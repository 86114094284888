/* eslint-disable no-case-declarations */
import Immutable from 'immutable';

const defaultState = Immutable.fromJS({
  isFetching: false,
  data: [],
  itemData: null,
  options: undefined,
  position: 0,
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  recordTypeList: [],
  unSavedData: false
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RESET:CUSTOM_LIST:DATA':
    case 'CUSTOM_RECORD_LAYOUTS:LIST:INIT':
      return state
        .set('error', defaultState.get('error'))
        .set('data', defaultState.get('data'))
        .set('position', defaultState.get('position'));

    case 'CUSTOM_RECORD_LAYOUTS:LIST:FETCH':
    case 'CUSTOM_DYNAMIC_RECORD_LAYOUTS:LIST:FETCH':
    case 'CUSTOM:FIELD:LIST:FETCH:INIT':
    case 'CUSTOM_RECORD_TYPES:LIST_FETCH:INIT':
    case 'CUSTOM:ITEM_LIST:FETCH:INIT':
    case 'CUSTOM:LAYOUT:SYNC:INIT':
    case 'CUSTOM_DYNAMIC_LAYOUT:FETCH_BY_ID:INIT':
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'));

    case 'CUSTOM:FIELD:ITEM:FETCH:SUCCESS':
    case 'CUSTOM:ITEM_LIST:FETCH_BY_ID:SUCCESS':
    case 'CUSTOM_DYNAMIC_LAYOUT:FETCH_BY_ID:SUCCESS':
      return state.set('isFetching', false).set('itemData', action.data);

    case 'CUSTOM_RECORD_TYPES:EDIT:SUCCESS':
    case 'CUSTOM:ITEM_LIST:EDIT:SUCCESS':
    case 'CUSTOM:FIELD:UPSERT:SUCCESS': {
      // eslint-disable-next-line no-case-declarations
      const modifiedData = state
        .get('data')
        .map((item) => (item.id === action.data.id ? action.data : item));
      return state
        .set('isFetching', false)
        .set('data', modifiedData)
        .set('itemData', action.data);
    }
    case 'RESET:CUSTOM:ITEMDATA':
      return state
        .set('itemData', defaultState.get('itemData'))
        .set('options', defaultState.get('options'));

    case 'CUSTOM:DROPDOWN_LIST:SAVE:DATA':
      return state.set('itemData', action.data);

    case 'CUSTOM:ITEM_LIST:CREATE:SUCCESS':
      return state.set('isFetching', false).set('position', 0);

    case 'CUSTOM_RECORD_TYPES:CREATE:SUCCESS':
      return state
        .set('data', [...state.get('data'), action.data])
        .set('position', state.get('position') + 1);

    case 'CUSTOM_RECORD_TYPES:DELETE:SUCCESS':
    case 'CUSTOM:FIELD:ITEM:DELETE:SUCCESS':
    case 'CUSTOM:ITEM_LIST:DELETE:SUCCESS': {
      // eslint-disable-next-line no-case-declarations
      const modifiedData = state
        .get('data')
        .filter((item) => item.id !== action.data.id);
      return state
        .set('isFetching', false)
        .set('data', modifiedData)
        .set('position', state.get('position') - 1);
    }
    case 'CUSTOM_RECORD_TYPES:LIST_FETCH:SUCCESS':
    case 'CUSTOM:FIELD:LIST:FETCH:SUCCESS':
    case 'CUSTOM_RECORD_LAYOUTS:LIST:FETCH:SUCCESS':
    case 'CUSTOM:ITEM_LIST:FETCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('data', action.data)
        .set('position', state.get('position') + action.data.length);

    case 'CUSTOM_RECORD_LAYOUTS:LIST:FETCH:FAIL':
    case 'CUSTOM:FIELD:UPSERT:FAIL':
    case 'CUSTOM:ITEM_LIST:ACTION:FAIL':
    case 'CUSTOM:CALCULATOR:VALIDATE:FAIL':
      return state.set('isFetching', false).set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );
    case 'CUSTOM:FIELD:ITEM:INIT':
    case 'CUSTOM:DIALOG:ERORR:RESET':
    case 'CUSTOM:CALCULATOR:VALIDATE:SUCCESS':
      return state.set('error', defaultState.get('error'));
    case 'CUSTOM_DYNAMIC_RECORD_LAYOUTS:LIST:INIT':
      return state
        .set('error', defaultState.get('error'))
        .set('data', defaultState.get('data'))
        .set('position', defaultState.get('position'));

    case 'CUSTOM_DYNAMIC_RECORD_LAYOUTS:LIST:FETCH:SUCCESS':
      return state
        .set('isFetching', false)
        .set('data', state.get('data').concat(action.data))
        .set('position', state.get('position') + action.data.length);

    case 'CUSTOM_DYNAMIC_RECORD_LAYOUTS:ITEM:DELETE:SUCCESS': {
      const modifiedData = state
        .get('data')
        .filter((item) => item.id !== action.id);
      return state.set('data', modifiedData);
    }
    case 'CUSTOM_DYNAMIC_RECORD_TYPES:LIST:FETCH:SUCCESS': {
      return state.set('recordTypeList', action.data);
    }
    case 'CUSTOM_DYNAMIC_LAYOUT:STATUS:UPDATE:SUCCESS': {
      const modifiedData = state
        .get('data')
        .map((item) => (item.id === action.data.id ? action.data : item));
      return state.set('data', modifiedData);
    }

    // OPTIONS

    case 'CUSTOM:ITEM_LIST_OPTION:GET_ALL:SUCCESS':
      if (action.layoutFieldUniqueId) {
        return state.set('isFetching', false).set('options', {
          ...state.get('options'),
          [action.layoutFieldUniqueId]: action.data
        });
      } else {
        return state.set('isFetching', false).set('options', action.data);
      }

    case 'CUSTOM:ITEM_LIST_OPTION:CREATE:SUCCESS':
      return state
        .set('isFetching', false)
        .set('options', [action.data, ...state.get('options')]);

    case 'CUSTOM:ITEM_LIST_OPTION:EDIT:SUCCESS':
      const modifiedData = state
        .get('options')
        .map((item) => (item.id === action.data.id ? action.data : item));
      return state.set('isFetching', false).set('options', modifiedData);

    case 'CUSTOM:ITEM_LIST_OPTION:DELETE:SUCCESS':
      return state.set('isFetching', false).set(
        'options',
        state.get('options').filter((item) => item.id !== action.data.id)
      );
    case 'CUSTOM:ITEM_LIST_OPTION:REORDER:SUCCESS':
      return state.set('isFetching', false).set(
        'options',
        action.data
      )
    case 'CUSTOM:ITEM_LIST_OPTION:GET_ALL:FAIL':
    case 'CUSTOM:ITEM_LIST_OPTION:CREATE:FAIL':
    case 'CUSTOM:ITEM_LIST_OPTION:EDIT:FAIL':
    case 'CUSTOM:ITEM_LIST_OPTION:DELETE:FAIL':
      return state.set('isFetching', false).set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );
    default:
      return state;
  }
};
