import { put, select } from 'redux-saga/effects';
import registry from 'app-registry';
import Immutable from 'immutable';
import { replace as replaceRouter } from 'connected-react-router';

import {
  GENERAL_FETCH_LIMIT,
  handleServiceDown
} from '@packages/utils/common-utils';
import notificationtranslations from '@packages/utils/notificationtranslations';
import { getParameterValuesFromHash } from '@packages/utils/query-parameters';

export function* resetAndFetchPortalList(action) {
  yield put({ type: 'RECORD:PORTAL:LIST:FETCH' });
  yield fetchPortalList(action);
}

export function* fetchPortalList(action) {
  const { position = 0, rowCount = GENERAL_FETCH_LIMIT } = action;
  let filterParams = {};
  let filteredOn = Immutable.Map();
  let searchParams = {};
  if (action.isPersistedData) {
    const recordPortalListState = yield select((state) => state.recordPortal);
    if (recordPortalListState) {
      filterParams = recordPortalListState.get('filterParams').toJS();
      filteredOn = recordPortalListState.get('filteredOn');
      searchParams = recordPortalListState.get('searchParams');
    }
  }
  const { sortOn, sortOrder } = filterParams;
  try {
    yield put({ type: 'LOADER:TOGGLE', toggle: true });
    const request = registry.get('request');
    const response = yield request.get(
      getURLWithParams(
        { sortOn, sortOrder, filteredOn },
        position,
        rowCount,
        searchParams
      )
    );
    switch (response.status) {
      case 200: {
        yield put({
          type: 'RECORD:PORTAL:LIST:FETCH:SUCCESS',
          data: response.body
        });
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        break;
      }
      default: {
        const error = response.body.msg;
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        break;
      }
    }
  } catch (err) {
    yield put({ type: 'LOADER:TOGGLE', toggle: false });
    yield handleServiceDown(err, 'custom');
    registry.get('logger').error(err);
  }
}

export function* filterPortals(action) {
  yield put({
    type: 'RECORD:PORTAL:LIST:FILTER',
    filterParams: action.filterParams
  });
  yield fetchPortalList({ ...action });
}

export function* searchPortals(action) {
  yield put({
    type: `RECORD:PORTAL:LIST:SEARCH`,
    searchParams: action.searchParams
  });
  yield fetchPortalList({ isPersistedData: true });
}

export function* deletePortal(action) {
  const { itemToDelete, items } = action;
  const request = registry.get('request');
  try {
    yield put({ type: 'LOADER:TOGGLE', toggle: true });
    const response = yield request.delete(
      `/v1/custom/external-form/${itemToDelete.id}`,
      null
    );
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.portalDeleted,
            type: 'success'
          }
        });
        const newItems = updateDataItems(items, itemToDelete.id);
        yield put({ type: `RECORD:PORTAL:LIST:UPDATE`, items: newItems });
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        break;
      }
    }
  } catch (err) {
    yield put({ type: 'LOADER:TOGGLE', toggle: false });
    yield handleServiceDown(err, 'custom');
    registry.get('logger').error(err);
  }
}

export function* upsertPortal(action) {
  yield put({ type: 'LOADER:TOGGLE', toggle: true });
  yield put({ type: 'RECORD:PORTAL:UPSERT:INIT' });
  try {
    const request = registry.get('request');
    const response = yield request.post(
      `/v1/custom/external-form`,
      action.data
    );
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.newPortalSuccess,
            type: 'success'
          }
        });
        yield put(
          replaceRouter(`/settings/record-portal/${response.body.id}/edit`)
        );
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        break;
      }
      case 409:
        yield put({
          type: 'RECORD:PORTAL:UPSERT:FAIL',
          error: response.body.msg
        });
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        break;
      default: {
        const error = response.body.msg;
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        break;
      }
    }
  } catch (err) {
    yield put({ type: 'LOADER:TOGGLE', toggle: false });
    yield handleServiceDown(err, 'custom');
    registry.get('logger').error(err);
  }
}

export function* fetchPortal() {
  const { id } = getParameterValuesFromHash(`/settings/record-portal/:id/edit`);
  yield put({ type: 'RECORD:PORTAL:FETCH' });
  try {
    yield put({ type: 'LOADER:TOGGLE', toggle: true });
    const request = registry.get('request');
    const response = yield request.get(`/v1/custom/external-form/${id}`);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'RECORD:PORTAL:FETCH:SUCCESS',
          data: response.body
        });
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        break;
      }
      default: {
        const error = response.body.msg;
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield put({ type: 'LOADER:TOGGLE', toggle: false });
    yield handleServiceDown(err, 'custom');
    registry.get('logger').error(err);
  }
}

export function* updatePortal(action) {
  const { fetchList = false } = action;
  try {
    yield put({ type: 'LOADER:TOGGLE', toggle: true });
    const request = registry.get('request');
    const response = yield request.put(
      `/v1/custom/external-form/${action.data.id}`,
      action.data
    );
    switch (response.status) {
      case 200: {
        yield put({
          type: 'RECORD:PORTAL:FETCH:SUCCESS',
          data: response.body
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.updatePortalSuccess,
            type: 'success'
          }
        });
        if (fetchList) yield fetchPortalList({});
        else if (action.isClosePortal) yield put(replaceRouter(`/settings`));
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        break;
      }
      default: {
        const error = response.body.msg;
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        yield put({type: 'RECORD:PORTAL:DETAILS:RESET'})
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        break;
      }
    }
  } catch (err) {
    yield put({ type: 'LOADER:TOGGLE', toggle: false });
    yield handleServiceDown(err, 'custom');
    registry.get('logger').error(err);
  }
}

export const updateDataItems = (items, currentId) => {
  const index = items.findIndex((item) => item.id === currentId);
  if (index !== -1) {
    const updatedItems = items.remove(index);
    return updatedItems;
  }
  return items;
};

const getURLWithParams = (filterParams, position, rowCount, searchParams) => {
  let url = `/v1/custom/external-form?offset=${position}&numberOfResults=${rowCount}`;
  const { sortOn, sortOrder, filteredOn } = filterParams;
  filteredOn.keySeq().forEach((filterKey) => {
    const filteredOnItem = filteredOn.get(filterKey);
    if (filteredOnItem && filteredOnItem.length > 0) {
      filteredOnItem.forEach((x) => {
        url = `${url}&filter=${filterKey}%3D${encodeURIComponent(x)}`;
      });
    }
  });
  if (searchParams) {
    const { searchText, searchKey } = searchParams;
    url = searchText
      ? `${url}&search=${searchKey}=${encodeURIComponent(searchText)}`
      : url;
  }
  url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  return url;
};
