import registry from 'app-registry';
import jwtDecode from 'jwt-decode';
import Immutable from 'immutable';
import { put, select } from 'redux-saga/effects';

import {
  handleServiceDown,
  GENERAL_FETCH_LIMIT
} from '@packages/utils/common-utils';
import { serviceDownError } from '@packages/utils/commontranslations';
import errortranslations from '@packages/utils/errortranslations';
import notificationtranslations from '@packages/utils/notificationtranslations';

import globalSolutionTranslations from './homeLayoutTranslations';

export function* fetchTenantsAndTags(action) {
  const { position = 0, rowCount = 100 } = action;
  yield put({ type: 'GLOBAL:TENANTS_TAGS:FETCH:INIT' });
  yield put({ type: 'ORGANISATION:USER:DETAIL:INIT' });

  try {
    const response = yield registry
      .get('request')
      .get(
        `/v1/tenants-groups?numberOfResults=${rowCount}&offset=${position}`,
        null
      );

    switch (response.status) {
      case 200: {
        yield put({
          type: 'GLOBAL:TENANTS_TAGS:FETCH:SUCCESS',
          items: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}

export function* createTag(action) {
  const { data, isEdit } = action;
  const request = registry.get('request');
  let response;
  try {
    if (isEdit) {
      response = yield request.put(`/v1/tenantgroups/${data.id}`, data);
    } else {
      response = yield request.post(`/v1/tenantgroups`, data);
    }
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: isEdit
              ? notificationtranslations.tagUpdatedSuccessfully
              : notificationtranslations.tagCreationSuccess,
            type: 'success'
          }
        });
        yield fetchSubTenantHierarchy();
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}

export function* createSubTenant(action) {
  const { data, isEdit } = action;
  const requestData = { ...data };
  const request = registry.get('request');
  let response;
  try {
    if (isEdit) {
      response = yield request.put(
        `/v1/subtenants/${requestData.id}`,
        requestData
      );
    } else {
      response = yield request.post(`/v1/subtenants`, requestData);
    }
    switch (response.status) {
      case 200:
      case 202: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: isEdit
              ? notificationtranslations.tenantDetailUpdateSuccess
              : notificationtranslations.tenantCreated,
            type: 'success'
          }
        });
        yield fetchSubTenantHierarchy();
        yield put({
          type: 'GLOBAL:SUBTENANTS:CREATE:LATEST',
          subTenantName: requestData.name
        });
        yield put({ type: 'LOGIN:TOKEN:REFRESH' });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}

export function* fetchSubTenantHierarchy() {
  yield put({ type: 'GLOBAL:SUBTENANTS:FETCH:INIT' });
  try {
    const response = yield registry
      .get('request')
      .get(`/v1/tenants/hierarchy`, null);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'FETCH:SUB_TENANTS:HIERARCHY:SUCCESS',
          items: response.body
        });
        yield put({ type: 'GLOBAL:SUBTENANTS:FETCH:SUCCESS' });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({ type: 'GLOBAL:SUBTENANTS:FETCH:FAIL' });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    yield put({ type: 'GLOBAL:SUBTENANTS:FETCH:FAIL' });
  }
}

export function* fetchSubTenantDetail(action) {
  const { id } = action;
  try {
    const response = yield registry
      .get('request')
      .get(`/v1/tenants/${id}`, null);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'SUB_TENANT:DETAIL:FETCH:SUCCESS',
          data: response.body
        });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    registry.get('logger').error(err);
    yield put({ type: 'SUB_TENANT:DETAIL:FETCH:FAIL', error: err.message });
  }
}

export function* fetchTagDetail(action) {
  const { id } = action;
  try {
    const response = yield registry
      .get('request')
      .get(`/v1/tenantgroups/${id}`, null);
    switch (response.status) {
      case 200: {
        yield put({ type: 'TAG:DETAIL:FETCH:SUCCESS', data: response.body });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    registry.get('logger').error(err);
    yield put({ type: 'TAG:DETAIL:FETCH:FAIL', error: err.message });
  }
}

export function* resetAndfetchDataUpdates(action) {
  yield put({ type: 'SUB_TENANTS:DATA_UPDATES:LIST:INIT' });
  yield fetchDataUpdates(action);
}

export function* fetchDataUpdates(action) {
  yield put({ type: 'SUB_TENANTS:DATA_UPDATES:LIST:FETCH:INIT' });
  const { position = 0, rowCount = GENERAL_FETCH_LIMIT } = action;
  const dataUpdatesState = yield select((state) => state.globalDataUpdates);
  const filterParams = dataUpdatesState
    ? dataUpdatesState.get('filterParams').toJS()
    : {};
  const { sortOn, sortOrder } = filterParams;
  const filteredOn = dataUpdatesState
    ? dataUpdatesState.get('filteredOn')
    : Immutable.Map();

  try {
    const response = yield registry
      .get('request')
      .get(
        getURLWithParams({ sortOn, sortOrder, filteredOn }, position, rowCount),
        null,
        {}
      );
    switch (response.status) {
      case 200: {
        yield put({
          type: 'SUB_TENANTS:DATA_UPDATES:LIST:FETCH:SUCCESS',
          items: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    yield put({
      type: 'SUB_TENANTS:DATA_UPDATES:LIST:FETCH:FAIL',
      error: err.message
    });
  }
}

export function* filterDataUpdates(action) {
  const filterParams = action.filterParams ? action.filterParams : {};
  yield put({ type: `SUB_TENANTS:DATA_UPDATES:LIST:FILTER`, filterParams });
  yield fetchDataUpdates(action);
}

export function* retrySyncJob(action) {
  const { id } = action;
  try {
    const response = yield registry
      .get('request')
      .put(`/v1/masterdatasyncjob/retry/${id}`, null);
    switch (response.status) {
      case 200: {
        yield put({
          type: 'SUB_TENANTS:DATA_UPDATES:ITEM:RETRY:SUCCESS',
          newData: response.body
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.retryCompleted,
            type: 'success'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}

export function* saveDataUpdates(action) {
  const { data } = action;
  try {
    const response = yield registry
      .get('request')
      .put(`/v1/masterdatasyncjob/update-masterdata-sync`, data);
    switch (response.status) {
      case 200: {
        yield put({ type: 'SUB_TENANTS:DATA_UPDATES:LIST:INIT' });
        yield put({
          type: 'SUB_TENANTS:DATA_UPDATES:LIST:FETCH:SUCCESS',
          items: response.body
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: globalSolutionTranslations.dataUpdateSuccess,
            type: 'success'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}

export function* fetchTenantDetails() {
  yield put({ type: 'GLOBAL:SOLUTION:TENANT:DETAILS:FETCH:INIT' });
  try {
    const response = yield registry
      .get('request')
      .get(`/v1/subtenants/access`, null);
    const item = response.body;
    switch (response.status) {
      case 200: {
        yield put({
          type: 'GLOBAL:SOLUTION:TENANT:DETAILS:FETCH:SUCCESS',
          accessData: item
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
    yield put({
      type: 'GLOBAL:SOLUTION:TENANT:DETAILS:FETCH:FAIL',
      error: err.message
    });
  }
}

export function* tenantLogin(action) {
  const { tenantId, postLoginActions, removeHoldingToken } = action;
  const config = registry.get('config');
  try {
    const response = yield registry
      .get('request')
      .post(`/v1/identity/tenants/${tenantId}/login`, null);
    const { httpHeader } = config.login.token;
    switch (response.status) {
      case 204:
        {
          const token = response.headers.get(httpHeader);
          const tenantName = jwtDecode(token).tenant_id;
          const location = `${window.location.protocol}//${tenantName}${
            window.location.port ? `:${window.location.port}` : getDomainName()
          }`;
          if( removeHoldingToken ){
            yield put({ type: 'USER:DO_LOGOUT', redirectToLogout: false });
          }
          const url = `${location}/#/${tenantId}?isHoldingTenant=true&token=${token}&route=environment&postLoginActions=${encodeURIComponent(
            JSON.stringify(postLoginActions)
          )}&route=environment`;
          window.location.replace(`${url}`);
        }
        break;
      case 400:
      case 404:
        window.location.replace('/#/tenantCreationInProgress');
        break;
      default:
        yield put({
          type: 'LOGIN:DO_LOGIN:FAIL',
          loginError: response.body.content || response.body.msg
        });
        break;
    }
  } catch (err) {
    switch (err.status) {
      case 401:
        yield put({ type: 'LOGIN:DO_LOGIN:FAIL', loginError: err.message });
        break;
      case 502:
        yield put({
          type: 'LOGIN:DO_LOGIN:FAIL',
          loginError: serviceDownError('user')
        });
        break;
      default: {
        const errorMsg = err.response?.body?.msg
          ? err.response.body.msg
          : errortranslations.authenticationError;
        yield put({ type: 'LOGIN:DO_LOGIN:FAIL', loginError: errorMsg });
        break;
      }
    }
  }
}

export function* globalOrganisationCheckUsage(action) {
  yield put({ type: 'GLOBAL:LEGAL_ENTITIES:ITEM:USAGE:FETCH:INIT' });
  try {
    const response = yield registry
      .get('request')
      .get(
        `/v1/organisations/${action.entityId}/tenants-usage?withUsageCount=true`,
        null
      );
    switch (response.status) {
      case 200: {
        const globalUsage = response.body;
        yield put({ type: 'GLOBAL:LEGAL_ENTITIES:USAGE:SUCCESS', globalUsage });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'organisation');
    yield put({ type: 'GLOBAL:LEGAL_ENTITIES:USAGE:FAIL', error: err.message });
  }
}

export function* deleteSubTenantOrGroup(action) {
  const { id, entityType } = action;
  const request = registry.get('request');
  try {
    const response =
      entityType === 'Tenant'
        ? yield request.delete(`v1/tenants/${id}`, null)
        : yield request.delete(`/v1/tenantgroups/${id}`, null);
    switch (response.status) {
      case 204: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content:
              entityType === 'Tenant'
                ? notificationtranslations.tenantDeleted
                : notificationtranslations.tenantGroupDeletionSuccess,
            type: 'success'
          }
        });
        yield fetchSubTenantHierarchy();
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'tenant');
  }
}

export function* runQuickSearch(action) {
  const { searchItem, requestData } = action;
  const request = registry.get('request');
  let response;
  try {
    response = yield request.post(
      `/v1/reportsearches/quickReport/${searchItem}/run`,
      requestData
    );
    switch (response.status) {
      case 200: {
        const data = getModifiedReportResult(response.body);
        yield put({ type: 'GLOBAL:SOLUTION:QUICK:REPORT:FETCH:SUCCESS', data });
        break;
      }
      default: {
        yield put({ type: 'GLOBAL:SOLUTION:QUICK:REPORT:FETCH:FAIL' });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield put({ type: 'GLOBAL:SOLUTION:QUICK:REPORT:FETCH:FAIL' });
    yield handleServiceDown(err, 'tenant');
  }
}

export function* downloadSyncJobLog(action) {
  const { id } = action;
  const request = registry.get('request');

  try {
    const response = yield request.exportCSVbyGet(
      `/v1/masterdatasyncjob/${id}/download-log`,
      null
    );

    yield response;
    switch (response.status) {
      case 200: {
        // Do nothing
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'report');
    registry.get('logger').error(err);
  }
}

export function* exportQuickReport(action) {
  const request = registry.get('request');
  const { searchItem, requestData } = action;
  try {
    const response = yield request.exportCSVbyPost(
      `/v1/reportsearches/quickReport/${searchItem}/run?queryType=csv`,
      requestData
    );

    yield response;
    switch (response.status) {
      case 200: {
        // Do nothing
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'report');
    registry.get('logger').error(err);
  }
}

const getDomainName = () => {
  const host = window.location.host.split('.');
  host.shift();
  return `.${host.join('.')}`;
};

const getURLWithParams = (filterParams, position, rowCount) => {
  let url = `/v1/masterdatasyncjob?numberOfResults=${rowCount}&offset=${position}`;
  if (filterParams) {
    const { filteredOn, sortOrder, sortOn } = filterParams;
    filteredOn.keySeq().forEach((filterKey) => {
      const filteredOnItem = filteredOn.get(filterKey);
      if (filteredOnItem.length > 0) {
        filteredOnItem.forEach((item) => {
          const filterText = typeof item === 'object' ? item.name : item;
          url = `${url}&filter=${filterKey}%3D${encodeURIComponent(
            filterText
          )}`;
        });
      }
    });
    url = sortOn ? `${url}&sortOn=${sortOn}` : url;
    url = sortOn ? `${url}&sortOrder=${sortOrder}` : url;
  }
  return url;
};

const getModifiedReportResult = (data) => {
  const modifiedData = [];
  Object.keys(data).forEach((item) => {
    modifiedData.push({ key: item, value: data[item] });
  });
  return modifiedData;
};
