import registry from 'app-registry';
import { put, select } from 'redux-saga/effects';
import { replace as replaceRouter } from 'connected-react-router';
import { push } from 'react-router-redux';

import { getParameterValuesFromHash } from '@packages/utils/query-parameters';
import {
  getErrorMessage,
  handleServiceDown
} from '@packages/utils/common-utils';
import notificationtranslations from '@packages/utils/notificationtranslations';
import { documentInfected } from '@packages/utils/commontranslations';

import { getUserOrg, transformCopyRecordData } from '../common-utils';
import {
  transformRequestData,
  transformResponseData,
  transformQuickAddData
} from './utils/documentUtils';
import { isRecordEditable, getRecordData } from '../saga-utils';

function* getDefaultDocumentData() {
  const user = yield select((state) => state.user);
  const aclOrgs = getUserOrg(user);
  return {
    aclOrgs: aclOrgs.length > 1 ? [] : aclOrgs,
    status: 'record_status_draft',
    documents: [],
    documentRecordTypes: [],
    description: '',
    specialCharacteristics: [],
    organisations: [],
    internationalTransfer: false,
    text: {},
    htmlText: {},
    pickList: {},
    stakeholder: {},
    checkbox: {},
    picklist: {},
    scoredPicklist: {},
    purposes: [],
    processingCategories: [],
    isExecuteAccess: true
  };
}

const getDocumentDetailFetchUrl = (
  recordId,
  isTemplateMode,
  isPreviewMode,
  libraryTenantId
) => {
  let url = '/v1/records';
  if (isTemplateMode) url += '/templates';
  url += `/document-records/${recordId}`;
  if (isPreviewMode) url += `/preview/${libraryTenantId}`;
  return url;
};

export function* initializeDocumentDetail(action) {
  const {
    id,
    isQuickAdd,
    locale,
    tenantLocale,
    isTemplateMode,
    isPreviewMode,
    libraryTenantId
  } = action;
  const { recordId } = getParameterValuesFromHash(
    '/document/:recordId/edit?:is'
  );
  yield put({ type: 'DOCUMENT:DETAIL:FETCH', recordId: id || recordId });
  try {
    const response = yield registry
      .get('request')
      .get(
        getDocumentDetailFetchUrl(
          id || recordId,
          isTemplateMode,
          isPreviewMode,
          libraryTenantId
        ),
        null
      );
    switch (response.status) {
      case 200:
        {
          const recordDetail = response.body;
          const userState = yield select((state) => state.user);
          const currentUser = userState ? userState.get('profile') : null;
          const isEditable = yield isRecordEditable(recordDetail, currentUser);
          const store = registry.get('store');
          const responseData = Object.assign(
            {},
            yield getDefaultDocumentData(),
            recordDetail
          );
          const data = yield transformResponseData(responseData, currentUser);
          if (recordDetail.status === 'record_status_requested'
              || recordDetail.status === 'record_status_external_submitted') {
            store.dispatch(replaceRouter(`/document/${recordDetail.id}/view`));
            yield put({
              type: 'DOCUMENT:EDIT:RESTRICTED',
              isEditRestricted: true
            });
          }
          if (isQuickAdd)
            yield put({
              type: 'RECORD:DOCUMENT:QUICKADD:DETAIL',
              quickAddData: response.body
            });
          yield put({
            type: 'DOCUMENT:DETAIL:FETCH:SUCCESS',
            isEditable,
            recordName: data.name,
            data
          });
          const { layoutId } = response.body;
          yield put({
            type: 'RECORD_LAYOUT:FETCH_INIT',
            recordType: 'DOCUMENT',
            layoutId,
            locale,
            tenantLocale,
            libraryTenantId,
            isPreviewMode
          });
        }
        break;
      case 404:
      case 403: {
        const error = response.body.msg;
        yield put({ type: 'DOCUMENT:DETAIL:FETCH:FAIL', error });

        const currentState = yield select((state) => state.documents);
        const store = registry.get('store');

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        if (!isPreviewMode)
          store.dispatch(replaceRouter(currentState.get('prevLocation')));

        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    const error = getErrorMessage(err);
    yield put({ type: 'DOCUMENT:DETAIL:FETCH:FAIL', error });
  }
}

export function* upsertDocumentDetail(action) {
  const request = registry.get('request');
  const {
    copyOfRecordId,
    data,
    createFromEdit,
    isQuickAdd,
    isPublic,
    isTemplateMode,
    templateFromRecord,
    templateId
  } = action;

  try {
    // If document is a copy of an existing document, then get that document data.
    const defaultReqData =
      copyOfRecordId || templateId
        ? yield getRecordData(
          { copyOfRecordId, templateId },
          'document-records'
        )
        : yield getDefaultDocumentData();
    const modifiedData = isQuickAdd
      ? transformQuickAddData(defaultReqData, data)
      : Object.assign({}, defaultReqData, data);
    const userState = yield select((state) => state.user);
    const aclOrgs = getUserOrg(userState);
    const currentUser = userState ? userState.get('profile') : null;
    const newData =
      copyOfRecordId && !isTemplateMode
        ? transformCopyRecordData(modifiedData, currentUser, aclOrgs)
        : modifiedData;
    const { formId } = isPublic ? getParameterValuesFromHash('/external-form/:formId') : {};
    const requestData = transformRequestData(newData);
    const requestUrl = formId ? `/v1/public/external-form/${formId}/document-records` 
      :`/v1/records${isTemplateMode ? '/templates' : ''}/document-records`;
    const response = yield request.post(requestUrl, requestData, {}, !formId);
    yield response;

    switch (response.status) {
      case 201:
      case 202:
      case 200:
      case 204: {
        yield put({ type: 'DOCUMENT:DETAIL:UPSERT:SUCCESS' });
        if (isQuickAdd) {
          if(formId) {
            yield put({
              type: 'RECORD:DOCUMENT:QUICKADD:DETAIL',
              quickAddData: {...data[0],
                documents:[{value: {...data[0]}}],
                ...response.body}
            });
            yield put({ type: 'RECORD:DOCUMENT:QUICKADD:DETAIL:RESET' });
          }
          else {
            yield put({
              type: 'RECORD:LIST:REQUEST',
              recordType: 'document',
              getAllFields: true,
              dataType: 'document-records'
            });
            yield initializeDocumentDetail({ id: response.body.id, isQuickAdd });
          }
        } else if (templateFromRecord) {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: notificationtranslations.templateCreated,
              type: 'success'
            }
          });
          yield put(replaceRouter('/environment'));
        } else if (isTemplateMode) {
          yield put(push(`/document-template/${response.body.id}/edit`));
        } else if (createFromEdit) {
          yield put(push(`/document/${response.body.id}/view`));
        } else {
          yield put(push(`/document/${response.body.id}/edit`));
        }
        break;
      }
      case 423:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'warning'
          }
        });
        yield put({
          type: 'DOCUMENT:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        break;

      case 403:
      case 409:
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({
          type: 'DOCUMENT:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    const error = getErrorMessage(err);
    yield put({ type: 'DOCUMENT:DETAIL:UPSERT:FAIL', error });
  }
}

export function* upsertDocumentRecordDetail(action) {
  const request = registry.get('request');
  const { copyOfRecordId, data, isFromManageDocuments, isTemplateMode } =
    action;

  yield put({ type: 'DOCUMENT:DETAIL:UPSERT:INIT' });
  try {
    const defaultReqData = copyOfRecordId
      ? yield getRecordData({ copyOfRecordId }, 'document-records')
      : yield getDefaultDocumentData();
    const modifiedData = Object.assign({}, defaultReqData, data);
    const requestData = transformRequestData(modifiedData);
    const recordId = modifiedData.id || '';
    const response = yield request.put(
      `/v1/records${
        isTemplateMode ? '/templates' : ''
      }/document-records/${recordId}`,
      requestData
    );

    switch (response.status) {
      case 201:
      case 202:
      case 200:
      case 204: {
        if (isFromManageDocuments) {
          yield put({
            type: `RECORD:LIST:REQUEST`,
            recordType: 'document',
            getAllFields: true,
            dataType: 'document-records'
          });
        }
        const userState = yield select((state) => state.user);
        const currentUser = userState ? userState.get('profile') : null;
        const responseData = yield transformResponseData(
          response.body,
          currentUser
        );
        yield put({
          type: 'DOCUMENT:DETAIL:UPSERT:SUCCESS',
          data: responseData
        });
        if (action.prevLocation !== '')
          yield put(replaceRouter(action.prevLocation));
        break;
      }
      case 403:
      case 409:
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({
          type: 'DOCUMENT:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      }
    }
  } catch (err) {
    const error = getErrorMessage(err);
    yield handleServiceDown(err, 'records');
    yield put({ type: 'DOCUMENT:DETAIL:UPSERT:FAIL', error });
  }
}

export function* fetchUsersForDocument() {
  const { recordId } = getParameterValuesFromHash(
    '/document/:recordId/edit?:is'
  );
  yield put({ type: 'DOCUMENT:USERS:LIST:REQUEST:FETCH' });
  try {
    const response = yield registry
      .get('request')
      .get(`/v1/records/document-records/${recordId}/users`, {});
    switch (response.status) {
      case 200: {
        yield put({
          type: 'DOCUMENT:USERS:LIST:REQUEST:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* saveDocumentComment(action) {
  const currentState = yield select((state) => state.documents);
  const currentComments = currentState
    ? currentState.get('comments').toJS()
    : [];
  let modifiedComments = [];
  const { comment, isEdit, id } = action;

  try {
    const request = registry.get('request');
    let response = {};
    if (!isEdit) {
      response = yield request.post(
        `/v1/records/document-records/${id}/comments`,
        comment
      );
    } else {
      response = yield request.put(
        `/v1/records/document-records/${id}/comments/${comment.id}`,
        comment
      );
    }
    switch (response.status) {
      case 200: {
        if (!isEdit) {
          modifiedComments = [...currentComments, response.body];
        } else {
          const currentIndex = currentComments.findIndex(
            (item) => item.id === response.body.id
          );
          modifiedComments = [...currentComments];
          modifiedComments[currentIndex] = response.body;
        }
        yield put({
          type: 'DOCUMENT:COMMENT:SAVE:SUCCESS',
          comments: modifiedComments
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.commentedSuccess,
            type: 'success'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* deleteDocumentComment(action) {
  const { id, comment } = action;
  const currentState = yield select((state) => state.documents);
  const currentComments = currentState
    ? currentState.get('comments').toJS()
    : [];
  try {
    const request = registry.get('request');
    const response = yield request.delete(
      `/v1/records/document-records/${id}/comments/${comment.id}`
    );
    switch (response.status) {
      case 204: {
        const currentIndex = currentComments.findIndex(
          (item) => item.id === comment.id
        );
        currentComments.splice(currentIndex, 1);
        yield put({
          type: 'DOCUMENT:COMMENT:DELETE:SUCCESS',
          comments: currentComments
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.commentDeletedSuccess,
            type: 'success'
          }
        });

        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* upsertDocumentItem(action) {
  const { data, isEdit, isQuickAdd, isPublic = false } = action;
  const { overWriteCurrentVersion } = data;
  const { formId } = isPublic ? getParameterValuesFromHash('/external-form/:formId') : {};
  const request = registry.get('request');

  yield put({ type: 'DOCUMENT:RECORD:ITEM:UPSERT:INIT' });

  let response;
  try {
    if(isPublic && formId) {
      if (data.file) {
        response = yield request.postDocument(`/v1/public/external-form/${formId}/document-record/upload`, 
          data, {}, false);
      } else response = yield request.post(`/v1/public/external-form/${formId}/document-record/add-document`, data,
        {}, false);
    } else if (isEdit) {
      response = yield request.put(`/v1/documents/${data.id}`, data);
    } else if (data.file) {
      response = yield request.postDocument(`/v1/documents/upload`, data);
    } else response = yield request.post(`/v1/documents/add-document`, data);

    switch (response.status) {
      case 201:
      case 202:
      case 200: {
        const items = response.body;
        const documents = yield select((state) => state.documents);
        const modifiedDocument = isQuickAdd
          ? []
          : JSON.parse(JSON.stringify(documents.get('data').toJS().documents));
        if (overWriteCurrentVersion || isEdit) {
          modifiedDocument[0] = items;
        } else {
          modifiedDocument.unshift(items);
        }
        yield put({
          type: 'DOCUMENT:PROPERTY:UPDATE',
          property: 'documents',
          data: modifiedDocument
        });
        yield put({
          type: 'DOCUMENT:RECORD:ITEM:UPSERT:SUCCESS',
          documentData: modifiedDocument
        });
        if (isQuickAdd) {
          if (data.documentRecordTypes)
            modifiedDocument[0].documentRecordTypes = data.documentRecordTypes;
          yield put({
            type: 'DOCUMENT:DETAIL:UPSERT',
            data: modifiedDocument,
            isQuickAdd,
            isPublic
          });
        }
        break;
      }

      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({ type: 'DOCUMENT:RECORD:ITEM:UPSERT:FAIL' });
        break;
      }
    }
  } catch (err) {
    if (err.status === 502 && err.message === 'Document seems to be infected') {
      yield put({
        type: 'NOTIFIER:NOTIFY',
        notification: {
          content: documentInfected,
          type: 'error'
        }
      });
    } else {
      yield handleServiceDown(err, 'document');
    }
  }
}

export function* deleteDocumentFileContent(action) {
  const { itemId } = action;
  const request = registry.get('request');
  try {
    const response = yield request.delete(
      `v1/documents/${itemId}/remove-attachment`
    );
    switch (response.status) {
      case 200: {
        const deletedItem = response.body;
        const documentState = yield select((state) => state.documents);
        const modifiedDocument = JSON.parse(
          JSON.stringify(documentState.get('data').toJS().documents)
        ).map((item) => (item.id === deletedItem.id ? deletedItem : item));
        yield put({
          type: 'DOCUMENT:PROPERTY:UPDATE',
          property: 'documents',
          data: modifiedDocument
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'document');
  }
}

export function* getLinkedOrganisations(action) {
  const request = registry.get('request');
  const { recordId, data } = action;
  const response = yield request.get(
    `v1/records/${recordId}/linkedRecordsOrgs`
  );

  try {
    switch (response.status) {
      case 201:
      case 202:
      case 200:
      case 204: {
        const modifiedResponse = transformOrganisations(response.body);
        const modifiedData = filterOrganisations(data, modifiedResponse);
        yield put({
          type: 'DOCUMENT:PROPERTY:UPDATE',
          property: 'organisations',
          data: modifiedData
        });
        break;
      }
      case 403:
      case 409: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'record');
  }
}

const transformOrganisations = (data) =>
  data.map((item) => ({
    ...item,
    value: { name: item.name, id: item.id.id, country: item.country }
  }));

const filterOrganisations = (currentData, newData) => {
  const data = currentData.concat(newData);
  const modifiedData = data.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (item) =>
          (item?.value?.id || item.id) === (value?.value?.id || value.id)
      )
  );
  return modifiedData;
};

export function* updateDocumentStatus(action) {
  try {
    const request = registry.get('request');
    const { name, status, nextReviewDate } = action.data;
    const modifiedRequest = { name, status, nextReviewDate };
    const response = yield request.put(
      `/v1/records/document-records/${action.recordId}`,
      modifiedRequest
    );
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.recordStatusUpdateSuccess,
            type: 'success'
          }
        });
        // Update the record status
        const documentState = yield select((state) => state.documents);
        const data = documentState
          .get('data')
          .set('status', action.data.status)
          .set('lastApprovalDate', response.body.lastApprovalDate)
          .set('nextReviewDate', response.body.nextReviewDate);
        yield put({ type: 'DOCUMENT:DETAIL:UPSERT:SUCCESS', data });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}
