import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import Toolbar from '@material-ui/core/Toolbar';
import Immutable from 'immutable';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import TextField from '@material-ui/core/TextField';
import Clear from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';
import Add from '@material-ui/icons/Add';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArchive, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import ArrowTooltip from '@packages/components/tooltip';
import ExportReportDialog from '@packages/components/export-dialog';
import CustomDialog from '@packages/components/custom-dialog';
import style from '@packages/ui/styles';
import {
  typeTranslations,
  commonTranslations,
  recordTranslations,
  customTranslation
} from '@packages/utils/commontranslations';
import {
  getAptDefaultValue,
  getAptPDFExportTypes,
  getModifiedRecordType,
  supportedJurisdictions
} from '@packages/utils/common-utils';
import { checkForRecordCreation } from '@packages/utils/record-utils';
import { pluralTerms } from '@packages/utils/uppercaseTranslations';
import { sectionHeaderTranslations } from '@packages/features/environment/masterDataTranslations';
import CreateCustomRecordDialog from '@packages/features/privacy-record-detail/custom/create';
import { getTranslatedLabel } from '@packages/features/tenant-configuration/tenantConfigUtils';

import CreateAssessmentDialog from '../../../privacy-record-detail/assessments/create';
import CreateProcessingDialog from '../../../privacy-record-detail/processings/create';
import CreateBreachDialog from '../../../privacy-record-detail/breaches/create';
import CreateDocumentDialog from '../../../privacy-record-detail/documents/create';
import BulkUpdateDialog from '../bulk-update';
import BulkDelete from '../../../privacy-record-detail/components/bulk-delete';
import {
  BulkUpdateSummary,
  BulkUpdateValidationErrors
} from '../bulk-update/components';
import {
  getGoBackLink,
  getComplianceButton
} from '../../../privacy-record-detail/common-utils';
import {
  defaultRecordLayouts,
  getAccessibleRecordTypes
} from '../../record-utils';

export const PrivacyRecordsHeader = (props) => {
  const {
    currentFilter,
    showToggleButton,
    theme,
    prevLocation,
    allowPDFDownload,
    pricingPlan,
    showBulkDeleteIcon,
    locale,
    tenantLocale,
    recordTypeList,
    filterMenuData,
    userPermissions,
    searchText,
    showRisks,
    selectedRecords,
    isDownloading,
    toggleLoader,
    showValidationErrors,
    listItems,
    intl,
    recordsBulkExport,
    bulkDelete,
    resetBulkItems,
    toggleColumn,
    toggleColumnName,
    resetValidationErrors,
    resetCheckedFields,
    removeFilters,
    onSearch,
    visibility,
    hasNoLimit,
    toggleMode,
    showRecordModeToggle,
    isTemplateMode,
    disableModeSwitch
  } = props;

  const recordType = getModifiedRecordType(currentFilter);
  const pageSearchObj = searchText.get(recordType).pageSearchText;

  const [columnToggleOn, setColumnToggleOn] = useState(showRisks);
  const [openCreateProcessingDialog, setOpenCreateProcessingDialog] =
    useState(false);
  const [openCreateAssessmentDialog, setOpenCreateAssessmentDialog] =
    useState(false);
  const [openCreateBreachDialog, setOpenCreateBreachDialog] = useState(false);
  const [openCreateDocumentDialog, setOpenCreateDocumentDialog] =
    useState(false);
  const [openCreateCustomRecordDialog, setOpenCreateCustomRecordDialog] =
    useState(false);
  const [openBulkUpdateDialog, setOpenBulkUpdateDialog] = useState(false);
  const [openBulkUpdateRejectDialog, setOpenBulkUpdateRejectDialog] =
    useState(false);
  const [openExportDialog, setOpenExportDialog] = useState(false);
  const [openAddMenu, setOpenAddMenu] = useState(false);
  const [bulkUpdateType, setBulkUpdateType] = useState(undefined);
  const [bulkUpdateData, setBulkUpdateData] = useState({});
  const [openBulkDeleteDialog, setOpenBulkDeleteDialog] = useState(false);
  const [chosenRecords, setChosenRecords] = useState(selectedRecords);
  const [openSummaryDialog, setOpenSummaryDialog] = useState(false);
  const [displayValidationErrors, setDisplayValidationErrors] = useState(false);
  const [validationMessage, setValidationMessage] = useState([]);
  const [currentSearchText, setCurrentSearchText] = useState(
    pageSearchObj.get('allFields').get('searchText') || ''
  );
  const [recordTypeData, setRecordTypeData] = useState({});
  const [controller, setController] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);
  const [checkedFields, setCheckedFields] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  const {
    createProcessing,
    createPrivacyRecord,
    createPreDpia,
    createBreach,
    createTia,
    createCustom,
    createEditDocumentRecord,
    exportPdf,
    deleteProcessing,
    viewTemplate,
    createTemplate
  } = userPermissions.toJS();

  useEffect(() => {
    if (selectedRecords) {
      setChosenRecords(selectedRecords);
    }
    if (currentFilter) {
      const recordType = getModifiedRecordType(currentFilter);
      const pageSearchObj = searchText.get(recordType).pageSearchText;
      setCurrentSearchText(
        pageSearchObj.get('allFields').get('searchText') || ''
      );
    }
    if (showValidationErrors) {
      setDisplayValidationErrors(showValidationErrors);
    }
  }, [
    selectedRecords,
    currentFilter,
    showValidationErrors,
    toggleLoader,
    searchText
  ]);

  useEffect(() => {
    const content = (
      <FormattedMessage
        id="PdfBulkExport.loader"
        description="Loader icon text"
        defaultMessage="Please wait while your PDF files are being generated"
      />
    );
    toggleLoader(isDownloading, content, handleOnClick);
  }, [isDownloading]);

  useEffect(
    () => () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    },
    [timeoutId]
  );

  const getFilterApplied = () => {
    const filteredOn = [...props.filteredOn.values()];
    return filteredOn.some((item) => item.length > 0);
  };

  const getExportRecordType = () => {
    switch (currentFilter) {
      case 'dpia_assessments':
        return 'assessments';
      case 'tia_assessments':
        return 'tias';
      case 'processing_processings':
        return 'processings';
      case 'breach_breaches':
        return 'breaches';
      default:
        return currentFilter;
    }
  };

  const getSelectedRecords = () => {
    const recordIds = chosenRecords.map(({ id }) => id);
    return listItems.toJS().filter(({ id }) => recordIds.includes(id));
  };

  const getErrorMessage = () => (
    <div>
      {validationMessage.map((message) => (
        <div style={{ marginBottom: 10 }}>
          {intl.formatMessage(message.props)}
        </div>
      ))}
    </div>
  );

  const handleCreateProcessing = (recordTypeData) => {
    setOpenCreateProcessingDialog(true);
    setRecordTypeData(recordTypeData);
  };

  const createAssessment = (recordTypeData) => {
    setOpenCreateAssessmentDialog(true);
    setRecordTypeData(recordTypeData);
  };

  const handleCreateBreach = (recordTypeData) => {
    setOpenCreateBreachDialog(true);
    setRecordTypeData(recordTypeData);
  };

  const createDocument = () => {
    setOpenCreateDocumentDialog(true);
  };

  const createCustomRecord = (recordTypeData) => {
    setOpenCreateCustomRecordDialog(true);
    setRecordTypeData(recordTypeData);
  };

  const bulkExport = (exportType) => {
    const controller = new AbortController();
    const { signal } = controller;
    setController(controller);
    recordsBulkExport(getExportRecordType(), chosenRecords, exportType, signal);
  };

  const getHeaderName = (type, label) => {
    switch (type) {
      case 'all':
        return (
          <FormattedMessage
            id="PrivacyRecords.header.title"
            description="Privacy Records - Header Title"
            defaultMessage="Privacy Records"
          />
        );
      case 'processings':
      case 'processing_processings':
        return (
          <div>
            {typeTranslations.processings}
            {columnToggleOn && (
              <div style={{ float: 'right', display: 'none' }}>
                <div style={style.toolbarTitleRisk}>45%</div>
                <div
                  style={{ fontSize: '15px', marginTop: '-10px', color: 'red' }}
                >
                  {' '}
                  have risks
                </div>
              </div>
            )}
          </div>
        );
      case 'dpia_assessments':
        return <div>{typeTranslations.dpia}</div>;
      case 'assessments':
        return <div>{typeTranslations.assessments}</div>;
      case 'breaches':
        return <div>{typeTranslations.breaches}</div>;
      case 'tia_assessments':
        return <div>{typeTranslations.transferImpactAssessment}</div>;
      case 'document_records':
        return <div>{sectionHeaderTranslations.documentRecords}</div>;
      default:
        return label;
    }
  };

  const handleBulkDelete = () => {
    setOpenBulkDeleteDialog(false);
    bulkDelete({ recordIds: chosenRecords.map((item) => item.id) });
    resetBulkItems();
  };

  const handleOnClick = () => {
    recordsBulkExport(
      getExportRecordType(),
      chosenRecords,
      null,
      null,
      controller
    );
  };

  const handleToggleColumn = () => {
    setColumnToggleOn((prev) => !prev);
    if (toggleColumn) {
      toggleColumn(toggleColumnName);
    }
  };

  const handleRequestClose = () => {
    setOpenCreateProcessingDialog(false);
    setOpenCreateAssessmentDialog(false);
    setOpenCreateBreachDialog(false);
    setOpenCreateDocumentDialog(false);
    setOpenCreateCustomRecordDialog(false);
    setOpenExportDialog(false);
    setOpenBulkUpdateDialog(false);
    setOpenBulkUpdateRejectDialog(false);
    setOpenBulkDeleteDialog(false);
    setBulkUpdateType(undefined);
    setBulkUpdateData(undefined);
    setDisplayValidationErrors(false);
    setValidationMessage([]);
  };

  const handleCloseValidation = () => {
    setDisplayValidationErrors(false);
    resetValidationErrors();
    resetBulkItems();
  };

  const handleCloseSummaryDialog = () => {
    setOpenSummaryDialog(false);
    resetCheckedFields('checkedFields', {});
  };

  const handleBackFromSummary = () => {
    setOpenSummaryDialog(false);
    setOpenBulkUpdateDialog(true);
  };

  const handleOpenMenu = (event) => {
    setOpenAddMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenAddMenu(false);
  };

  const handleMenuItemTouchTap = (recordType, recordLayouts) => {
    handleCloseMenu();
    if (recordType === 'processing') {
      handleCreateProcessing(recordLayouts);
    } else if (recordType === 'assessment') {
      createAssessment(recordLayouts);
    } else if (recordType === 'breach') {
      handleCreateBreach(recordLayouts);
    } else createCustomRecord(recordLayouts);
  };

  const handleClick = () => {
    removeFilters(
      getModifiedRecordType(currentFilter),
      filterMenuData?.recordLayoutId,
      isTemplateMode
    );
  };

  const handleSummaryDialog = (type, data, checkedFields) => {
    setOpenSummaryDialog(true);
    setBulkUpdateType(type);
    setBulkUpdateData(data);
    setCheckedFields(checkedFields);
  };

  const handleBulkExportClick = () => {
    setOpenExportDialog(true);
  };

  const validateRecords = () => {
    const jurisdictionNames = supportedJurisdictions.map(
      (jurisdiction) => jurisdiction.data
    );
    const jurisdictions = chosenRecords.map((item) => item.jurisdictions);
    let hasSameJurisdiction = false;

    jurisdictionNames.forEach((jurisdiction) => {
      hasSameJurisdiction =
        hasSameJurisdiction ||
        jurisdictions.filter((item) => item.includes(jurisdiction)).length ===
          jurisdictions.length;
    });

    const hasRequestedRecord = chosenRecords.some(
      (each) => each.status === 'record_status_requested' 
      || each.status === 'record_status_external_submitted' || each.status === 'record_status_external_rejected'
    );

    const errorMessage = [...validationMessage];
    if (!hasSameJurisdiction) {
      errorMessage.push(recordTranslations.differentJurisdictions);
    }
    if (hasRequestedRecord) {
      errorMessage.push(recordTranslations.nonEditableRecords);
    }

    setValidationMessage(errorMessage);

    return !hasSameJurisdiction || hasRequestedRecord;
  };

  const handleBulkUpdateClick = () => {
    // bulk update is restricted if any of the selected record has status "requested" or
    // if the jurisdictions of the selected records are different
    const rejectBulkUpdate = validateRecords();
    if (rejectBulkUpdate) {
      setOpenBulkUpdateRejectDialog(true);
    } else {
      setOpenBulkUpdateDialog(true);
      setBulkUpdateType(undefined);
      setBulkUpdateData(undefined);
      setCheckedFields(undefined);
    }
  };

  const handleBulkDeleteClick = () => {
    setOpenBulkDeleteDialog(true);
  };

  const handleSmartSearch = (event, clearSearch = false) => {
    const searchText = clearSearch ? '' : event.currentTarget.value;
    setCurrentSearchText(searchText);

    // Clear any existing timer
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Create a new timer
    const newTimeoutId = setTimeout(() => {
      const searchParameters = {
        isPageSearch: true,
        searchText,
        searchKey: 'allFields',
        sortOn: 'lastChanged',
        sortOrder: 'DESC'
      };

      if (onSearch) {
        onSearch(
          searchParameters,
          getModifiedRecordType(currentFilter),
          filterMenuData?.recordLayoutId,
          isTemplateMode
        );
      }
    }, 1000);

    // Set the new timer ID
    setTimeoutId(newTimeoutId);
  };

  const handleToggleSwitch = (checked) => {
    toggleMode(checked, getModifiedRecordType(currentFilter));
  };

  const isFilterApplied = getFilterApplied();
  const accessibleRecordItems = getAccessibleRecordTypes(
    userPermissions.toJS(),
    recordTypeList,
    locale,
    tenantLocale
  );

  const isCreatePossible = checkForRecordCreation(pricingPlan);
  const selectedBulkRecords = getSelectedRecords();
  const disableCreation = filterMenuData?.status === 'Inactive';
  const isDefaultProcessing =
    currentFilter === 'processing_processings' ||
    currentFilter === 'processings';
  const bulkExportLimit = process.env.REACT_APP_BULK_EXPORT_LIMIT || 25;

  const shouldRenderCreateButton = (recordAccess) => {
    const hasCreateAccess = isTemplateMode
      ? createTemplate && recordAccess
      : recordAccess;
    return isCreatePossible && hasCreateAccess;
  };

  return (
    <div>
      <div>
        {prevLocation && getGoBackLink(prevLocation, theme, { top: 165 })}{' '}
      </div>
      <Toolbar disableGutters={true}>
        <div style={{ ...style.toolbarTitle, flexGrow: 1 }}>
          {getHeaderName(currentFilter, filterMenuData?.label)}
        </div>
        <div style={{ ...style.toolbarTitle, flexGrow: 1 }}>{}</div>
        <div style={{ display: 'flex' }}>
          {showRecordModeToggle && viewTemplate && (
            <div style={{ paddingRight: '15px' }}>
              {recordTranslations.records}
              <Switch
                checked={isTemplateMode}
                disabled={disableModeSwitch}
                onChange={(event) => {
                  handleToggleSwitch(event.target.checked);
                }}
                value="isTemplateMode"
                color="primary"
              />
              {recordTranslations.template}
            </div>
          )}
          {isFilterApplied && (
            <Button
              id="remove_filter_button"
              variant="text"
              onClick={handleClick}
            >
              {commonTranslations.removeFilters}
            </Button>
          )}
          <div
            id="record-smart-search"
            style={{
              marginLeft: '15px',
              width: '300px',
              alignSelf: 'center'
            }}
          >
            <TextField
              autoFocus={true}
              id="privacy_record_page_search"
              style={{
                paddingLeft: '5px',
                height: '30px',
                backgroundColor: '#ffffff',
                width: '85%',
                border: '1px solid #9b9b9b',
                borderRadius: '3px'
              }}
              value={currentSearchText}
              onChange={handleSmartSearch}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {currentSearchText && currentSearchText !== '' ? (
                      <Clear
                        style={{ cursor: 'pointer' }}
                        onClick={(event) => handleSmartSearch(event, true)}
                      />
                    ) : (
                      <Search />
                    )}
                  </InputAdornment>
                )
              }}
            />
          </div>
          {shouldRenderCreateButton(createPrivacyRecord) &&
            currentFilter === 'all' && (
              <ArrowTooltip
                title={
                  <FormattedMessage
                    id="PrivacyRecords.addRecord"
                    defaultMessage="Add privacy record"
                  />
                }
              >
                <Button
                  id="add_record_button"
                  className="button_icon_no_margin"
                  onClick={handleOpenMenu}
                  style={{ marginRight: '10px' }}
                >
                  <Add style={{ cursor: 'pointer', fontSize: 25 }} />
                </Button>
              </ArrowTooltip>
          )}
          <Menu
            id="add_record_menu"
            open={openAddMenu}
            anchorEl={anchorEl}
            onClose={handleCloseMenu}
          >
            {accessibleRecordItems.map(
              ({ action, primaryText, recordTypeData }) => (
                <MenuItem
                  style={{ width: 190, whiteSpace: 'normal' }}
                  id={action}
                  key={action}
                  primaryText={primaryText}
                  onClick={() => handleMenuItemTouchTap(action, recordTypeData)}
                >
                  {primaryText}
                </MenuItem>
              ),
              this
            )}
          </Menu>

          {shouldRenderCreateButton(createProcessing) &&
            currentFilter.split('_')[1] === 'processings' && (
              <ArrowTooltip
                title={
                  <FormattedMessage
                    id="PrivacyRecords.addProcessing"
                    defaultMessage="Add Processing"
                  />
                }
              >
                <Button
                  id="create_processing_button"
                  className="button_icon_no_margin"
                  onClick={handleCreateProcessing}
                  style={{ marginRight: '10px' }}
                  disabled={disableCreation}
                >
                  <Add style={{ cursor: 'pointer', fontSize: 25 }} />
                </Button>
              </ArrowTooltip>
          )}
          {shouldRenderCreateButton(createPreDpia) &&
            currentFilter.split('_')[1] === 'assessments' &&
            currentFilter !== 'tia_assessments' && (
              <ArrowTooltip
                title={
                  <FormattedMessage
                    id="PrivacyRecords.addAssessment"
                    defaultMessage="Add Assessment"
                  />
                }
              >
                <Button
                  id="create_assessment_button"
                  className="button_icon_no_margin"
                  onClick={() => createAssessment('dpia')}
                  style={{ marginRight: '10px' }}
                  disabled={disableCreation}
                >
                  <Add style={{ cursor: 'pointer', fontSize: 25 }} />
                </Button>
              </ArrowTooltip>
          )}
          {shouldRenderCreateButton(createBreach) &&
            currentFilter.split('_').pop() === 'breaches' && (
              <ArrowTooltip
                title={
                  <FormattedMessage
                    id="PrivacyRecords.addBreach"
                    defaultMessage="Add Breach"
                  />
                }
              >
                <Button
                  id="create_breach_button"
                  className="button_icon_no_margin"
                  onClick={handleCreateBreach}
                  style={{ marginRight: '10px' }}
                  disabled={disableCreation}
                >
                  <Add style={{ cursor: 'pointer', fontSize: 25 }} />
                </Button>
              </ArrowTooltip>
          )}
          {shouldRenderCreateButton(createTia) &&
            currentFilter === 'tia_assessments' && (
              <ArrowTooltip title={pluralTerms('tia')}>
                <Button
                  id="create_tia_button"
                  className="button_icon_no_margin"
                  onClick={() => createAssessment(currentFilter)}
                  style={{ marginRight: '10px' }}
                  disabled={disableCreation}
                >
                  <Add style={{ cursor: 'pointer', fontSize: 25 }} />
                </Button>
              </ArrowTooltip>
          )}
          {shouldRenderCreateButton(createEditDocumentRecord) &&
            currentFilter.split('_')[1] === 'document-records' && (
              <ArrowTooltip title={sectionHeaderTranslations.documentRecords}>
                <Button
                  id="create_document_records_button"
                  className="button_icon_no_margin"
                  onClick={() => createDocument(currentFilter)}
                  style={{ marginRight: '10px' }}
                  disabled={disableCreation}
                >
                  <Add style={{ cursor: 'pointer', fontSize: 25 }} />
                </Button>
              </ArrowTooltip>
          )}
          {shouldRenderCreateButton(createCustom) &&
            !defaultRecordLayouts.includes(
              currentFilter.split('_')[1] || currentFilter
            ) && (
              <ArrowTooltip
                title={
                  <FormattedMessage
                    id="PrivacyRecords.addCustom"
                    defaultMessage="Add custom record"
                  />
                }
              >
                <Button
                  id="create_custom_button"
                  className="button_icon_no_margin"
                  onClick={createCustomRecord}
                  style={{ marginRight: '10px' }}
                  disabled={disableCreation}
                >
                  <Add style={{ cursor: 'pointer', fontSize: 25 }} />
                </Button>
              </ArrowTooltip>
          )}
          {!isTemplateMode && allowPDFDownload && (
            <ArrowTooltip
              title={
                chosenRecords.length > bulkExportLimit
                  ? customTranslation(
                    'pdfBulkExportDisabledHint',
                    bulkExportLimit
                  )
                  : recordTranslations.pdfBulkExportHint
              }
            >
              <Button
                style={{ marginRight: '10px' }}
                id="bulk-pdf-export"
                className="button_icon_no_margin"
                disabled={
                  chosenRecords.length < 1 ||
                  chosenRecords.length > bulkExportLimit
                }
                onClick={handleBulkExportClick}
              >
                <FontAwesomeIcon
                  icon={faFileArchive}
                  style={{ color: 'white', fontSize: '24px' }}
                />
              </Button>
            </ArrowTooltip>
          )}
          {!isTemplateMode &&
            showBulkDeleteIcon &&
            deleteProcessing &&
            hasNoLimit && (
              <ArrowTooltip
                title={
                  <FormattedMessage
                    id="MasterData.bulkDelete"
                    description="Delete"
                    defaultMessage="Bulk Delete"
                  />
                }
              >
                <Button
                  style={{ marginRight: '10px' }}
                  className="button_icon_no_margin"
                  disabled={chosenRecords.length < 2}
                  onClick={handleBulkDeleteClick}
                >
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    style={{ color: 'white', fontSize: '24px' }}
                  />
                </Button>
              </ArrowTooltip>
          )}
          <div style={{ marginRight: 6 }}>
            {!isTemplateMode &&
              showToggleButton &&
              getComplianceButton(columnToggleOn, handleToggleColumn)}
          </div>
          {!isTemplateMode &&
            hasNoLimit &&
            exportPdf &&
            isDefaultProcessing &&
            !!currentFilter.split('_')[1] &&
            createProcessing && (
              <div>
                <Button
                  id="bulk-pdf-export"
                  disabled={chosenRecords.length < 2}
                  onClick={handleBulkUpdateClick}
                >
                  {recordTranslations.bulkUpdate}
                </Button>
              </div>
          )}
        </div>
      </Toolbar>

      {openCreateProcessingDialog && (
        <CreateProcessingDialog
          id="processing-dialog"
          open={openCreateProcessingDialog}
          onRequestClose={handleRequestClose}
          subRecordTypes={recordTypeData?.recordLayouts}
          currentRecordType={currentFilter}
          layoutId={filterMenuData?.recordLayoutId}
          filterMenuData={filterMenuData}
        />
      )}

      {openCreateAssessmentDialog && (
        <CreateAssessmentDialog
          id="assessment-dialog"
          open={openCreateAssessmentDialog}
          onRequestClose={handleRequestClose}
          subRecordTypes={recordTypeData?.recordLayouts}
          currentRecordType={currentFilter}
          layoutId={filterMenuData?.recordLayoutId}
          filterMenuData={filterMenuData}
        />
      )}
      {openCreateBreachDialog && (
        <CreateBreachDialog
          id="breach-dialog"
          open={openCreateBreachDialog}
          onRequestClose={handleRequestClose}
          subRecordTypes={recordTypeData?.recordLayouts}
          currentRecordType={currentFilter}
          layoutId={filterMenuData?.recordLayoutId}
          filterMenuData={filterMenuData}
        />
      )}
      {openCreateDocumentDialog && (
        <CreateDocumentDialog
          open={openCreateDocumentDialog}
          onRequestClose={handleRequestClose}
          currentRecordType={currentFilter}
          layoutId={filterMenuData?.recordLayoutId}
          filterMenuData={filterMenuData}
        />
      )}
      {openCreateCustomRecordDialog && (
        <CreateCustomRecordDialog
          open={openCreateCustomRecordDialog}
          onRequestClose={handleRequestClose}
          currentFilter={currentFilter}
          filterMenuData={
            currentFilter === 'all'
              ? {
                recordLabel: getTranslatedLabel(
                  recordTypeData.names,
                  locale,
                  tenantLocale
                )
              }
              : filterMenuData
          }
          subRecordTypes={recordTypeData?.recordLayouts}
          isCustomFromAll={currentFilter === 'all'}
          recordTypeId={recordTypeData?.id}
          layoutId={filterMenuData?.recordLayoutId}
        />
      )}
      {openExportDialog && (
        <ExportReportDialog
          open={openExportDialog}
          onSubmit={bulkExport}
          defaultValue={getAptDefaultValue(currentFilter)}
          exportTypes={getAptPDFExportTypes(currentFilter)}
          onRequestClose={handleRequestClose}
        />
      )}
      {openBulkUpdateDialog && (
        <BulkUpdateDialog
          open={openBulkUpdateDialog}
          onRequestClose={handleRequestClose}
          selectedRecords={chosenRecords}
          handleSummaryDialog={(type, data, checkedFields) =>
            handleSummaryDialog(type, data, checkedFields)
          }
          updateItems={bulkUpdateData}
          updateType={bulkUpdateType}
        />
      )}
      {displayValidationErrors && (
        <BulkUpdateValidationErrors
          open={displayValidationErrors}
          requestClose={handleCloseValidation}
          selectedRecords={chosenRecords}
          updateItems={bulkUpdateData}
          updateType={bulkUpdateType}
        />
      )}
      {openBulkUpdateRejectDialog && (
        <CustomDialog
          show={openBulkUpdateRejectDialog}
          proceed={handleRequestClose}
          isDelete={false}
          title={recordTranslations.bulkUpdate}
          hideConfirmation={true}
          content={getErrorMessage()}
        />
      )}
      {openBulkDeleteDialog && (
        <BulkDelete
          open={openBulkDeleteDialog}
          onSubmit={handleBulkDelete}
          onRequestClose={handleRequestClose}
          items={selectedBulkRecords}
        />
      )}
      {openSummaryDialog && (
        <BulkUpdateSummary
          open={openSummaryDialog}
          selectedRecords={chosenRecords}
          visibleFields={visibility}
          updateItems={bulkUpdateData}
          checkedFields={checkedFields}
          updateType={bulkUpdateType}
          requestClose={handleCloseSummaryDialog}
          handleGoBack={handleBackFromSummary}
        />
      )}
    </div>
  );
};

PrivacyRecordsHeader.propTypes = {
  showToggleButton: PropTypes.bool,
  showRisks: PropTypes.bool,
  toggleColumnName: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentFilter: PropTypes.string,
  toggleColumn: PropTypes.func,
  prevLocation: PropTypes.string,
  theme: PropTypes.shape({
    palette: PropTypes.shape({
      primary: PropTypes.shape({
        main: PropTypes.string
      })
    })
  }),
  filteredOn: PropTypes.shape({
    toJS: PropTypes.func,
    values: PropTypes.func
  }),
  pricingPlan: PropTypes.instanceOf(Immutable.Map),
  removeFilters: PropTypes.func,
  selectedRecords: PropTypes.arrayOf(PropTypes.string),
  recordsBulkExport: PropTypes.func,
  isDownloading: PropTypes.bool,
  toggleLoader: PropTypes.func,
  allowPDFDownload: PropTypes.bool,
  onSearch: PropTypes.func,
  searchText: PropTypes.shape({
    get: PropTypes.func
  }),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  resetCheckedFields: PropTypes.func,
  showBulkDeleteIcon: PropTypes.bool,
  bulkDelete: PropTypes.func,
  listItems: PropTypes.instanceOf(Immutable.List),
  resetBulkItems: PropTypes.func,
  visibility: PropTypes.arrayOf(PropTypes.string),
  showValidationErrors: PropTypes.bool,
  resetValidationErrors: PropTypes.func,
  locale: PropTypes.string,
  tenantLocale: PropTypes.string,
  recordTypeList: PropTypes.arrayOf(PropTypes.shape({})),
  filterMenuData: PropTypes.shape({
    recordLayoutId: PropTypes.string,
    label: PropTypes.string,
    status: PropTypes.string
  }),
  userPermissions: PropTypes.instanceOf(Immutable.List),
  hasNoLimit: PropTypes.bool,
  toggleMode: PropTypes.func,
  isTemplateMode: PropTypes.bool,
  showRecordModeToggle: PropTypes.bool,
  disableModeSwitch: PropTypes.bool
};

PrivacyRecordsHeader.defaultProps = {
  showToggleButton: false,
  showRisks: false,
  currentFilter: 'all',
  toggleColumn: (e) => e,
  resetBulkItems: (e) => e,
  prevLocation: undefined,
  theme: {},
  pricingPlan: Immutable.Map(),
  filteredOn: {},
  removeFilters: (e) => e,
  resetCheckedFields: (e) => e,
  selectedRecords: [],
  visibility: [],
  recordsBulkExport: (e) => e,
  isDownloading: false,
  toggleLoader: (e) => e,
  allowPDFDownload: false,
  onSearch: (e) => e,
  searchText: {},
  showBulkDeleteIcon: false,
  bulkDelete: (e) => e,
  listItems: [],
  showValidationErrors: false,
  resetValidationErrors: (e) => e,
  locale: '',
  tenantLocale: '',
  recordTypeList: [],
  filterMenuData: {},
  userPermissions: Immutable.List(),
  hasNoLimit: false,
  toggleMode: (e) => e,
  isTemplateMode: false,
  showRecordModeToggle: false,
  disableModeSwitch: false
};

PrivacyRecordsHeader.contextTypes = {
  router: PropTypes.shape({}).isRequired
};

export default injectIntl(PrivacyRecordsHeader);
