import { takeEvery } from 'redux-saga/effects';

import {
  fetchHelpNotes,
  updateHelpNotes
} from '@packages/features/settings/components/custom/sagas';
import {
  fetchDSRDetails,
  updateDSRDetails
} from '@packages/features/settings/components/dsrSettings/sagas';
import {
  updateFieldsVisibility,
  fetchRoleGroupVisibilityInit
} from '@packages/features/settings/components/fieldVisibility/sagas';
import {
  fetchTenantSettings,
  uploadTenantLogo,
  fetchLogo,
  updateTenantSettings,
  fetchTenantEmailPreference,
  updateTenantEmailPreference
} from '@packages/features/settings/components/general/sagas';
import {
  fetchApiTokenList,
  deleteApiToken,
  validatePassword,
  updateToken,
  filterApiTokenList
} from '@packages/features/settings/components/integration/sagas';
import {
  upsertRegistry,
  fetchRegistry,
  resetAndFetchRegistry,
  deleteRegistry,
  getPublicFieldVisibility,
  updatePublicFieldsVisibility,
  fetchRegistryList,
  searchRegistries,
  filterRegistries,
  updateRegistry
} from '@packages/features/settings/components/integration/public-registry/sagas';
import {
  fetchPasswordSettings,
  updatePasswordSettings
} from '@packages/features/settings/components/security/passwordSettings/sagas';
import {
  fetchSSOSettings,
  updateSSOSettings,
  downloadMetadata
} from '@packages/features/settings/components/security/ssosettings/sagas';
import { deletePortal, 
  fetchPortal, 
  fetchPortalList,
  filterPortals, 
  resetAndFetchPortalList, 
  searchPortals, 
  updatePortal, 
  upsertPortal } from '@packages/features/settings/components/integration/record-portal/sagas';

import {
  getAuditTrailSettings,
  updateAUditTrailSettings
} from './components/auditTrailSettings/sagas';

export default [
  [takeEvery, 'HELPTEXT:FETCH_INIT', fetchHelpNotes],
  [takeEvery, 'HELPTEXT:UPDATE', updateHelpNotes],

  [takeEvery, 'DSR:SETTINGS:FETCH_INIT', fetchDSRDetails],
  [takeEvery, 'DSR:SETTINGS:UPDATE', updateDSRDetails],

  [takeEvery, 'ROLE_GROUP:FIELDS:VISIBILITY', updateFieldsVisibility],
  [
    takeEvery,
    'ROLE_GROUP:FIELDS:VISIBILITY:REQUEST_INIT',
    fetchRoleGroupVisibilityInit
  ],

  [takeEvery, 'TENANT_EMAIL_PREFERENCE:FETCH', fetchTenantEmailPreference],
  [takeEvery, 'TENANT_EMAIL_PREFERENCE:UPDATE', updateTenantEmailPreference],
  [takeEvery, 'TENANT:SETTINGS:REQUEST_INIT', fetchTenantSettings],
  [takeEvery, 'TENANT:SETTINGS:LOGO:UPLOAD', uploadTenantLogo],
  [takeEvery, 'TENANT:SETTINGS:LOGO:FETCH', fetchLogo],
  [takeEvery, 'TENANT:SETTINGS:UPDATE', updateTenantSettings],

  [takeEvery, 'API:TOKEN:LIST:FETCH_INIT', fetchApiTokenList],
  [takeEvery, 'API:TOKEN:DELETE', deleteApiToken],
  [takeEvery, 'TOKENS:PASSWORD:VALIDATION', validatePassword],
  [takeEvery, 'API:TOKEN:UPDATE', updateToken],
  [takeEvery, 'API:TOKEN:LIST:FILTER_INIT', filterApiTokenList],

  [takeEvery, 'PUBLIC:REGISTRY:DETAIL:UPSERT', upsertRegistry],
  [takeEvery, 'PUBLIC:REGISTRY:FETCH:INIT', fetchRegistry],
  [takeEvery, 'PUBLIC:REGISTRY:LIST:FETCH_INIT', resetAndFetchRegistry],
  [takeEvery, 'PUBLIC:REGISTRY:LIST:NEXT_FETCH', fetchRegistryList],
  [takeEvery, 'PUBLIC:FIELD:VISIBILITY:FETCH', getPublicFieldVisibility],
  [takeEvery, 'PUBLIC:FIELDS:VISIBILITY:UPDATE', updatePublicFieldsVisibility],
  [takeEvery, 'PUBLIC:REGISTRY:UPDATE', updateRegistry],
  [takeEvery, 'PUBLIC:REGISTRY:DELETE:INIT', deleteRegistry],
  [takeEvery, 'PUBLIC:REGISTRY:LIST:SEARCH_INIT', searchRegistries],
  [takeEvery, 'PUBLIC:REGISTRY:LIST:FILTER_INIT', filterRegistries],

  [takeEvery, 'RECORD:PORTAL:DETAIL:UPSERT', upsertPortal],
  [takeEvery, 'RECORD:PORTAL:FETCH:INIT', fetchPortal],
  [takeEvery, 'RECORD:PORTAL:LIST:FETCH_INIT', resetAndFetchPortalList],
  [takeEvery, 'RECORD:PORTAL:LIST:NEXT_FETCH', fetchPortalList],
  [takeEvery, 'RECORD:PORTAL:UPDATE', updatePortal],
  [takeEvery, 'RECORD:PORTAL:DELETE:INIT', deletePortal],
  [takeEvery, 'RECORD:PORTAL:LIST:SEARCH_INIT', searchPortals],
  [takeEvery, 'RECORD:PORTAL:LIST:FILTER_INIT', filterPortals],

  [takeEvery, 'PASSWORDSETTINGS:REQUEST:FETCH', fetchPasswordSettings],
  [takeEvery, 'PASSWORDSETTINGS:UPDATE', updatePasswordSettings],
  [takeEvery, 'SSOSETTINGS:REQUEST_FETCH', fetchSSOSettings],
  [takeEvery, 'SSOSETTINGS:UPDATE', updateSSOSettings],
  [takeEvery, 'SSOSETTINGS:DOWNLOAD:META_DATA', downloadMetadata],
  [takeEvery, 'SETTINGS:AUDIT_TRAIL:FETCH', getAuditTrailSettings],
  [takeEvery, 'SETTINGS:AUDIT_TRAIL:UPDATE', updateAUditTrailSettings]
];
