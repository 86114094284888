import Immutable from 'immutable';

import { getFilteredOn } from '@packages/utils/reducer-utils';

const defaultState = Immutable.fromJS({
  portalDetails: Immutable.Map(),
  items: Immutable.List([]),
  position: 0,
  visibility: undefined,
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  filteredOn: Immutable.Map(),
  filterParams: Immutable.Map({
    sortOn: 'name',
    sortOrder: 'ASC'
  }),
  filterColumn: {},
  searchText: Immutable.Map()
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'RECORD:PORTAL:FETCH': {
      return state.set('portalDetails', defaultState.get('portalDetails'));
    }
    case 'RECORD:PORTAL:FETCH:SUCCESS': {
      return state.set('portalDetails', Immutable.Map(action.data));
    }
    case 'RECORD:PORTAL:LIST:FETCH':
      return state.set('items', defaultState.get('items'));
    case 'RECORD:PORTAL:LIST:FETCH:SUCCESS':
      return state
        .set('items', Immutable.List(action.data))
        .set('position', state.get('position') + action.data.length);
    case 'RECORD:PORTAL:LIST:UPDATE': {
      return state.set('items', Immutable.List(action.items));
    }
    case 'RECORD:PORTAL:LIST:SEARCH':
      return state
        .set('searchParams', action.searchParams)
        .set(
          'searchText',
          Immutable.Map({ name: action.searchParams.searchText })
        );
    case 'RECORD:PORTAL:LIST:FILTER': {
      const { filterParams } = action;
      const filterColumn = Object.assign({}, state.get('filterColumn').toJS(), {
        [filterParams.filterKey]: filterParams.filterKey
      });
      return state
        .set(
          'filteredOn',
          getFilteredOn(
            Object.assign({}, action.filterParams, {
              filterObj: state.get('filteredOn')
            })
          )
        )
        .set('filterColumn', Immutable.Map(filterColumn))
        .set('position', 0)
        .set('filterParams', Immutable.Map(action.filterParams));
    }
    case 'RECORD:PORTAL:LIST:FILTER:RESET': {
      return state
        .set('filterColumn', defaultState.get('filterColumn'))
        .set('filteredOn', defaultState.get('filteredOn'))
        .set('filterParams', defaultState.get('filterParams'));
    }
    case 'RECORD:PORTAL:CREATE:INIT':
    case 'RECORD:PORTAL:UPSERT:INIT':
      return state.set('error', defaultState.get('error'));
    case 'RECORD:PORTAL:UPSERT:FAIL':
      return state.set(
        'error',
        Immutable.Map({ message: action.error, isError: true })
      );
    case 'RECORD:PORTAL:DETAILS:RESET': 
      return state.set('portalDetails', defaultState.get('portalDetails'));
    default:
      return state;
  }
};
