import registry from 'app-registry';
import { put, select } from 'redux-saga/effects';
import { replace as replaceRouter } from 'connected-react-router';
import { push } from 'react-router-redux';
import { getParameterValuesFromHash } from '@packages/utils/query-parameters';
import {
  getErrorMessage,
  handleServiceDown,
  setLastActivityTime
} from '@packages/utils/common-utils';
import notificationtranslations from '@packages/utils/notificationtranslations';
import {
  getModifiedRecordData,
  getUserOrg,
  transformCopyRecordData
} from '../common-utils';
import { isRecordEditable, getRecordData } from '../saga-utils';
import {
  transformRequestData,
  transformResponseData,
  getUpdatedProperty
} from './utils/breachUtils';

function* getDefaultBreachData() {
  const user = yield select((state) => state.user);
  const aclOrgs = getUserOrg(user);
  return {
    description: '',
    startDate: null,
    endDate: null,
    measuresAddressingBreach: '',
    awareness: {},
    executingEntities: [],
    controllers: [],
    aclUsers: [],
    aclOrgs: aclOrgs.length > 1 ? [] : aclOrgs,
    natureOfBreach: { values: [], other: '' },
    breachConsequences: { values: [], other: '' },
    supervisoryNotification: {
      sent: true,
      supervisoryAuthorities: [],
      text: '',
      reference: '',
      date: null
    },
    dataSubjectNotification: {
      sent: true,
      dataSubjectCategories: [],
      text: '',
      reference: '',
      date: null
    },
    breachGrounds: [],
    processors: [],
    dataRecipients: [],
    dataSubjectCategories: [],
    dataRecipientCategories: [],
    numOfDataSubjects: -1,
    numOfPersonalDataRecords: -1,
    personalDataItems: [],
    dataSources: [],
    attachments: [],
    isExecuteAccess: true,
    status: 'record_status_draft',
    retentionTerms: [],
    personalDataCategories: [],
    references: [],
    text: {},
    htmlText: {},
    pickList: {},
    stakeholder: {},
    checkbox: {},
    picklist: {},
    scoredPicklist: {}
  };
}

const getBreachDetailFetchUrl = (
  recordId,
  isTemplateMode,
  isPreviewMode,
  libraryTenantId
) => {
  let url = '/v1/records';
  if (isTemplateMode) url += '/templates';
  url += `/breaches/${recordId}`;
  if (isPreviewMode) url += `/preview/${libraryTenantId}`;
  return url;
};

export function* initializeBreachDetail(action) {
  const { recordId } = getParameterValuesFromHash(
    '/:routeType/:recordId/:mode'
  );
  const {
    locale,
    tenantLocale,
    isTemplateMode,
    isPreviewMode,
    libraryTenantId
  } = action;

  yield put({ type: 'BREACH:DETAIL:FETCH', recordId });
  try {
    const response = yield registry
      .get('request')
      .get(
        getBreachDetailFetchUrl(
          recordId,
          isTemplateMode,
          isPreviewMode,
          libraryTenantId
        ),
        null
      );

    switch (response.status) {
      case 200:
        {
          const recordDetail = response.body;
          const userState = yield select((state) => state.user);
          const currentUser = userState ? userState.get('profile') : null;
          const isEditable = yield isRecordEditable(recordDetail, currentUser);
          const store = registry.get('store');

          const responseData = Object.assign(
            {},
            yield getDefaultBreachData(),
            recordDetail
          );
          const data = yield transformResponseData(responseData, currentUser);

          if (recordDetail.status === 'record_status_requested'
              || recordDetail.status === 'record_status_external_submitted') {
            store.dispatch(replaceRouter(`/breach/${recordDetail.id}/view`));
            yield put({
              type: 'BREACH:EDIT:RESTRICTED',
              isEditRestricted: true
            });
          }

          yield put({
            type: 'BREACH:DETAIL:FETCH:SUCCESS',
            isEditable,
            recordName: data.name,
            data
          });

          const { layoutId } = response.body;
          yield put({
            type: 'RECORD_LAYOUT:FETCH_INIT',
            recordType: 'BREACH',
            layoutId,
            locale,
            tenantLocale,
            libraryTenantId,
            isPreviewMode
          });
        }
        break;

      case 404:
      case 403: {
        const error = response.body.msg;
        yield put({ type: 'BREACH:DETAIL:FETCH:FAIL', error });

        const currentState = yield select((state) => state.breach);
        const store = registry.get('store');

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        if (!isPreviewMode)
          store.dispatch(replaceRouter(currentState.get('prevLocation')));

        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    const error = getErrorMessage(err);
    yield handleServiceDown(err, 'records');
    yield put({ type: 'BREACH:DETAIL:FETCH:FAIL', error });
  }
}

export function* upsertBreachDetail(action) {
  const request = registry.get('request');
  const {
    copyOfRecordId,
    data,
    createFromEdit,
    isVendor = false,
    templateId,
    isTemplateMode,
    templateFromRecord,
    refetchRecords,
    isGlobal
  } = action;

  yield put({ type: 'LOADER:TOGGLE', toggle: true });

  try {
    // If breach is a copy of an existing breach, then get that breach data.
    const defaultReqData =
      copyOfRecordId || templateId
        ? yield getRecordData({ copyOfRecordId, templateId }, 'breaches')
        : yield getDefaultBreachData();
    const modifiedData = getModifiedRecordData(defaultReqData, data);
    const userState = yield select((state) => state.user);
    const aclOrgs = getUserOrg(userState);
    const currentUser = userState ? userState.get('profile') : null;
    const newData =
      copyOfRecordId && !isTemplateMode
        ? transformCopyRecordData(modifiedData, currentUser, aclOrgs)
        : modifiedData;
    const requestData = transformRequestData(newData, isGlobal);
    const requestUrl = `/v1/records${
      isTemplateMode ? '/templates' : ''
    }/breaches`;
    const response = yield request.post(requestUrl, requestData);
    yield response;

    yield put({ type: 'LOADER:TOGGLE', toggle: false });

    switch (response.status) {
      case 201:
      case 202:
      case 200:
      case 204: {
        yield put({ type: 'BREACH:DETAIL:UPSERT:SUCCESS' });
        if (isVendor) {
          refetchRecords();
          const location =
            window.location.origin ||
            `${window.location.protocol}//${window.location.hostname}${
              window.location.port ? `:${window.location.port}` : ''
            }`;
          yield window.open(
            `${location}/#/breach/${response.body.id}/edit`,
            '_blank'
          );
        } else if (templateFromRecord) {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: notificationtranslations.templateCreated,
              type: 'success'
            }
          });
          yield put(replaceRouter('/privacyrecords'));
        } else if (isTemplateMode) {
          yield put(push(`/breach-template/${response.body.id}/edit`));
        } else if (createFromEdit) {
          yield put(push(`/breach/${response.body.id}/view`));
        } else {
          yield put(push(`/breach/${response.body.id}/edit`));
        }
        break;
      }
      case 403: {
        const error = response.body.msg;
        yield put({ type: 'BREACH:DETAIL:UPSERT:FAIL', error });

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
      case 409:
        yield put({
          type: 'BREACH:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        if (copyOfRecordId) {
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
        }
        break;
      case 423:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'warning'
          }
        });
        yield put({
          type: 'BREACH:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        yield put({ type: 'TENANT:PRICING:PLAN:INIT' });
        break;
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({
          type: 'BREACH:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    const error = getErrorMessage(err);
    yield put({ type: 'BREACH:DETAIL:UPSERT:FAIL', error });
  }
}

// Update breach fields
export function* updateBreachProperty(action) {
  try {
    setLastActivityTime();
    const breachDetailState = yield select((state) => state.breach);
    const recordId = breachDetailState ? breachDetailState.get('recordId') : '';
    let data = breachDetailState ? breachDetailState.get('data').toJS() : {};

    const requestParams = getUpdatedProperty(
      action,
      recordId,
      data.createdBy ? data.createdBy.id : ''
    );
    const propertyObj = requestParams.renderData;
    data = Object.assign({}, data, propertyObj);
    yield put({ type: 'BREACH:PROPERTY:UPDATE:SUCCESS', data });
  } catch (err) {
    const error = getErrorMessage(err);
    yield put({
      type: 'NOTIFIER:NOTIFY',
      notification: {
        content: error,
        type: 'error'
      }
    });
    yield put({ type: 'BREACH:PROPERTY:UPDATE:FAIL', error });
  }
}

export function* upsertBreachRecordDetail(action) {
  const request = registry.get('request');
  const { copyOfRecordId, data, isTemplateMode } = action;
  yield put({ type: 'LOADER:TOGGLE', toggle: false });
  try {
    const defaultReqData = copyOfRecordId
      ? yield getRecordData({ copyOfRecordId }, 'breaches')
      : data;
    const requestData = transformRequestData(defaultReqData);
    const recordId = defaultReqData.id || '';
    const url = `/v1/records${
      isTemplateMode ? '/templates' : ''
    }/breaches/${recordId}`;
    const response = yield request.put(url, requestData);
    yield response;

    switch (response.status) {
      case 201:
      case 202:
      case 200:
      case 204: {
        /* eslint-disable no-underscore-dangle */
        const userState = yield select((state) => state.user);
        const currentUser = userState ? userState.get('profile') : null;
        const responseData = Object.assign(
          {},
          yield getDefaultBreachData(),
          response.body
        );
        const modifiedResponseData = yield transformResponseData(
          responseData,
          currentUser
        );
        const updatedData = {
          ...data,
          ...modifiedResponseData
        };
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        yield put({ type: 'BREACH:DETAIL:UPSERT:SUCCESS', data: updatedData });
        if (action.promoteInitiated) {
          yield put({ type: 'PRIVACY_RECORDS:ACTION_DIALOG:STATE_CHANGE' });
        }
        if (action.prevLocation !== '')
          yield put(replaceRouter(action.prevLocation));
        break;
      }
      case 403: {
        const error = response.body.msg;
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        yield put({ type: 'BREACH:DETAIL:UPSERT:FAIL', error });

        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });
        break;
      }
      case 409:
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        if (
          response.headers.get('reason') &&
          response.headers.get('reason') === 'version-mismatch'
        ) {
          yield put({ type: 'BREACH:DETAIL:UPSERT:LOCKED' });
        } else {
          yield put({
            type: 'BREACH:DETAIL:UPSERT:FAIL',
            error: response.body.msg
          });
        }
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      default: {
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({
          type: 'BREACH:DETAIL:UPSERT:FAIL',
          error: response.body.msg
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
    const error = getErrorMessage(err);
    yield put({ type: 'LOADER:TOGGLE', toggle: false });
    yield put({ type: 'BREACH:DETAIL:UPSERT:FAIL', error });
  }
}

export function* fetchUsersForBreach() {
  const { recordId } = getParameterValuesFromHash('/breach/:recordId/edit?:is');
  yield put({ type: 'BREACH:USERS:LIST:REQUEST:FETCH' });
  try {
    const response = yield registry
      .get('request')
      .get(`/v1/records/breaches/${recordId}/users`, {});
    switch (response.status) {
      case 200: {
        yield put({
          type: 'BREACH:USERS:LIST:REQUEST:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* saveBreachComment(action) {
  const currentState = yield select((state) => state.breach);
  const currentRecordData = currentState ? currentState.get('data').toJS() : {};
  let modifiedComments = [];
  const { comment, isEdit, id } = action;
  try {
    const request = registry.get('request');
    let response = {};
    if (!isEdit) {
      response = yield request.post(
        `/v1/records/breaches/${id}/comments`,
        comment
      );
    } else {
      response = yield request.put(
        `/v1/records/breaches/${id}/comments/${comment.id}`,
        comment
      );
    }
    switch (response.status) {
      case 200: {
        if (!isEdit) {
          modifiedComments = [...currentRecordData.comments, response.body];
        } else {
          const currentIndex = currentRecordData.comments.findIndex(
            (item) => item.id === response.body.id
          );
          modifiedComments = [...currentRecordData.comments];
          modifiedComments[currentIndex] = response.body;
        }
        currentRecordData.comments = modifiedComments;
        yield put({
          type: 'BREACH:COMMENT:SAVE:SUCCESS',
          data: currentRecordData
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.commentedSuccess,
            type: 'success'
          }
        });

        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}

export function* deleteBreachComment(action) {
  const { id, comment } = action;
  const currentState = yield select((state) => state.breach);
  const currentRecordData = currentState ? currentState.get('data').toJS() : {};
  const modifiedComments = [...currentRecordData.comments];
  try {
    const request = registry.get('request');
    const response = yield request.delete(
      `/v1/records/breaches/${id}/comments/${comment.id}`
    );
    switch (response.status) {
      case 204: {
        const currentIndex = modifiedComments.findIndex(
          (item) => item.id === comment.id
        );
        modifiedComments.splice(currentIndex, 1);
        currentRecordData.comments = modifiedComments;
        yield put({
          type: 'BREACH:COMMENT:DELETE:SUCCESS',
          data: currentRecordData
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.commentDeletedSuccess,
            type: 'success'
          }
        });

        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'records');
  }
}
