/* eslint-disable no-promise-executor-return */
import 'whatwg-fetch';
import registry from 'app-registry';
import queryString from 'query-string';
import fileSaver from 'file-saver';
import { replace as replaceRouter } from 'connected-react-router';

const defaultGetHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '-1'
};
const defaultPostHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache'
};
const { timeZone } = new Intl.DateTimeFormat().resolvedOptions();
const defaultReportDownloadHeaders = {
  Accept: 'text/csv',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  'X-USER-TIMEZONE': timeZone
};
const defaultDownloadHeaders = {
  Accept: 'application/octet-stream',
  'X-USER-TIMEZONE': timeZone
};
const defaultXLSXDownloadHeaders = {
  Accept: 'application/octet-stream',
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '-1'
};
const defaultUploadHeaders = {
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'Cache-Control': 'no-cache'
};
const defaultPutHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache'
};
const defaultDeleteHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache'
};

function checkStatus(response) {
  /* eslint-disable no-else-return */
  if (
    (response.status >= 200 && response.status < 300) ||
    response.status === 304 ||
    response.status === 403 ||
    response.status === 404 ||
    response.status === 409 ||
    response.status === 410 ||
    response.status === 412 ||
    response.status === 423 ||
    response.status === 400
  ) {
    return response;
  } else {
    const error = new Error(response.statusText);
    error.status = response.status;
    error.message = response.body.msg || response.body.content || response.body;
    error.response = response;
    throw error;
  }
  /* eslint-enable no-else-return */
}
function transformResponse(fetchResponse) {
  return new Promise((resolve) => {
    fetchResponse
      .text()
      .then((result) => {
        let body;
        try {
          body = JSON.parse(result);
        } catch (e) {
          registry.get('logger').info(e);
          body = { content: result };
        }
        resolve({
          headers: fetchResponse.headers,
          status: fetchResponse.status,
          type: fetchResponse.type,
          body
        });
      })
      .catch((e) => {
        registry.get('logger').error(e);
        resolve({
          headers: fetchResponse.headers,
          status: fetchResponse.status,
          body: { content: e },
          type: fetchResponse.type
        });
      });
  });
}
function getResponseAsBlob(fetchResponse) {
  return new Promise((resolve) => {
    if (fetchResponse.status === 200) {
      fetchResponse
        .blob()
        .then((result) => {
          let body;
          try {
            body = result;
            const headerDisposition = fetchResponse.headers.get(
              'Content-Disposition'
            );
            const fileNameAttr = headerDisposition.match(/filename="(.+)"/);
            const fileName =
              fileNameAttr && fileNameAttr.length > 0
                ? fileNameAttr[1]
                : 'fileName';
            const bloburl = new Blob([result], {
              type: 'application/octet-stream'
            });
            fileSaver.saveAs(bloburl, fileName);
          } catch (e) {
            registry.get('logger').info(e);
            body = { content: result };
          }
          resolve({
            headers: fetchResponse.headers,
            status: fetchResponse.status,
            type: fetchResponse.type,
            body
          });
        })
        .catch((e) => {
          registry.get('logger').error(e);
          resolve({
            headers: fetchResponse.headers,
            status: fetchResponse.status,
            body: { content: e },
            type: fetchResponse.type
          });
        });
    } else resolve(transformResponse(fetchResponse));
  });
}
function getResponseAsBlobForFile(fetchResponse) {
  return new Promise((resolve) => {
    if (fetchResponse.status === 200) {
      fetchResponse
        .blob()
        .then((result) => {
          let body;
          try {
            body = result;
            const file = new File([result]);
            body = file;
          } catch (e) {
            registry.get('logger').info(e);
            body = { content: result };
          }
          resolve({
            headers: fetchResponse.headers,
            status: fetchResponse.status,
            type: fetchResponse.type,
            body
          });
        })
        .catch((e) => {
          registry.get('logger').error(e);
          resolve({
            headers: fetchResponse.headers,
            status: fetchResponse.status,
            body: { content: e },
            type: fetchResponse.type
          });
        });
    } else resolve(transformResponse(fetchResponse));
  });
}
// This method is used to retrieve documents as bloburl
function getResponseAsBlobUrl(fetchResponse) {
  return new Promise((resolve) => {
    fetchResponse
      .blob()
      .then((result) => {
        let bloburl;
        try {
          const blob = new Blob([result], { type: 'application/octet-stream' });
          bloburl = URL.createObjectURL(blob);
        } catch (e) {
          registry.get('logger').info(e);
        }
        resolve({
          headers: fetchResponse.headers,
          status: fetchResponse.status,
          type: fetchResponse.type,
          body: bloburl
        });
      })
      .catch((e) => {
        registry.get('logger').error(e);
        resolve({
          headers: fetchResponse.headers,
          status: fetchResponse.status,
          body: { content: e },
          type: fetchResponse.type
        });
      });
  });
}
/**
 * Methods for wrapping the requests methods of the `fetch` module.
 */
function getMethod(
  url,
  query,
  options,
  isAuthRequired = true,
  saveLastRequestTime = true
) {
  return new Promise((resolve, reject) => {
    let headers = { ...defaultGetHeaders };
    if (options && options.headers) {
      headers = Object.assign(headers, options.headers);
    }
    if (isAuthRequired) {
      const config = registry.get('config');
      headers = Object.assign(headers, {
        [config.login.token.httpHeader]: registry
          .get('storage')
          .getItem(config.login.token.storage.key)
      });
    }
    if (query) {
      url += `?${queryString.stringify(query)}`;
    }
    // Excepted the API to get billing plan, Since it is fired in every single minute
    if (saveLastRequestTime) {
      // Set last request time.
      setLastRequestTime();
    }

    fetch(url, {
      method: 'GET',
      headers
    })
      .then(transformResponse)
      .then(checkStatus)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        registry.get('logger').error(err);
        verifyAuthAndRedirect(err, reject);
      });
  });
}

/**
 * Methods for wrapping the requests methods for ISMS module.
 */
function getISMSMethod(
  url,
  query,
  options,
  isAuthRequired = true,
  saveLastRequestTime = true
) {
  return new Promise((resolve, reject) => {
    let headers = { ...defaultGetHeaders };
    if (options && options.headers) {
      headers = Object.assign(headers, options.headers);
    }
    if (isAuthRequired) {
      const config = registry.get('config');
      headers = Object.assign(headers, {
        [config.login.token.ismsHttpHeader]: `Bearer ${registry
          .get('storage')
          .getItem(config.login.token.storage.key)}`
      });
    }
    if (query) {
      url += `?${queryString.stringify(query)}`;
    }
    // Excepted the API to get billing plan, Since it is fired in every single minute
    if (saveLastRequestTime) {
      // Set last request time.
      setLastRequestTime();
    }

    fetch(url, {
      method: 'GET',
      headers
    })
      .then(transformResponse)
      .then(checkStatus)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        registry.get('logger').error(err);
        verifyAuthAndRedirect(err, reject);
      });
  });
}

function postMethod(url, data, options, isAuthRequired = true) {
  return new Promise((resolve, reject) => {
    let headers = { ...defaultPostHeaders };
    if (options && options.headers) {
      headers = Object.assign(headers, options.headers);
    }
    if (isAuthRequired) {
      const config = registry.get('config');
      headers = Object.assign(headers, {
        [config.login.token.httpHeader]: registry
          .get('storage')
          .getItem(config.login.token.storage.key)
      });
    }
    if (url.lastIndexOf('/identity/refresh') === -1) {
      // Set last request time.
      setLastRequestTime();
    }

    fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(data || {})
    })
      .then(transformResponse)
      .then(checkStatus)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        registry.get('logger').error(err);
        verifyAuthAndRedirect(err, reject);
      });
  });
}
function postDocument(url, data, options, isAuthRequired = true) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', data.file);
    formData.append('name', data.name);
    formData.append('tags', JSON.stringify(data.tags));
    formData.append('description', data.description);
    formData.append('public', data.public);
    if (data.entityId) formData.append('entityId', data.entityId);
    if (data.documentType) formData.append('documentType', data.documentType);
    if (data.startDate)
      formData.append('startDate', JSON.parse(JSON.stringify(data.startDate)));
    if (data.endDate)
      formData.append('endDate', JSON.parse(JSON.stringify(data.endDate)));
    if (data.version) formData.append('version', data.version);

    let headers = { ...defaultUploadHeaders };
    const config = registry.get('config');
    if (options && options.headers) {
      headers = Object.assign(headers, options.headers);
    } else if (isAuthRequired) {
      headers = Object.assign(headers, {
        [config.login.token.httpHeader]: registry
          .get('storage')
          .getItem(config.login.token.storage.key)
      });
    }
    // Set last request time.
    setLastRequestTime();

    fetch(url, {
      method: 'POST',
      headers,
      body: formData
    })
      .then(transformResponse)
      .then(checkStatus)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        registry.get('logger').error(err);
        verifyAuthAndRedirect(err, reject);
      });
  });
}
function getDocument(url, options) {
  return new Promise((resolve, reject) => {
    let headers = { ...defaultDownloadHeaders };
    const config = registry.get('config');
    if (options && options.headers) {
      headers = Object.assign(headers, options.headers);
    } else {
      headers = Object.assign(headers, {
        [config.login.token.httpHeader]: registry
          .get('storage')
          .getItem(config.login.token.storage.key)
      });
    }

    // Set last request time.
    setLastRequestTime();

    fetch(url, {
      method: 'GET',
      headers
    })
      .then(getResponseAsBlob)
      .then(checkStatus)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        registry.get('logger').error(err);
        verifyAuthAndRedirect(err, reject);
      });
  });
}
function getXLSX(url, options) {
  return new Promise((resolve, reject) => {
    let headers = { ...defaultXLSXDownloadHeaders };
    const config = registry.get('config');
    if (options && options.headers) {
      headers = Object.assign(headers, options.headers);
    } else {
      headers = Object.assign(headers, {
        [config.login.token.httpHeader]: registry
          .get('storage')
          .getItem(config.login.token.storage.key)
      });
    }
    // Set last request time.
    setLastRequestTime();

    fetch(url, {
      method: 'GET',
      headers
    })
      .then(getResponseAsBlob)
      .then(checkStatus)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        registry.get('logger').error(err);
        verifyAuthAndRedirect(err, reject);
      });
  });
}
function postCsvDocument(url, data) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.set('file', data);
    let headers = { ...defaultUploadHeaders };
    const config = registry.get('config');
    headers = Object.assign(headers, {
      [config.login.token.httpHeader]: registry
        .get('storage')
        .getItem(config.login.token.storage.key)
    });

    // Set last request time.
    setLastRequestTime();

    fetch(url, {
      method: 'POST',
      headers,
      body: formData
    })
      .then(transformResponse)
      .then(checkStatus)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        registry.get('logger').error(err);
        verifyAuthAndRedirect(err, reject);
      });
  });
}
function exportCSVbyGet(url) {
  return new Promise((resolve, reject) => {
    let headers = { ...defaultReportDownloadHeaders };
    const config = registry.get('config');
    headers = Object.assign(headers, {
      [config.login.token.httpHeader]: registry
        .get('storage')
        .getItem(config.login.token.storage.key)
    });

    // Set last request time.
    setLastRequestTime();

    fetch(url, {
      method: 'GET',
      headers
    })
      .then(getResponseAsBlob)
      .then(checkStatus)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        registry.get('logger').error(err);
        verifyAuthAndRedirect(err, reject);
      });
  });
}
function exportCSVbyPost(url, data, signal, controller) {
  if (controller) {
    controller.abort();
  } else {
    return new Promise((resolve, reject) => {
      let headers = { ...defaultReportDownloadHeaders };
      const config = registry.get('config');
      headers = Object.assign(headers, {
        [config.login.token.httpHeader]: registry
          .get('storage')
          .getItem(config.login.token.storage.key)
      });
      // Set last request time.
      setLastRequestTime();
      fetch(url, {
        signal,
        method: 'POST',
        headers,
        body: JSON.stringify(data || {})
      })
        .then(getResponseAsBlob)
        .then(checkStatus)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          registry.get('logger').error(err);
          verifyAuthAndRedirect(err, reject);
        });
    });
  }
  return null;
}
function exportCSVAsFile(url, data) {
  return new Promise((resolve, reject) => {
    let headers = { ...defaultReportDownloadHeaders };
    const config = registry.get('config');
    headers = Object.assign(headers, {
      [config.login.token.httpHeader]: registry
        .get('storage')
        .getItem(config.login.token.storage.key)
    });
    // Set last request time.
    setLastRequestTime();
    fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(data || {})
    })
      .then(getResponseAsBlobForFile)
      .then(checkStatus)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        registry.get('logger').error(err);
        verifyAuthAndRedirect(err, reject);
      });
  });
}
function getImage(url, options, isAuthRequired = true) {
  return new Promise((resolve, reject) => {
    let headers = { ...defaultDownloadHeaders };
    if (options && options.headers) {
      headers = Object.assign(headers, options.headers);
    }
    if (isAuthRequired) {
      const config = registry.get('config');
      headers = Object.assign(headers, {
        [config.login.token.httpHeader]: registry
          .get('storage')
          .getItem(config.login.token.storage.key)
      });
    }

    // Set last request time.
    setLastRequestTime();

    fetch(url, {
      method: 'GET',
      headers
    })
      .then(getResponseAsBlobUrl)
      .then(checkStatus)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        registry.get('logger').error(err);
        verifyAuthAndRedirect(err, reject);
      });
  });
}
function putMethod(url, data, options, isAuthRequired = true) {
  return new Promise((resolve, reject) => {
    let headers = { ...defaultPutHeaders };
    if (options && options.headers) {
      headers = Object.assign(headers, options.headers);
    }
    if (isAuthRequired) {
      const config = registry.get('config');
      headers = Object.assign(headers, {
        [config.login.token.httpHeader]: registry
          .get('storage')
          .getItem(config.login.token.storage.key)
      });
    }

    // Set last request time.
    setLastRequestTime();

    fetch(url, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data || {})
    })
      .then(transformResponse)
      .then(checkStatus)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        registry.get('logger').error(err);
        verifyAuthAndRedirect(err, reject);
      });
  });
}
function deleteMethod(url, options, isAuthRequired = true, data) {
  return new Promise((resolve, reject) => {
    let headers = { ...defaultDeleteHeaders };
    if (options && options.headers) {
      headers = Object.assign(headers, options.headers);
    }
    if (isAuthRequired) {
      const config = registry.get('config');
      headers = Object.assign(headers, {
        [config.login.token.httpHeader]: registry
          .get('storage')
          .getItem(config.login.token.storage.key)
      });
    }

    // Set last request time.
    setLastRequestTime();

    fetch(url, {
      method: 'DELETE',
      headers,
      body: JSON.stringify(data || {})
    })
      .then(transformResponse)
      .then(checkStatus)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        registry.get('logger').error(err);
        verifyAuthAndRedirect(err, reject);
      });
  });
}
/**
 * Method which Verifies authentication and redirect the user to login accordingly.
 */
export function verifyAuthAndRedirect(error, rejectCallback) {
  // Checks for Unauthorized Exception and redirect to login.
  if (
    window.location.hash.indexOf('/login') !== 1 &&
    window.location.hash.indexOf('/vendorforms') !== 1 &&
    ((error && error.response && error.response.status === 401) ||
      error.message === 'TypeMismatchError')
  ) {
    // response was 401 or Edge threw weird error
    return new Promise((resolve) =>
      fetch('/v1/sso/url', {
        method: 'GET'
      })
        .then(clearCookieAndCache)
        .then(reloadApplication)
        .then(resolve)
    );
  }
  if (rejectCallback) {
    return rejectCallback(error);
  } else return null;
}

function clearCookieAndCache(fetchResponse) {
  return new Promise((resolve) => {
    const config = registry.get('config');
    registry.get('storage').removeItem(config.login.token.storage.key);
    registry.get('storage').removeItem(config.login.user.storage.key);
    // removing cookie by setting it empty and setting an old expiry date
    document.cookie =
      'X-AUTH-PRIVACYPERFECT= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
    fetchResponse.text().then((result) => {
      let body;
      try {
        body = JSON.parse(result);
      } catch (e) {
        registry.get('logger').info(e);
        body = { content: result };
      }
      resolve({
        body
      });
    });
  });
}

function reloadApplication(fetchResponse) {
  if (fetchResponse.body) {
    window.location.replace(fetchResponse.body);
  } else {
    // store.dispatch(replaceRouter("/login"));
    registry.get('store').dispatch(replaceRouter('/login'));
  }
}
function setLastRequestTime() {
  registry.get('storage').setItem('lastRequestTime', new Date().getTime());
}
export default {
  get: getMethod,
  getISMSMethod,
  getXLSX,
  post: postMethod,
  postDocument,
  getDocument,
  exportCSVbyPost,
  exportCSVAsFile,
  exportCSVbyGet,
  postCsvDocument,
  getImage,
  put: putMethod,
  delete: deleteMethod
};
