/* eslint-disable import/no-cycle */
import { connect } from 'react-redux';
import { NO_LIMIT } from '@packages/utils/common-utils';
import Documents from './document';

const mapStateToProps = (state) => ({
  documentData: state.environment.documents.get('documentData'),
  vendorDocumentData: state.vendorDashboard?.get('documentData'),
  actionError: state.environment.documents.get('error').toJS(),
  vendorActionError: state.vendorDashboard?.get('error').toJS(),
  isUploading:
    state.environment.documents.get('isUploading') ||
    state.vendorDashboard?.get('isUploading'),
  isFetching: state.vendorDashboard?.get('isFetching'),
  hasNoLimit: state.user.get('profile')?.get('userHierarchy') === NO_LIMIT
});

const mapDispatchToProps = (dispatch) => ({
  init: (id, isVendor, isCreate) => {
    if (isCreate) dispatch({ type: 'MASTERDATA:DOCUMENTS:ITEM:FETCH:INIT' });
    else dispatch({ type: 'DOCUMENTS:ITEM:FETCH', id, isVendor });
  },
  updateDocuments: (
    data,
    isEdit,
    isReplace,
    isUsed,
    isVendor,
    formDocumentRecord,
    isQuickAdd,
    isPublic
  ) => {
    if (formDocumentRecord) {
      dispatch({
        type: 'DOCUMENT:RECORD:ATTACHMENT:UPSERT',
        data,
        isEdit,
        formDocumentRecord,
        isQuickAdd,
        isPublic
      });
    } else
      dispatch({
        type: 'DOCUMENTS:UPSERT',
        data,
        isEdit,
        isReplace,
        isUsed,
        isVendor
      });
  },
  resetDocuments: () => {
    dispatch({ type: 'MASTERDATA:DOCUMENTS:ITEM:FETCH:INIT' });
  },
  deleteDocument: () => {
    dispatch({ type: 'VENDOR:DOCUMENTS:ITEM:DELETE:INIT' });
    // disable physical file delete
    // dispatch({ type: 'VENDOR:DOCUMENTS:ITEM:DELETE', dataItemId, isVendor });
    dispatch({ type: 'VENDOR:DOCUMENTS:ITEM:DELETE:SUCCESS' });
  },
  toggleLoader: (toggle, content) => {
    dispatch({ type: 'LOADER:TOGGLE', toggle, content });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Documents);
