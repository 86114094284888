/* eslint-disable import/no-cycle */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import FontAwesome from 'react-fontawesome';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import ActionHelp from '@material-ui/icons/Help';
import Error from '@material-ui/icons/Error';
import Button from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withTheme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import Divider from '@material-ui/core/Divider';

import ArrowTooltip from '@packages/components/tooltip';
import RiskCounter from '@packages/components/risk-counter';
import threatTranslations from '@packages/utils/threatTranslations';
import { singularTerms as uppercaseSingulars } from '@packages/utils/uppercaseTranslations';
import {
  commonTranslations,
  recordTranslations,
  getRiskTranslation,
  orgTypesTranslations
} from '@packages/utils/commontranslations';
import styles from '@packages/ui/styles';
import { getAllowedJurisdiction } from '@packages/utils/common-utils';

import { getRiskButtonBgColor, renderHelpNote } from '../common-utils';

import {
  OrganisationSelector,
  DpoSelector,
  LegalEntitySelector,
  AccessRightsSelector,
  SupervisoryAuthoritySelector,
  AttachmentSelector,
  PersonalItemSelector,
  RetentionTermSelector,
  DatasourceSelector,
  DataSubjectCategorySelector,
  ProcessingCategorySelector,
  PersonalDataCategorySelector,
  ProcessingGroundSelector,
  BreachGroundSelector,
  TransferGroundSelector,
  DataRecipientCategorySelector,
  Tree,
  SimpleEntitySelector,
  CustomSimpleMasterDataSelector
} from '../components';
import RecipientSelector from '../../message-center/components/messages/components/recipient-selector';
import GroupListDefault from '../processings/components/data-item-group';
import TagSelector from '../components/tag-selector';
import SimpleItemSelector from '../../dsr-detail/components/simple-item-selector';
import GroupSelector from '../components/group-selector';
import TetantOrTagSelector from '../../home/components/tenant-or-tag-selector';
import RecordsSelector from '../components/linked-records/components/records-selector';
import ItemList from '../components/item-list';
import './style.css';

const rootStyle = {
  width: '90%'
};

const subheaderStyle = {
  ...styles.labelField,
  paddingRight: '0px',
  position: 'inherit'
};

const listSubheaderStyle = {
  ...styles.labelField,
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  marginTop: 30
};

export const renderRangeField = ({
  input,
  label,
  helpNotes,
  onSelect,
  showSpecificField,
  meta: { error, submitFailed },
  style,
  disabled,
  risks,
  locale,
  ignoredRuleIds,
  selectedJurisdictions,
  mandatory
}) => (
  <div style={style || rootStyle}>
    {renderLabelWithRisk({
      headerLabel: label,
      helpNotes,
      risks,
      disabled,
      locale,
      ignoredRuleIds,
      selectedJurisdictions,
      mandatory
    })}
    <div>
      <Select
        style={{ ...styles.textField, ...styles.textBox, paddingLeft: 0 }}
        disabled={disabled}
        {...input}
        onChange={(event) => {
          input.onChange(event.target.value);
          onSelect(event.target.value);
        }}
      >
        <MenuItem value={-1}>{commonTranslations.unknown}</MenuItem>
        <MenuItem value={0}>{'< 100'}</MenuItem>
        <MenuItem value={100}>{'100 < 1 000'}</MenuItem>
        <MenuItem value={1000}>{'1 000 < 10 000'}</MenuItem>
        <MenuItem value={10000}>{'10 000 < 100 000'}</MenuItem>
        <MenuItem value={100000}>{'100 000 < 1 000 000'}</MenuItem>
        <MenuItem value={1000000}>{'1 000 000 < 10 000 000'}</MenuItem>
        <MenuItem value={10000000}>{'10 000 000 < 100 000 000'}</MenuItem>
        <MenuItem value={100000000}>{'> 100 000 000'}</MenuItem>
        {showSpecificField && (
          <MenuItem value={-2}>{commonTranslations.specific}</MenuItem>
        )}
      </Select>
    </div>
    <div style={styles.errorText}>
      {submitFailed && error && <span>{error}</span>}
    </div>
  </div>
);

renderRangeField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string
  }).isRequired,
  showSpecificField: PropTypes.bool,
  label: PropTypes.node,
  helpNotes: PropTypes.node,
  onSelect: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitFailed: PropTypes.bool
  }).isRequired
};

renderRangeField.defaultProps = {
  label: null,
  showSpecificField: false,
  helpNotes: null
};

const isChecked = (item, items) => {
  if (items) {
    const select =
      items &&
      items.findIndex((selectedItem) => selectedItem === item.value) !== -1;
    return select;
  }
  return false;
};
export const renderCheckList = ({
  fields,
  helpNotes,
  header,
  subHeader,
  selectedItems,
  categoryType,
  isFullWidth,
  risk,
  onCheck,
  onCheckHandler,
  type,
  fieldValues,
  align,
  helpNoteList,
  helpNoteListStyle,
  disabled,
  meta: { error, submitFailed }
}) => {
  const items = selectedItems || fields.getAll();
  const helpNoteStyle = helpNoteList
    ? { display: 'flex', alignItems: 'center', ...helpNoteListStyle }
    : '';
  return (
    <div style={{ width: align === 'vertical' ? '100%' : '90%' }}>
      {header && (
        <ListSubheader style={subheaderStyle}>
          {header}
          <div style={{ display: 'inline', float: 'right' }}>
            {helpNotes && (
              <ArrowTooltip
                id={categoryType ? `${categoryType}` : `${fields.name}`}
                title={helpNotes}
              >
                <span>
                  <IconButton style={styles.rightIcon}>
                    <ActionHelp color="primary" />
                  </IconButton>
                </span>
              </ArrowTooltip>
            )}
          </div>
        </ListSubheader>
      )}
      {subHeader && (
        <ListSubheader style={{ ...styles.subLabelField, position: 'inherit' }}>
          {subHeader}
        </ListSubheader>
      )}
      <div
        style={
          align === 'vertical' ? { display: 'flex', flexWrap: 'wrap' } : {}
        }
      >
        {fieldValues.map((item, index) => (
          <div
            style={
              align === 'vertical' && index % 2 === 0
                ? { ...styles.verticalCheckBox, ...helpNoteStyle }
                : {
                  ...(isFullWidth
                    ? {}
                    : {
                      ...styles.verticalCheckBox,
                      marginLeft: '8.6%',
                      ...helpNoteStyle
                    })
                }
            }
            key={item.value}
          >
            <FormGroup column={true}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(event, isInputChecked) =>
                      onCheck(
                        fields,
                        item,
                        items,
                        onCheckHandler,
                        isInputChecked
                      )
                    }
                    id={item.value}
                    key={`${item.label}_${item.value}_${type}`}
                    disabled={disabled || item.disabled}
                    color="primary"
                    style={{
                      height: 1,
                      alignSelf: 'baseline',
                      position: 'relative',
                      marginTop: 3
                    }}
                    checked={isChecked(item, items)}
                  />
                }
                label={item.label}
                style={styles.checkbox}
              />
            </FormGroup>
            {helpNoteList && (
              <div style={{ marginRight: -32 }}>
                <ArrowTooltip title={helpNoteList[item.helpNote]}>
                  <IconButton style={{ marginTop: -3 }}>
                    <ActionHelp color="primary" />
                  </IconButton>
                </ArrowTooltip>
              </div>
            )}
          </div>
        ))}
      </div>
      {risk && <div style={{ paddingTop: '10px' }}>{risk}</div>}
      <div style={styles.errorText}>
        {submitFailed && error && <span>{error}</span>}
      </div>
    </div>
  );
};

renderCheckList.propTypes = {
  onCheck: PropTypes.func,
  onCheckHandler: PropTypes.func,
  helpNotes: PropTypes.node,
  header: PropTypes.node,
  subHeader: PropTypes.node,
  fields: PropTypes.shape({
    map: PropTypes.func.isRequired,
    name: PropTypes.string,
    getAll: PropTypes.func
  }).isRequired,
  meta: PropTypes.shape({
    submitFailed: PropTypes.bool,
    error: PropTypes.string
  }).isRequired,
  fieldValues: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.shape({})),
  type: PropTypes.string,
  categoryType: PropTypes.string,
  align: PropTypes.string,
  helpNoteListStyle: PropTypes.shape({})
};

renderCheckList.defaultProps = {
  onCheck: (e) => e,
  onCheckHandler: (e) => e,
  helpNotes: null,
  header: null,
  subHeader: null,
  selectedItems: null,
  type: 'text',
  align: 'horizontal',
  categoryType: '',
  helpNoteListStyle: {}
};

export const getItemSelector = (
  type,
  isPartOf,
  itemLabel,
  entityType,
  dialogHeaderLabel,
  hintText,
  disabled,
  props,
  stakeHolderType,
  isNote
) => {
  if (type === 'linkedRecords') {
    return (
      <RecordsSelector
        hintTextLabel={hintText}
        label={itemLabel}
        disabled={disabled}
        {...props}
      />
    );
  }
  if (type === 'legalEntities') {
    return (
      <LegalEntitySelector
        hintTextLabel={hintText}
        label={itemLabel}
        entityType={entityType}
        dialogHeaderLabel={dialogHeaderLabel}
        createNewMenuItem={true}
        selectFromListMenuItem={true}
        showItemList={true}
        dataItemType={stakeHolderType}
        disabled={disabled}
        {...props}
      />
    );
  }
  if (type === 'dataSubjectCategories') {
    return (
      <DataSubjectCategorySelector
        hintTextLabel={hintText}
        label={itemLabel}
        createNewMenuItem={true}
        selectFromListMenuItem={true}
        isPartOf={isPartOf}
        dataItemType={type}
        disabled={disabled}
        {...props}
      />
    );
  }
  if (type === 'processingCategories') {
    return (
      <ProcessingCategorySelector
        hintTextLabel={hintText}
        label={itemLabel}
        createNewMenuItem={true}
        selectFromListMenuItem={true}
        dataItemType={type}
        disabled={disabled}
        {...props}
      />
    );
  }
  if (type === 'personalDataCategories') {
    return (
      <PersonalDataCategorySelector
        hintTextLabel={hintText}
        label={itemLabel}
        createNewMenuItem={true}
        selectFromListMenuItem={true}
        dataItemType={type}
        disabled={disabled}
        {...props}
      />
    );
  }
  if (type === 'personalDataItems') {
    return (
      <PersonalItemSelector
        hintTextLabel={hintText}
        label={itemLabel}
        createNewMenuItem={true}
        selectFromListMenuItem={true}
        dataItemType={type}
        disabled={disabled}
        {...props}
      />
    );
  }
  if (type === 'retentionTerms') {
    return (
      <RetentionTermSelector
        hintTextLabel={hintText}
        label={itemLabel}
        createNewMenuItem={true}
        dataItemType={type}
        disabled={disabled}
        {...props}
      />
    );
  }
  if (type === 'dataSources') {
    return (
      <DatasourceSelector
        hintTextLabel={hintText}
        label={itemLabel}
        createNewMenuItem={true}
        selectFromListMenuItem={true}
        dataItemType={type}
        disabled={disabled}
        {...props}
      />
    );
  }
  if (type === 'acl') {
    return (
      <AccessRightsSelector
        hintTextLabel={hintText}
        label={itemLabel}
        createNewMenuItem={false}
        dialogHeaderLabel={dialogHeaderLabel}
        selectFromListMenuItem={true}
        dataItemType="aclUsers"
        disabled={disabled}
        {...props}
      />
    );
  } else if (type === 'supervisoryAuthorities') {
    return (
      <SupervisoryAuthoritySelector
        hintTextLabel={hintText}
        label={itemLabel}
        createNewMenuItem={false}
        dialogHeaderLabel={dialogHeaderLabel}
        selectFromListMenuItem={true}
        dataItemType={type}
        disabled={disabled}
        {...props}
      />
    );
  } else if (type === 'organisation') {
    return (
      <OrganisationSelector
        hintTextLabel={hintText}
        label={itemLabel}
        createNewMenuItem={false}
        dialogHeaderLabel={dialogHeaderLabel}
        selectFromListMenuItem={true}
        dataItemType={type}
        disabled={disabled}
        {...props}
      />
    );
  } else if (type === 'dataRecipientCategories') {
    return (
      <DataRecipientCategorySelector
        hintTextLabel={hintText}
        label={itemLabel}
        createNewMenuItem={true}
        dialogHeaderLabel={dialogHeaderLabel}
        selectFromListMenuItem={true}
        dataItemType={type}
        disabled={disabled}
        {...props}
      />
    );
  } else if (type === 'recipients') {
    return (
      <RecipientSelector
        hintTextLabel={hintText}
        label={itemLabel}
        createNewMenuItem={props.createNewMenuItem}
        selectFromListMenuItem={props.selectFromListMenuItem}
        disabled={disabled}
        {...props}
      />
    );
  } else if (type === 'dataProtection') {
    return (
      <DpoSelector
        hintTextLabel={hintText}
        label={itemLabel}
        dataItemType={type}
        disabled={disabled}
        {...props}
      />
    );
  } else if (type === 'tags') {
    return (
      <TagSelector
        hintTextLabel={hintText}
        label={itemLabel}
        entityType={entityType}
        disabled={disabled}
        {...props}
      />
    );
  } else if (type === 'simpleItems') {
    return (
      <SimpleItemSelector
        hintTextLabel={hintText}
        entityType={entityType}
        dataItemType={entityType}
        selectFromListMenuItem={true}
        dialogHeaderLabel={dialogHeaderLabel}
        disabled={disabled}
        {...props}
      />
    );
  } else if (type === 'group') {
    return (
      <GroupSelector
        hintTextLabel={hintText}
        entityType={entityType}
        selectFromListMenuItem={false}
        dialogHeaderLabel={dialogHeaderLabel}
        disabled={disabled}
        {...props}
      />
    );
  } else if (type === 'tenantOrTagParent') {
    return (
      <TetantOrTagSelector
        dialogHeaderLabel={dialogHeaderLabel}
        disabled={disabled}
        {...props}
      />
    );
  } else if (type === 'simpleMasterData') {
    return (
      <CustomSimpleMasterDataSelector
        selectFromListMenuItem={true}
        disabled={disabled}
        {...props}
      />
    );
  }
  return (
    <SimpleEntitySelector
      hintTextLabel={hintText}
      label={itemLabel}
      createNewMenuItem={true}
      selectFromListMenuItem={true}
      type={type}
      masterDataType={props.masterDataType || type}
      entityType={entityType}
      isNote={isNote}
      disabled={disabled}
      {...props}
    />
  );
};

export const renderUploadHandler = injectIntl(
  ({
    itemLabel,
    helpNotes,
    risks,
    intl: { formatMessage },
    disabled,
    locale = 'en',
    ignoredRuleIds = {},
    selectedJurisdictions = [],
    ...props
  }) => (
    <div style={{ ...(!props.isVendor && rootStyle) }}>
      {renderLabelWithRisk({
        headerLabel: props.headerLabel,
        helpNotes,
        risks,
        disabled,
        locale,
        ignoredRuleIds,
        selectedJurisdictions
      })}
      {props.showTextbox && (
        <AttachmentSelector
          hintTextLabel={props.hintText && formatMessage(props.hintText.props)}
          label={itemLabel}
          createNewMenuItem={false}
          selectFromListMenuItem={true}
          handleUsageClick={props.handleUsageClick}
          {...props}
        />
      )}
    </div>
  )
);

renderUploadHandler.propTypes = {
  itemLabel: PropTypes.node,
  hintText: PropTypes.node,
  helpNotes: PropTypes.node,
  fields: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  showTextbox: PropTypes.bool
};

renderUploadHandler.defaultProps = {
  itemLabel: null,
  hintText: null,
  helpNotes: null,
  showTextbox: true
};

export const renderLinkGroup = ({ ...props }) => (
  <div style={{ width: '100%' }}>
    {props.showCheckbox && (
      <div style={{ marginTop: '13px' }}>{props.headerLabel}</div>
    )}
    <GroupListDefault {...props} />
  </div>
);

// Custom risk rendering
export const renderCustomRisk = (
  currentRisks,
  locale,
  ignoredRuleIds,
  selectedJurisdictions
) => {
  const groupedRisks =
    currentRisks &&
    groupRisksByJurisdiction(
      currentRisks,
      locale,
      ignoredRuleIds,
      selectedJurisdictions
    );
  if (
    groupedRisks &&
    Object.values(groupedRisks).some((risks) => risks.length > 0)
  ) {
    return renderGroupedRisks(groupedRisks);
  } else {
    return null;
  }
};

export const groupRisksByJurisdiction = (
  risks,
  locale,
  ignoredRuleIds,
  selectedJurisdictions = []
) =>
  risks.reduce((aggregatedRisks, riskItem) => {
    const { applicableJurisdictions, message, category } =
      riskItem.riskComplianceInfo;

    const modifiedJurisdictions = selectedJurisdictions.length
      ? applicableJurisdictions.filter((jurisdiction) =>
        selectedJurisdictions.includes(jurisdiction)
      )
      : applicableJurisdictions;

    if (category === 'COMPLIANCE') {
      return aggregatedRisks;
    }

    modifiedJurisdictions.forEach((jurisdiction) => {
      const jurisdictionKey = jurisdiction.toLowerCase();

      // If the jurisdictionKey doesn't exist in aggregatedRisks, initialize it with an empty array.
      if (!aggregatedRisks[jurisdictionKey]) {
        aggregatedRisks[jurisdictionKey] = [];
      }

      // Exclude risk messages that should be ignored based on ignoredRuleIds.
      if (!ignoredRuleIds?.[jurisdictionKey]?.includes(riskItem.ruleId)) {
        aggregatedRisks[jurisdictionKey].push(message[locale]);
      }
    });

    return aggregatedRisks;
  }, {});

const renderGroupedRisks = (groupedRisks) => (
  <div style={{ ...styles.errorText, paddingTop: '10px' }}>
    {recordTranslations.riskIdentified}:
    {Object.keys(groupedRisks).map((jurisdiction) => {
      if (groupedRisks[jurisdiction].length > 0) {
        return (
          <div key={jurisdiction}>
            <div>{uppercaseSingulars(jurisdiction)}</div>
            <ul style={{ marginTop: -5, marginLeft: -20 }}>
              {groupedRisks[jurisdiction].map((riskMessage) => (
                <li key={riskMessage}>{riskMessage}</li>
              ))}
            </ul>
          </div>
        );
      }
      return null;
    })}
  </div>
);

// end

export const renderItemSelector = injectIntl(
  withTheme(
    ({
      meta: { error, submitFailed },
      headerLabel,
      selectedOrg,
      dialogHeaderLabel,
      itemLabel,
      entityType,
      helpNotes,
      type,
      isPartOf,
      headerLabelStyle,
      stakeHolderType,
      style,
      risks,
      intl: { formatMessage },
      showHeader,
      disabled,
      showTextbox,
      isView,
      locale,
      ignoredRuleIds,
      selectedJurisdictions,
      ...props
    }) => {
      const hintText = props.hintText
        ? formatMessage(props.hintText.props)
        : props.hintText;
      const itemSelector = getItemSelector(
        type,
        isPartOf,
        itemLabel,
        entityType,
        dialogHeaderLabel,
        hintText,
        disabled,
        props,
        stakeHolderType
      );

      const hasCustomRisk = !!risks.length;

      return (
        <div style={style || rootStyle}>
          {showHeader && (
            <>
              <ListSubheader style={subheaderStyle}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div
                    style={{
                      ...(headerLabelStyle || { width: '96%' }),
                      ...(disabled ? { opacity: 0.4 } : {})
                    }}
                  >
                    {headerLabel}
                  </div>
                  <div style={{ display: 'flex', float: 'right' }}>
                    {hasCustomRisk && (
                      <ArrowTooltip
                        title={renderCustomRisk(
                          risks,
                          locale,
                          ignoredRuleIds,
                          selectedJurisdictions
                        )}
                      >
                        <span>
                          <Error color="error" />
                        </span>
                      </ArrowTooltip>
                    )}
                    {helpNotes && (
                      <ArrowTooltip
                        id={`${props.fields.name}_${type}`}
                        title={helpNotes}
                      >
                        <span>
                          <IconButton style={styles.rightIcon}>
                            <ActionHelp color="primary" />
                          </IconButton>
                        </span>
                      </ArrowTooltip>
                    )}
                  </div>
                </div>
              </ListSubheader>
              {props.fields?.length > 0 &&
                props.showTextField &&
                showTextbox &&
                !isView && <Divider style={styles.divider} />}
            </>
          )}
          {showTextbox && !isView && itemSelector}
          {isView &&
            (props.fields.getAll()?.length > 0 ? (
              <ItemList
                key={type}
                type={type}
                content={props.fields.getAll()}
                fontStyle={styles.itemListStyle}
                disabled={disabled}
              />
            ) : (
              <div style={{ padding: 10 }}>—</div>
            ))}
          {submitFailed && error && (
            <span style={styles.errorText}>{error}</span>
          )}
        </div>
      );
    }
  )
);

renderItemSelector.propTypes = {
  headerLabel: PropTypes.node,
  dialogHeaderLabel: PropTypes.node,
  itemLabel: PropTypes.node,
  entityType: PropTypes.node,
  helpNotes: PropTypes.node,
  type: PropTypes.string.isRequired,
  risks: PropTypes.arrayOf(PropTypes.shape({})),
  fields: PropTypes.shape({
    name: PropTypes.string,
    length: PropTypes.number
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitFailed: PropTypes.bool
  }).isRequired,
  isPartOf: PropTypes.string,
  stakeHolderType: PropTypes.string,
  showHeader: PropTypes.bool,
  showTextField: PropTypes.bool,
  showTextbox: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  isView: PropTypes.bool
};

renderItemSelector.defaultProps = {
  headerLabel: null,
  dialogHeaderLabel: null,
  itemLabel: null,
  entityType: null,
  helpNotes: null,
  isPartOf: '',
  risks: [],
  stakeHolderType: '',
  showHeader: true,
  showTextField: true,
  showTextbox: true,
  showCheckbox: false,
  isView: false
};

const defaultChipSelectorStyle = { width: '85%' };

export const renderChipSelector = withTheme(
  injectIntl(
    ({
      meta: { error, submitFailed },
      headerLabel,
      chipSelectorStyle,
      entityType,
      type,
      maxCount,
      style,
      intl: { formatMessage },
      showHeader,
      displayAsChip,
      disabled,
      ...props
    }) => {
      const hintText = props.hintText
        ? formatMessage(props.hintText.props)
        : props.hintText;
      const itemSelector = getItemSelector(
        type,
        null,
        headerLabel,
        entityType,
        null,
        hintText,
        disabled,
        props,
        null,
        null,
        maxCount,
        displayAsChip,
        null
      );
      return (
        <div style={style || rootStyle}>
          {showHeader && (
            <div style={{ width: '20%' }}>
              <ListSubheader
                style={{ ...subheaderStyle, paddingRight: '20px' }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '96%' }}>{headerLabel}</div>
                </div>
              </ListSubheader>
            </div>
          )}
          <div style={chipSelectorStyle || defaultChipSelectorStyle}>
            {itemSelector}
          </div>
          {submitFailed && error && (
            <span style={styles.errorText}>{error}</span>
          )}
        </div>
      );
    }
  )
);

renderChipSelector.propTypes = {
  headerLabel: PropTypes.node,
  itemLabel: PropTypes.node,
  entityType: PropTypes.node,
  type: PropTypes.string.isRequired,
  fields: PropTypes.shape({
    name: PropTypes.string,
    length: PropTypes.number
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitFailed: PropTypes.bool
  }).isRequired,
  showHeader: PropTypes.bool
};

renderChipSelector.defaultProps = {
  headerLabel: null,
  itemLabel: null,
  entityType: null,
  showHeader: true
};

export const renderHierarchyTree = injectIntl(
  ({
    helpNotes,
    input,
    headerLabel,
    handleDpoClick,
    showAssignRole,
    handleRoleAssign,
    risks,
    intl: { formatMessage },
    style,
    disabled,
    locale,
    ignoredRuleIds,
    selectedJurisdictions,
    ...props
  }) => {
    const hintText = formatMessage(props.hintText.props);
    const hasCustomRisk = !!risks.length;

    return (
      <div style={style || rootStyle}>
        <ListSubheader style={subheaderStyle}>
          <span style={{ ...(disabled ? { opacity: 0.4 } : {}) }}>
            {headerLabel}
          </span>
          {showAssignRole && !disabled && (
            <Button
              variant="text"
              style={{ ...styles.rightIcon, width: '30%' }}
              onClick={handleRoleAssign}
            >
              {recordTranslations.assignRoles}
            </Button>
          )}
          <div style={{ display: 'flex', float: 'right' }}>
            {hasCustomRisk && (
              <ArrowTooltip
                title={renderCustomRisk(
                  risks,
                  locale,
                  ignoredRuleIds,
                  selectedJurisdictions
                )}
              >
                <span>
                  <Error color="error" />
                </span>
              </ArrowTooltip>
            )}
            {helpNotes && (
              <ArrowTooltip id={input.name} title={helpNotes}>
                <span>
                  <IconButton style={styles.rightIcon}>
                    <ActionHelp color="primary" />
                  </IconButton>
                </span>
              </ArrowTooltip>
            )}
          </div>
        </ListSubheader>
        <Tree
          {...props}
          disabled={disabled}
          hintText={hintText}
          showAssignRole={showAssignRole}
          handleDpoClick={handleDpoClick}
        />
      </div>
    );
  }
);
renderHierarchyTree.propTypes = {
  headerLabel: PropTypes.node,
  helpNotes: PropTypes.node,
  input: PropTypes.shape({}).isRequired,
  risks: PropTypes.shape({})
};

renderHierarchyTree.defaultProps = {
  headerLabel: null,
  helpNotes: null,
  risks: {}
};

export const renderLegalGroundSelector = withTheme(
  ({
    meta: { error, submitFailed },
    helpNotes,
    headerLabel,
    type,
    fieldType,
    risks,
    disabled,
    locale,
    ignoredRuleIds,
    selectedJurisdictions,
    ...props
  }) => {
    let legalGroundSelector;
    if (type === 'transferGrounds') {
      legalGroundSelector = (
        <TransferGroundSelector
          dataItemType={fieldType || type}
          disabled={disabled}
          {...props}
        />
      );
    } else if (type === 'processingGrounds') {
      legalGroundSelector = (
        <ProcessingGroundSelector
          dataItemType={fieldType || type}
          disabled={disabled}
          {...props}
        />
      );
    } else {
      legalGroundSelector = (
        <BreachGroundSelector
          dataItemType={type}
          disabled={disabled}
          {...props}
        />
      );
    }

    return (
      <div style={{ ...rootStyle, ...props.style }}>
        <ListSubheader style={subheaderStyle}>
          {renderLabelWithRisk({
            headerLabel,
            helpNotes,
            risks,
            disabled,
            locale,
            ignoredRuleIds,
            selectedJurisdictions
          })}
        </ListSubheader>
        {props.fields.length > 0 && props.showTextbox && (
          <Divider style={{ ...styles.divider, ...props.dividerStyle }} />
        )}
        {props.showTextbox && legalGroundSelector}
        {submitFailed && error && <span style={styles.errorText}>{error}</span>}
      </div>
    );
  }
);
renderLegalGroundSelector.propTypes = {
  headerLabel: PropTypes.node,
  helpNotes: PropTypes.node,
  type: PropTypes.string.isRequired,
  fieldType: PropTypes.string,
  risks: PropTypes.arrayOf(PropTypes.shape({})),
  fields: PropTypes.shape({
    name: PropTypes.string,
    length: PropTypes.number
  }).isRequired,
  meta: PropTypes.shape({
    error: PropTypes.string,
    submitFailed: PropTypes.bool
  }).isRequired,
  showTextbox: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  dividerStyle: PropTypes.shape({})
};

renderLegalGroundSelector.defaultProps = {
  headerLabel: null,
  helpNotes: null,
  risks: [],
  showTextbox: true,
  isFullWidth: false,
  dividerStyle: {},
  fieldType: undefined
};

export const getCommentary = (headerLabel, commentary, style, helpNote) => (
  <div style={style || { paddingTop: '50px' }}>
    <div style={{ display: 'flex' }}>
      <div
        style={{
          color: '#807e7e',
          fontSize: '15px',
          paddingRight: '6px',
          width: helpNote ? '87%' : '100%'
        }}
      >
        {headerLabel}
      </div>
      {helpNote && renderHelpNote(helpNote)}
    </div>
    <div
      style={{
        paddingTop: '10px',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: helpNote ? '85%' : '100%'
      }}
    >
      {commentary && commentary.length > 0 ? (
        ReactHtmlParser(commentary)
      ) : (
        <div>
          <FormattedMessage
            id="Overview.noData"
            description="No data to display"
            defaultMessage="No data to display"
          />
        </div>
      )}
    </div>
  </div>
);

const getOddEvenClassName = (index) => (index % 2 === 0 ? 'even-row' : '');

const getThreatTranslation = {
  confidentiality: threatTranslations.confidentialityHeader,
  integrity: threatTranslations.integrityHeader,
  availability: threatTranslations.availabilityHeader
};

export const getRiskIndicator = (item, props, categories) => {
  const threatAndImpactRisks = ['confidentiality', 'integrity', 'availability'];
  const { threatAndImpactRiskAssessment = [] } = props.data.toJS();
  const riskStyle = {
    lineHeight: 2.5,
    borderBottom: '5px solid',
    borderRadius: '10px',
    fontSize: '14px',
    width: '100%'
  };
  if (
    item !== 'preAssessment' &&
    item !== 'accountability' &&
    item !== 'impactAssessment'
  ) {
    return (
      <RiskCounter
        categories={categories[item]}
        data={
          item === 'dataSubjectRights'
            ? props.data.toJS().dataSubjectRights
            : props.data.toJS()
        }
      />
    );
  }
  if (item === 'impactAssessment') {
    return (
      <div style={{ marginTop: '15px' }}>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: 'lightGrey', height: '10px' }}>
              <TableCell style={{ width: '50%' }}>
                {threatTranslations.residualRisk}
              </TableCell>
              <TableRow />
            </TableRow>
          </TableHead>
          <TableBody>
            {threatAndImpactRisks.map((el, index) => (
              <TableRow className={getOddEvenClassName(index)} key={`${item}`}>
                <TableCell style={{ width: '50%' }}>
                  <span style={{ paddingTop: '10px' }}>
                    {getThreatTranslation[el]}:{' '}
                    {threatAndImpactRiskAssessment[el]
                      ? getRiskTranslation(
                        threatAndImpactRiskAssessment[el].residualRisk
                      )
                      : recordTranslations.noRiskLevel}
                  </span>
                </TableCell>
                <TableCell align="left">
                  <div>
                    <div
                      style={{
                        ...riskStyle,
                        borderBottomColor: threatAndImpactRiskAssessment[el]
                          ? getRiskButtonBgColor(
                            threatAndImpactRiskAssessment[el].residualRisk
                          )
                          : '#00ad55',
                        width: '100%'
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
  return null;
};

getRiskIndicator.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    get: PropTypes.func,
    toJS: PropTypes.func
  }).isRequired
};

const getHelpNotes = (helpNotesItem, style = {}) => (
  <div style={style}>
    <ArrowTooltip title={helpNotesItem}>
      <IconButton style={{ marginTop: -3 }}>
        <ActionHelp color="primary" />
      </IconButton>
    </ArrowTooltip>
  </div>
);

export const renderLabel = (
  header,
  helpNotesItem,
  isView,
  helpNoteStyle,
  headerStyle = {},
  disabled = false
) => (
  <ListSubheader
    style={
      isView
        ? {
          ...listSubheaderStyle,
          color: '#807e7e',
          fontSize: '15px'
        }
        : { ...listSubheaderStyle }
    }
    disableSticky={true}
  >
    <span
      style={{
        width: '41%',
        ...headerStyle,
        ...(disabled ? { opacity: 0.4 } : {})
      }}
    >
      {header}
    </span>
    {getHelpNotes(helpNotesItem, helpNoteStyle)}
  </ListSubheader>
);

export const renderDateSelector = ({ disabled, ...props }) => {
  const handleButtonClick = (item) => {
    const date = new Date();
    const newDate = new Date(date.setMonth(date.getMonth() + item));
    props.updateReviewDate(newDate);
  };
  return (
    <div style={{ display: 'flex' }}>
      <div
        style={
          disabled
            ? { ...styles.disabled, marginTop: '14px' }
            : { marginTop: '14px' }
        }
      >
        {recordTranslations.quickSet}:
      </div>
      <div>
        <Button
          id="six-months"
          key="six-months"
          variant="text"
          onClick={() => handleButtonClick(6)}
          disabled={disabled}
        >
          {recordTranslations.sixMonths}
        </Button>
      </div>
      <div>
        <Button
          id="one-year"
          key="one-year"
          variant="text"
          onClick={() => handleButtonClick(12)}
          disabled={disabled}
        >
          {recordTranslations.oneYear}
        </Button>
      </div>
      <div>
        <Button
          id="three-years"
          key="three-years"
          variant="text"
          onClick={() => handleButtonClick(36)}
          disabled={disabled}
        >
          {recordTranslations.threeYears}
        </Button>
      </div>
    </div>
  );
};

renderDateSelector.propTypes = {
  disabled: PropTypes.bool
};

renderDateSelector.defaultProps = {
  disabled: false
};

export const renderLabelWithRisk = ({
  headerLabel,
  helpNotes,
  risks,
  disabled,
  locale,
  ignoredRuleIds,
  selectedJurisdictions,
  mandatory
}) => {
  const hasCustomRisk = !!risks?.length;
  return (
    <ListSubheader style={subheaderStyle}>
      <span style={{ ...(disabled ? { opacity: 0.4 } : {}) }}>
        {headerLabel}
       { mandatory && <span style={{color: 'red', marginLeft: '4px', fontSize: '18px'}}>*</span>}
      </span>
      <div style={{ display: 'flex', float: 'right' }}>
        {hasCustomRisk && (
          <ArrowTooltip
            title={renderCustomRisk(
              risks,
              locale,
              ignoredRuleIds,
              selectedJurisdictions
            )}
          >
            <span>
              <Error color="error" />
            </span>
          </ArrowTooltip>
        )}
        <div style={{ display: 'inline', float: 'right' }}>
          {helpNotes && (
            <ArrowTooltip title={helpNotes}>
              <IconButton style={styles.rightIcon}>
                <ActionHelp color="primary" />
              </IconButton>
            </ArrowTooltip>
          )}
        </div>
      </div>
    </ListSubheader>
  );
};

export const renderCheckBoxFields = ({
  label,
  showDisclaimer,
  selectedJurisdictions,
  input,
  onCheck,
  meta: { error, submitFailed },
  disabled,
  risks,
  locale,
  ignoredRuleIds,
  isMulti = true
}) => {
  const allowedJurisdictions = getAllowedJurisdiction();
  const values = input.value || selectedJurisdictions || [];

  const isItemDisabled = (item) =>
    (allowedJurisdictions.length === 1 &&
      values.includes(allowedJurisdictions[0].value)) ||
    disabled ||
    (!isMulti && values[0] !== item.value && values.length === 1);
  const title = renderCustomRisk(
    risks,
    locale,
    ignoredRuleIds,
    selectedJurisdictions
  );
  const hasCustomRisk = !!risks?.length && title;

  return (
    <div style={{ width: '100%' }}>
      <ListSubheader
        style={{
          ...styles.labelField,
          position: 'static',
          ...(disabled ? { opacity: 0.4 } : {})
        }}
      >
        {label}
        <div
          style={{
            display: 'inline-flex',
            float: 'right',
            color: 'red',
            alignItems: 'center'
          }}
        >
          {hasCustomRisk && (
            <ArrowTooltip title={title}>
              <span>
                <Error color="error" />
              </span>
            </ArrowTooltip>
          )}
          {showDisclaimer && (
            <ArrowTooltip title={recordTranslations.jurisdictionDisclaimer}>
              <FontAwesome
                style={{ marginLeft: '4px' }}
                name="warning"
                size="md"
              />
            </ArrowTooltip>
          )}
        </div>
      </ListSubheader>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto auto'
        }}
      >
        {allowedJurisdictions.map((item) => (
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  key={item.value}
                  id={item.value}
                  style={{ height: 1, marginBottom: 2 }}
                  disabled={isItemDisabled(item)}
                  checked={values.includes(item.value)}
                  onChange={(event, isInputChecked) =>
                    onCheck(isInputChecked, item.value)
                  }
                  color="primary"
                />
              }
              style={{
                ...styles.multiselectCheckbox,
                display: 'flex',
                alignItems: 'flex-start'
              }}
              label={item.label}
            />
          </FormGroup>
        ))}
      </div>
      <div style={styles.errorText}>
        {submitFailed && error && <span>{error}</span>}
      </div>
    </div>
  );
};

const renderItems = (items, color) => (
  <>
    {items.map((item) => (
      <div
        key={item}
        style={{
          marginTop: 10,
          color,
          wordBreak: 'break-all'
        }}
      >
        {item}
      </div>
    ))}
  </>
);

const renderEmptyPlaceholder = () => (
  <div style={{ paddingLeft: 30, paddingTop: 10 }}>—</div>
);

export const renderInvolvedOrganisations = injectIntl(
  ({
    processors,
    dataRecipients,
    controllers = [],
    label,
    helpNotes,
    countryList,
    dataSources = [],
    risks,
    locale,
    ignoredRuleIds,
    selectedJurisdictions
  }) => {
    const processorList = [];
    const getProcessorList = (itemList) => {
      itemList.forEach((item) => {
        if (item.value && item.value.subProcessors.length > 0) {
          processorList.push(item);
          getProcessorList(item.value.subProcessors);
        } else if (item.children) {
          processorList.push(item);
          getProcessorList(item.children);
        } else processorList.push(item);
      });
      return processorList;
    };

    const processor = getProcessorList(processors.children || processors);
    let internalOrg = [];
    let externalOrg = [];
    const getOrgArray = (item) => {
      const newitem = item.value || item;
      const country = newitem.country.id || newitem.country;
      const name = newitem.key || `${newitem.name} (${country})`;
      if (!countryList.includes(country)) {
        if (newitem.isInternal) internalOrg.push(name);
        else externalOrg.push(name);
      }
    };
    processor.forEach((item) => {
      getOrgArray(item);
    });
    dataRecipients.forEach((item) => {
      getOrgArray(item);
    });
    controllers.forEach((item) => {
      getOrgArray(item);
    });
    // remove duplicate organisations from array
    internalOrg = Array.from(new Set(internalOrg));
    externalOrg = Array.from(new Set(externalOrg));

    const outsideCountrListDataSources = dataSources.filter(
      (item) =>
        item.value.dataStorageCountry &&
        !countryList.includes(item.value.dataStorageCountry)
    );

    return (
      <div>
        {renderLabelWithRisk({
          headerLabel: label,
          helpNotes,
          risks,
          disabled: false,
          locale,
          ignoredRuleIds,
          selectedJurisdictions
        })}
        <div style={{ display: 'flex', marginTop: 20 }}>
          <div style={{ width: '50%' }}>
            {orgTypesTranslations.internalOrg}
            {internalOrg.length > 0
              ? renderItems(internalOrg, 'green')
              : renderEmptyPlaceholder()}
          </div>
          <div style={{ width: '50%' }}>
            {orgTypesTranslations.externalOrg}
            {externalOrg.length > 0
              ? renderItems(externalOrg, 'red')
              : renderEmptyPlaceholder()}
          </div>
        </div>
        <div style={{ marginTop: 20 }}>{recordTranslations.dataSources}</div>
        {outsideCountrListDataSources.length > 0
          ? renderItems(
            outsideCountrListDataSources.map((item) => item.value.key),
            'red'
          )
          : renderEmptyPlaceholder()}
      </div>
    );
  }
);
