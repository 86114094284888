import { connect } from 'react-redux';
import SimpleEntitySelector from './component';

const mapStateToProps = (state) => ({
  simpleEntities: state.environment.simpleEntities,
  isGlobal: state.home?.get('isGlobal'),
  searchResults: state.simpleEntities.get('entitySelectorSearch'),
  userPermissions: state.login?.get('loggedUser')?.get('userPermissions')
});

const mapDispatchToProps = (dispatch) => ({
  initSimpleEntities: (masterDataType, entityType, fieldElement, isPublic = false ) => {
    dispatch({
      type: 'SIMPLE_ENTITY:LIST:REQUEST_INIT',
      masterDataType,
      entityType,
      isPublic,
      fieldElement,
      source: 'records'
    });
  },
  getNextData: (masterDataType, entityType, position, fieldElement, isPublic = false ) => {
    dispatch({
      type: 'SIMPLE_ENTITY:LIST:NEXT_FETCH',
      masterDataType,
      entityType,
      isPublic,
      fieldElement,
      position,
      source: 'records'
    });
  },
  onSearch: (searchParams, masterDataType, entityType, fieldElement, isPublic = false ) => {
    dispatch({
      type: 'SIMPLE_ENTITY:LIST:SEARCH_INIT',
      searchParams,
      masterDataType,
      isPublic,
      fieldElement,
      entityType
    });
  },
  onChooseFilter: (filterParams, masterDataType, entityType, fieldElement, isPublic = false ) => {
    dispatch({
      type: 'SIMPLE_ENTITY:ITEM:FILTER_INIT',
      filterParams,
      masterDataType,
      entityType,
      isPublic,
      fieldElement,
      searchParams: { searchKey: 'name', sortOrder: 'ASC', sortOn: 'name' },
      source: 'records',
      isPersistedData: true
    });
  },
  tagsSearch: (searchText) => {
    dispatch({
      type: 'SIMPLE_ENTITY:LIST:SEARCH_INIT',
      searchParams: { searchKey: 'name', searchText },
      masterDataType: 'tags'
    });
  },
  getNextTags: (position) => {
    dispatch({
      type: 'SIMPLE_ENTITY:LIST:NEXT_FETCH',
      masterDataType: 'tags',
      position,
      source: 'records'
    });
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleEntitySelector);
