import Immutable from 'immutable';
import { put, select } from 'redux-saga/effects';
import registry from 'app-registry';
import jwtDecode from 'jwt-decode';
import FontAwesome from 'react-fontawesome';

import countryTranslations from '@packages/components/country-selector/country.jsx';
import {
  isType,
  transformRetentionTerm
} from '@packages/features/privacy-record-detail/common-utils';
import languages from '@packages/translation/languages';
import { getTranslatedLabel } from '@packages/features/tenant-configuration/tenantConfigUtils';

import {
  serviceDownError,
  recordTranslations,
  commonTranslations,
  customTranslation
} from './commontranslations';
import { getParameterValuesFromHash } from './query-parameters';
import vendorTranslations from './vendorTranslations.jsx';
import {
  assessmentPdfExportTypes,
  breachPdfExportTypes,
  processingPdfExportTypes,
  tiaPdfExportTypes
} from './record-utils';
import errortranslations from './errortranslations';

export const RECORDS_FETCH_LIMIT = 50;
export const RECORDS_SEARCH_LIMIT = 50;

export const MASTERDATA_FETCH_LIMIT = 500;
export const MASTERDATA_SEARCH_LIMIT = 500;

export const MESSAGES_FETCH_LIMIT = 500;
export const MESSAGES_SEARCH_LIMIT = 500;

export const GENERAL_FETCH_LIMIT = 500;

export const GLOBAL_NAVBAR_HEIGHT = 60;

export const GLOBAL_CUSTOM_PREFIX = 'holding';

// user hierarchy
export const NO_LIMIT = 'noLimit';
export const TOP_DOWN = 'topDown';
export const FLAT = 'flat';

export const getRecordType = (recordParam) => {
  switch (recordParam) {
    case 'assessment':
    case 'assessments':
      return 'assessments';
    case 'breach':
    case 'breaches':
      return 'breaches';
    case 'processing':
      return 'processings';
    case 'tia':
      return 'tias';
    case 'document':
    case 'document-records':
      return 'document-records';
    default:
      return 'processings';
  }
};

export const getTransformedRecordType = (recordType) => {
  // to retrieve the proper filter key
  switch (recordType) {
    case 'processing':
      return 'processings';
    case 'assessment':
      return 'assessments';
    case 'tia':
      return 'tia_assessments';
    case 'breach':
      return 'breaches';
    case 'custom':
      return 'all'; // filtering custom records from all filter
    default:
      break;
  }
  return null;
};

export const validateEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return re.test(email);
};
export const validatePhoneNumber = (phoneNumber) => {
  const re = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
  return re.test(phoneNumber);
};

export const sortItems = (items, sortOrder, sortOn) => {
  if (items) {
    return items.sort((obj1, obj2) => {
      // Do not sort the items if obj is array.
      if (Array.isArray(obj1) || Array.isArray(obj2)) return 0;
      const item1 = (
        sortOn ? obj1[sortOn] || obj1.value[sortOn] : obj1
      ).toLowerCase();
      const item2 = (
        sortOn ? obj2[sortOn] || obj2.value[sortOn] : obj2
      ).toLowerCase();
      if (sortOrder === 'ASC') {
        return item1.localeCompare(item2);
      }
      return item2.localeCompare(item1);
    });
  }
  return items;
};

// Get Error message from error Obj.
export const getErrorMessage = (err) =>
  err.message.msg ? err.message.msg : err.message;

export const deepCopy = (objToCopy) => {
  const output = Array.isArray(objToCopy) ? [] : {};
  Object.keys(objToCopy).forEach((key) => {
    const value = objToCopy[key];
    if (typeof value === 'object' && value !== null) {
      output[key] = deepCopy(value);
    } else {
      output[key] = value;
    }
  });
  return output;
};

// To map the country code to country name in search list.
export const getCountrySearchItems = (countries, formatMessage) => {
  const translatedItems = [];
  if (countries) {
    countries.forEach((item) => {
      const countryLabels = countryTranslations.find(
        (country) => country.value === (item.id || item)
      );
      translatedItems.push({
        label: formatMessage(countryLabels.text.country),
        value: item.id || item
      });
    });
  }
  return translatedItems;
};

export const getTranslatedCountryList = (formatMessage) => {
  const countries = [];
  countryTranslations.forEach((country) => {
    countries.push({
      ...country,
      label: formatMessage(country.text.country),
      value: country.value
    });
  });
  return countries;
};

// To get table height iframe.
export const getEmbedContainerHeight = () => {
  // (header = 80px + margin = 10)
  const containerHeight = window.innerHeight - 90;
  return containerHeight - (5 / 100) * containerHeight;
};

// To get table height based on screen height.
export const getContainerHeight = (headerHeight, isGlobal = false) => {
  // (header = 100px) + (containerHeader = 56px) + (marginBottom of list = 5px) +
  // (margin bottom of header = 5px) + (height of banner = 40px) = 206.
  const containerHeight = headerHeight
    ? window.innerHeight - headerHeight
    : window.innerHeight - (isGlobal ? 105 : 205);
  return containerHeight - (5 / 100) * containerHeight;
};

// To get the row count based on the calculated table height.
export const getRowCount = () => {
  const rowCount = Math.floor(getContainerHeight() / 40);
  return rowCount > 15 ? rowCount : 15;
};

// To get the dialog content height based on the window height.
export const getDialogContentHeight = (minWindowHeight, containerHeight) =>
  // Action buttons in the dialog is not visible when the browser window height is less than minWindowHeight.
  // containerHeight is the dialog content height for minWindowHeight.
  window.innerHeight > minWindowHeight
    ? containerHeight
    : containerHeight - (minWindowHeight - window.innerHeight);

// Filter out empty and undefined search items
export const filterEmptySearchItems = (searchItems, searchKey) => {
  const searchItemObj = {};
  const items = searchItems.filter((item) => item !== '' && item !== undefined);
  searchItemObj[searchKey] = items;
  return Immutable.Map(searchItemObj);
};

export const getModifiedRecordType = (recordType) => {
  // to retrieve the proper filter key
  switch (recordType) {
    case 'processings':
    case 'processing_processings':
      return 'processing';
    case 'assessments':
    case 'dpia_assessments':
      return 'assessment';
    case 'breach_breaches':
    case 'breaches':
      return 'breach';
    case 'all':
      return 'all';
    case 'tias':
    case 'tia_assessments':
      return 'tia';
    case 'document_records':
      return 'document_records';
    default:
      return recordType;
  }
};

export const linkGroupItems = [
  'dataSubjectCategories',
  'personalDataCategories',
  'personalDataItems',
  'dataSourceCategories',
  'dataSources',
  'retentionTerms',
  'purposes',
  'purposesOfTransfer',
  'technicalSecurityMeasures',
  'organisationalSecurityMeasures'
];

export function* handleServiceDown(err, serviceName) {
  const error = err;
  switch (error.status) {
    case 502:
    case 503:
    case 504:
      error.message = serviceDownError(serviceName);
      break;
    default:
      error.message = error.message;
      break;
  }
  yield put({
    type: 'NOTIFIER:NOTIFY',
    notification: {
      content: err.message,
      type: 'error'
    }
  });
}

export const checkIsCreatedUser = (createdBy, userId) => {
  const isCreatedUser = createdBy && userId === createdBy.id;
  return isCreatedUser;
};

export const getOddEvenClassName = (index) =>
  index % 2 === 0 ? 'even-row' : '';

/**
 * To set the last activity time in order to make sure that the user
 * is active on the form based on which the refresh token api is invoked.
 */
export const setLastActivityTime = () => {
  registry.get('storage').setItem('lastRequestTime', new Date().getTime());
};

export const getEntityType = (entityType) => {
  switch (entityType) {
    case 'purposes':
    case 'purposesOfTransfer':
      return 'Purpose';

    case 'personalDataItems':
      return 'PersonalDataItem';

    case 'processingGrounds':
      return 'ProcessingGround';

    case 'transferGrounds':
      return 'TransferGround';

    case 'qualityControls':
      return 'QualityControl';

    case 'retentionTerms':
      return 'RetentionTerm';

    case 'informationRights':
      return 'RightToInformation';

    case 'consentRights':
      return 'ValidityOfConsent';

    case 'accessRights':
      return 'RightToAccess';

    case 'objectionRights':
      return 'RightToObjection';

    case 'deletionRights':
      return 'RightToDeletion';

    case 'dataPortabilityRights':
      return 'RightToDataPortability';

    case 'confidentialityThreats':
    case 'confidentialityImpacts':
      return 'Confidentiality';

    case 'integrityThreats':
    case 'integrityImpacts':
      return 'Integrity';

    case 'availabilityThreats':
    case 'availabilityImpacts':
      return 'Availability';

    case 'technicalSecurityMeasures':
      return 'Technical';

    case 'organisationalSecurityMeasures':
      return 'Organisational';

    case 'specialSecurityMeasures':
      return 'Special';

    case 'contractualSecurityMeasures':
      return 'Contractual';

    case 'dataProtectionMeasures':
      return 'DataProtection';

    case 'accountabilityMeasures':
      return 'Accountability';

    case 'dataSubjectCategoriesFinding':
      return 'DataSubjectCategoryFinding';

    case 'dataSubjectCategoriesDecision':
      return 'DataSubjectCategoryDecision';

    case 'supervisoryAuthoritiesFinding':
      return 'SupervisoryAuthorityFinding';

    case 'supervisoryAuthoritiesDecision':
      return 'SupervisoryAuthorityDecision';

    case 'dpoFinding':
      return 'DPOFinding';

    case 'dpoDecision':
      return 'DPODecision';

    case 'dataSubjectCategories':
      return 'DataSubjectCategory';

    case 'processingCategories':
      return 'ProcessingCategory';

    case 'personalDataCategories':
      return 'PersonalDataCategory';

    case 'dataSourceCategories':
      return 'DataSourceCategory';

    case 'dataSources':
      return 'DataSource';

    case 'securityMeasuresDescription':
    case 'securityMeasures':
    case 'accountabilitysecuritymeasures':
      return 'SecurityMeasure';

    case 'retentionTermOffset':
    case 'retentionTermOffsets':
      return 'RetentionTermOffset';

    case 'dataSubjectRights':
      return 'DataSubjectRight';

    case 'threats':
      return 'Threat';

    case 'impacts':
      return 'Impact';

    case 'policies':
      return 'Policy';

    case 'riskDetails':
      return 'RiskDetail';

    case 'accountabilities':
      return 'Accountability';

    case 'mitigatingMeasures':
      return 'MitigatingMeasure';

    case 'references':
      return 'Reference';

    case 'dataRecipientCategories':
      return 'DataRecipientCategory';

    case 'organisations':
      return 'Organisation';

    case 'vendorForm':
      return 'VendorForm';

    case 'vendorFormTemplate':
      return 'VendorFormTemplate';

    case 'fairnessOfDecisions':
      return 'FairnessOfDecision';

    case 'documentRecordTypes':
      return 'DocumentRecordType';

    case 'specialCharacteristics':
      return 'SpecialCharacteristics';
    case 'customSimpleMasterData':
      return 'CustomSimpleMasterData';

    default:
      return entityType;
  }
};

export const getMasterDataType = (dataItemType) => {
  switch (dataItemType) {
    case 'purposesOfTransfer':
    case 'purposes':
      return 'purposes';

    case 'consentRights':
    case 'informationRights':
    case 'accessRights':
    case 'objectionRights':
    case 'deletionRights':
    case 'dataPortabilityRights':
      return 'datasubjectrights';

    case 'confidentialityThreats':
    case 'integrityThreats':
    case 'availabilityThreats':
      return 'threats';

    case 'confidentialityImpacts':
    case 'integrityImpacts':
    case 'availabilityImpacts':
      return 'impacts';

    case 'securityMeasures':
    case 'technicalSecurityMeasures':
    case 'contractualSecurityMeasures':
    case 'organisationalSecurityMeasures':
    case 'specialSecurityMeasures':
    case 'dataProtectionMeasures':
    case 'accountabilityMeasures':
      return 'securitymeasures';

    case 'findings':
    case 'decisions':
      return 'accountabilities';

    default:
      return dataItemType.toLowerCase();
  }
};

export const extractRiskObjects = (props) => {
  const { data, categories } = props;
  const riskResults = {};
  let riskItem = {};
  // eslint-disable-next-line no-unused-expressions
  categories &&
    categories.forEach((item) => {
      riskItem =
        data[item] &&
        data[item].filter(
          (el) =>
            el.severity === 'risk_estimate_type_high' ||
            el.severity === 'risk_estimate_type_medium'
        );
      if (riskItem && riskItem.length > 0) {
        riskResults[item] = riskItem;
      }
    });
  return riskResults;
};

export const getReducerType = (masterDataType, entityType) => {
  if (
    entityType &&
    masterDataType !== 'riskDetails' &&
    masterDataType !== 'accountabilities' &&
    masterDataType !== 'customSimpleMasterData'
  ) {
    return entityType;
  } else if (masterDataType === 'accountabilities') {
    if (
      entityType === 'dataSubjectCategoriesFinding' ||
      entityType === 'dpoFinding' ||
      entityType === 'supervisoryAuthoritiesFinding' ||
      entityType === 'dpoOrganisationsFinding'
    )
      return 'findings';
    return 'decisions';
  }
  return masterDataType;
};

export const getGlobalMasterdataType = (masterDataType) => {
  switch (masterDataType) {
    case 'References':
      return 'references';
    case 'securityMeasures':
      return 'securitymeasures';
    case 'mitigatingMeasures':
      return 'mitigatingmeasures';
    case 'riskDetails':
      return 'riskdetails';
    case 'qualityControls':
      return 'qualitycontrols';
    case 'dataSubjectRights':
      return 'datasubjectrights';
    case 'customsimplemasterdata':
      return 'simplecustom';
    default:
      return masterDataType;
  }
};

export const getIsEditable = (masterDataType, userPermissions) => {
  if (!userPermissions) return false;

  const {
    createEditDocumentRecord,
    createEditReferences,
    createEditSecurityMeasures,
    createEditPurposes,
    createEditDataSourceCategories,
    createEditAssessmentMasterData
  } = userPermissions.toJS();

  return (
    {
      documentRecordTypes: createEditDocumentRecord,
      specialCharacteristics: createEditDocumentRecord,
      references: createEditReferences,
      securityMeasures: createEditSecurityMeasures,
      purposes: createEditPurposes,
      dataSourceCategories: createEditDataSourceCategories
    }[masterDataType] ?? createEditAssessmentMasterData
  );
};

export const getSSObypassedValue = (enabledSSO, ssoSettings, userDetails) => {
  // If SSO is disabled for tenant show changePassword form.
  if (!enabledSSO) {
    return true;
  }
  // If the SSO enabled for tenant then, check sso settings status
  if (ssoSettings && ssoSettings.value && ssoSettings.value.status === 'Off') {
    return true;
    // If status is Live or Test, then check bypassSSO field
    // Show changePassword form only when byPassSSo field true
    // and SSOUserPasswordStatus is SuccessfullySetPassword
  } else if (!userDetails.bypassSSO) {
    return false;
  } else if (userDetails.SSOUserPasswordStatus === 'InvitedToSetPassword') {
    return false;
  }
  return true;
};

export const getTenantId = () => {
  const config = registry.get('config');
  const token = registry.get('storage').getItem(config.login.user.storage.key)
    ? JSON.parse(registry.get('storage').getItem(config.login.user.storage.key))
      .token
    : '';
  const tenantId = token && jwtDecode(token).tenant_uuid;
  return tenantId;
};

export const isValidSearchLength = (text) => {
  // Passes when the word length is not more than 4
  const match = text.match(/^(\w*\s){0,3}(\w*)/g);
  return match[0].length === text.length;
};

export const isUserCreationPossible = (pricingPlan) =>
  (pricingPlan.get('plan') && pricingPlan.get('plan').maxUsers) >
  (pricingPlan.get('usage') && pricingPlan.get('usage').users);

export const supportedJurisdictions = [
  {
    key: recordTranslations.gdpr,
    label: recordTranslations.gdpr,
    data: 'GDPR',
    riskFields: [
      'controllers',
      'purposes',
      'processingGrounds',
      'transferGrounds',
      'personalDataItems',
      'dataSources'
    ]
  },
  {
    key: recordTranslations.idpr,
    label: recordTranslations.idpr,
    data: 'IDPR',
    riskFields: [
      'controllers',
      'purposes',
      'processingGrounds',
      'transferGrounds',
      'personalDataItems',
      'dataSources'
    ]
  },
  {
    key: recordTranslations.lgpd,
    label: recordTranslations.lgpd,
    data: 'LGPD',
    riskFields: [
      'personalDataCategories',
      'dpo',
      'controllers',
      'processors',
      'purposes',
      'lgpdProcessingGrounds',
      'lgpdInternationalTransferGrounds',
      'lgpdSpecialProcessingGrounds'
    ]
  },
  {
    key: recordTranslations.ccpa,
    label: recordTranslations.ccpa,
    data: 'CCPA',
    riskFields: [
      'processors',
      'purposes',
      'personalDataCategories',
      'optOutWebsiteLink',
      'personalDataItems',
      'optOutUserRightsLink',
      'dataSourceCategories',
      'underSixteenOptInMeasures',
      'dsrMethods.link',
      'dsrMethods.phoneNumber'
    ]
  },
  {
    key: recordTranslations.pipl,
    label: recordTranslations.pipl,
    data: 'PIPL',
    riskFields: [
      'purposes',
      'dataSubjectNotificationMethod',
      'piplProcessingGrounds',
      'automatedDecisionMaking',
      'dataSubjectAuthorityMethod',
      'piplDisclosurePurposes',
      'piplDisclosedPersonalDataItems',
      'specialDataType',
      'piplInternationalTransferGrounds',
      'piplSpecialHandlingRules',
      'isSensitiveDataUsed'
    ]
  },
  {
    key: recordTranslations.pdp,
    label: recordTranslations.pdp,
    data: 'PDP',
    riskFields: [
      'purposes',
      'pdpProcessingGrounds',
      'privacyAgreementAttachments',
      'securityInfoAttachments',
      'processors',
      'controllers',
      'personalDataCategories',
      'compliances.pdp',
      'linkedDocumentRecords'
    ]
  },
  {
    key: recordTranslations.appi,
    label: recordTranslations.appi,
    data: 'APPI',
    riskFields: [
      'controllers',
      'personalDataItems',
      'personalDataCategories',
      'appiInternationalTransferCountries',
      'compliances.appi',
      'appiInternationalTransferGrounds',
      'appiDomesticTransferGrounds',
      'appiThirdPartyDataTransferGrounds'
    ]
  },
  {
    key: recordTranslations.fdpl,
    label: recordTranslations.fdpl,
    data: 'FDPL',
    riskFields: [
      'purposes',
      'controllers',
      'fdplProcessingGrounds',
      'personalDataItems',
      'fdplInternationalTransferPurposes',
      'compliances.fdpl'
    ]
  },
  {
    key: recordTranslations.sgpdpa,
    label: recordTranslations.sgpdpa,
    data: 'SGPDPA',
    riskFields: [
      'purposes',
      'technicalSecurityMeasures',
      'retentionTerms',
      'sgpdpaProcessingGrounds',
      'sgpdpaTransferGrounds'
    ]
  },
  {
    key: recordTranslations.pdpa,
    label: recordTranslations.pdpa,
    data: 'PDPA',
    riskFields: [
      'controllers',
      'purposes',
      'processors',
      'compliances.pdpa',
      'personalDataCategories',
      'pdpaSpecialProcessingGrounds'
    ]
  },
  {
    key: recordTranslations.ukgdpr,
    label: recordTranslations.ukgdpr,
    data: 'UKGDPR',
    riskFields: [
      'controllers',
      'purposes',
      'processingGrounds',
      'transferGrounds',
      'personalDataItems',
      'dataSources'
    ]
  }
];

export const getAllowedJurisdiction = () => {
  const store = registry.get('store');
  const permissions = store
    .getState()
    .login.get('loggedUser')
    ?.get('userPermissions');
  const allowedJurisdictions = [
    { value: 'GDPR', label: recordTranslations.gdpr }
  ];
  supportedJurisdictions.forEach((item) => {
    if (permissions.get(`createEdit${item.data}Processing`)) {
      allowedJurisdictions.push({ ...item, value: item.data });
    }
  });
  return allowedJurisdictions;
};

export const checkVendorTokenValidity = (currentToken) => {
  if (currentToken === 'undefined' || currentToken === 'null' || !currentToken)
    return false;
  const config = registry.get('config');
  const { currentId } = getParameterValuesFromHash('/vendorforms/:currentId');
  const decoded = jwtDecode(currentToken);
  const formId = decoded.form_id || '';
  if (formId !== currentId)
    registry.get('storage').removeItem(config.login.vendorToken.storage.key);
  return formId === currentId;
};

export const VendorNotificationTypeMapper = (type) => {
  switch (type) {
    case 'notification_vendor_form_message':
      return vendorTranslations.vendorFormMessage;
    case 'notification_vendor_review':
      return vendorTranslations.vendorReviewNotification;
    default:
      return '';
  }
};

export const getModifiedUrl = (url) =>
  url.match(/^https?:/) ? url : `//${url}`;

export const getLocation = () => {
  const { origin, protocol, hostname, port } = window.location;
  return origin || `${protocol}//${hostname}${port ? `:${port}` : ''}`;
};

export const getBannerMessage = (message) => `<p >${message}</p>`;

export const getFirstChapter = (data) => data[0]?.defaultFilter;

export const getLastChapter = (data) => {
  if (isType(data, 'array')) return getLastChapter(data[data.length - 1]);
  if (!data?.subMenu) return data?.key;
  return getLastChapter(data.subMenu);
};

let flatMenu = [];
const getMenuList = (menuItems) => {
  menuItems.forEach((item) => {
    if (item.subMenu) getMenuList(item.subMenu);
    else if (item.enable) flatMenu.push(item.key);
  });
};

export const getChapter = (menuItems, currentFilter, next) => {
  flatMenu = [];
  getMenuList(menuItems);
  return flatMenu[flatMenu.findIndex((item) => item === currentFilter) + next];
};

export const getFlatMenu = (menuItems) => {
  flatMenu = [];
  getMenuList(menuItems);
  return flatMenu;
};

export const getBrowserOrFallBackLang = (defaultLanguage) => {
  const availableLanguages = languages.map((item) => item.locale);
  const [language, region] = (
    window.navigator.language || window.navigator.userLanguage
  ).split('-');
  if (availableLanguages.includes(language)) {
    return language;
  } else if (region && availableLanguages.includes(`${language}-${region}`))
    return `${language}-${region}`;
  return defaultLanguage;
};

export const getHistoryGoBackLink = (history, theme) => (
  <button
    type="button"
    className="historyGoBackBtn"
    onClick={history.goBack}
    style={{
      color: theme.palette ? theme.palette.primary.main : ''
    }}
  >
    <FontAwesome
      name="angle-left"
      style={{ fontSize: 'inherit', fontWeight: 'inherit' }}
    />
    <span>{commonTranslations.goBack}</span>
  </button>
);

export const getAptPDFExportTypes = (recordType) => {
  switch (recordType) {
    case 'processings':
    case 'processing_processings':
      return processingPdfExportTypes;
    case 'dpia_assessments':
    case 'assessments':
      return assessmentPdfExportTypes;
    case 'breach_breaches':
    case 'breaches':
      return breachPdfExportTypes;
    case 'tia_assessments':
    case 'tias':
      return tiaPdfExportTypes;
    default:
      return [];
  }
};

export const getAptDefaultValue = (recordType) => {
  switch (recordType) {
    case 'processings':
    case 'processing_processings':
      return processingPdfExportTypes[0].value;
    case 'dpia_assessments':
    case 'assessments':
      return assessmentPdfExportTypes[0].value;
    case 'breaches':
    case 'breach_breaches':
      return breachPdfExportTypes[0].value;
    case 'tia_assessments':
    case 'tias':
      return tiaPdfExportTypes[0].value;
    default:
      return '';
  }
};

export const filterJurisdictionChapters = (menuItems, jurisdictions) =>
  menuItems
    ?.map((item) => ({
      ...item,
      subMenu:
        item.subMenu && filterJurisdictionChapters(item.subMenu, jurisdictions)
    }))
    .filter(
      (item) =>
        !item.metadata?.jurisdictions ||
        JSON.parse(item.metadata?.jurisdictions).some((jur) =>
          jurisdictions.includes(jur)
        )
    );

export const getTransformedFilter = (
  item,
  locale,
  tenantLocale,
  parent,
  isGlobal
) => {
  const parentId = parent ? `${item.uniqueId}_${parent}` : item.uniqueId;
  const subMenu = item.subRecordTypes.map((subRecords) => ({
    recordTypeId: item.id,
    recordLayoutId: subRecords.id,
    recordLayoutType: subRecords.uniqueId,
    recordLabel: getTranslatedLabel(item.names, locale, tenantLocale),
    label: getTranslatedLabel(subRecords.names, locale, tenantLocale),
    key: `${subRecords?.uniqueId}_${isGlobal ? item.uniqueId : parentId}`,
    status: subRecords.status,
    enable: true,
    useDefaultLayoutLogic: subRecords.useDefaultLayoutLogic
  }));

  const modifiedData = {
    label: getTranslatedLabel(item.names, locale, tenantLocale),
    key: parentId,
    enable: true,
    defaultFilter: subMenu?.length
      ? `${item.subRecordTypes[0]?.uniqueId}_${parentId}`
      : parentId,
    ...(subMenu?.length && { subMenu })
  };
  return modifiedData;
};

export const getExpiryValues = (item) => {
  const { deadlineExpiryDuration } = item;
  let dateExpiry = deadlineExpiryDuration || '';
  const deadlineExpiryStatus = item.deadlineExpiryStatus
    ? item.deadlineExpiryStatus
    : '';
  const overDueValue =
    deadlineExpiryStatus === 'deadline_expired' &&
    deadlineExpiryDuration.includes('PT');
  const startValue = overDueValue ? 3 : 2;
  dateExpiry =
    deadlineExpiryStatus === 'deadline_expired'
      ? deadlineExpiryDuration[0] +
        deadlineExpiryDuration.substring(
          startValue,
          deadlineExpiryDuration.length
        )
      : deadlineExpiryDuration;
  const match = deadlineExpiryDuration && dateExpiry.match(/P(T*)(\d+)\w/);

  return { dateExpiry, match, deadlineExpiryStatus };
};

export const getRemainingDays = (item, formatMessage) => {
  const { dateExpiry, match, deadlineExpiryStatus } = getExpiryValues(item);
  const dateExpiryValue =
    match &&
    formatMessage(transformRetentionTerm(dateExpiry).props, {
      term: match[2]
    });

  const remainingDays = match
    ? customTranslation('expiryWarning', dateExpiryValue)
    : '';

  return deadlineExpiryStatus && deadlineExpiryStatus === 'deadline_expired'
    ? customTranslation('overDueWarning', dateExpiryValue)
    : remainingDays;
};

const defaultOverviewFilter = 'dfa70afe-442d-4356-95c4-149fb5ce16a8_dashboards';
export const checkActionPermission = (
  data,
  action,
  currentUser,
  createEditTenantDashboard,
  access = 'Write',
  isFromFilter = false
) => {
  // isFromFilter is used to hide the name edit of overview dashboard.
  const { acl, key, createdBy, dashboardType } = data;
  if (action === 'delete') {
    return currentUser === createdBy?.id;
  }
  // Reject icon is only shown for shared dashboard.
  if (action === 'reject') {
    if (dashboardType === 'personal' && currentUser !== createdBy.id) {
      return true;
    }
    return false;
  }
  if (dashboardType === 'tenant') {
    if (action === 'share') {
      return false;
    } else {
      return key === defaultOverviewFilter && !isFromFilter
        ? false
        : createEditTenantDashboard;
    }
  }
  return acl
    ?.find((item) => item.subject.id === currentUser)
    .rights.includes(access);
};

export const getEditRecordTypes = (recordList, userPermissions) => {
  const {
    createProcessing,
    createPreDpia,
    createBreach,
    createEditDocumentRecord,
    createCustom
  } = userPermissions;

  if (!recordList) {
    return [];
  }
  return recordList.filter(
    (item) =>
      ({
        assessments: createPreDpia,
        processings: createProcessing,
        breaches: createBreach,
        'document-records': createEditDocumentRecord
      }[item.uniqueId] ?? createCustom)
  );
};

export const checkForNextDataFetch = (items, scrollY) =>
  items.size >= GENERAL_FETCH_LIMIT &&
  40 * items.size - getContainerHeight() <= scrollY;

export const featurePermissionMap = {
  // dsr
  viewDsr: ['view-data-subject-request'],
  createEditDsr: ['create-edit-data-subject-request'],
  deleteDsr: ['delete-data-subject-request'],
  approveDsr: ['approve-data-subject-request'],
  gdprDsrAccess: ['access-gdpr-dsr'],
  ccpaDsrAccess: ['access-ccpa-dsr'],
  // environment
  viewEnvironmentData: [
    'view-assessment',
    'view-processing',
    'view-breach',
    'view-document-record',
    'view-custom-privacy-record-data'
  ],
  viewUserOrg: ['view-user', 'view-organisation'],
  viewOrganisation: ['view-organisation'],
  createEditOrganisation: ['create-edit-organisation'],
  viewUser: ['view-user'],
  createEditUser: ['create-edit-user'],
  viewPreDpia: ['view-preDpia'],
  createPreDpia: ['create-edit-preDpia'],
  viewAssessment: ['view-assessment'],
  createAssessment: ['create-edit-assessment'],
  viewProcessing: ['view-processing'],
  createProcessing: ['create-edit-processing'],
  viewBreach: ['view-breach'],
  createBreach: ['create-edit-breach'],
  viewTia: ['view-tia'],
  createTia: ['create-edit-tia'],
  viewPrivacyRecord: [
    'view-preDpia',
    'view-processing',
    'view-breach',
    'view-custom-privacy-record-data'
  ],
  createPrivacyRecord: [
    'create-edit-preDpia',
    'create-edit-processing',
    'create-edit-breach',
    'create-edit-custom-privacy-record-data'
  ],
  deletePrivacyRecord: [
    'delete-assessment',
    'delete-processing',
    'delete-breach',
    'delete-custom-privacy-record-data'
  ],
  viewCustom: ['view-custom-privacy-record-data'],
  createCustom: ['create-edit-custom-privacy-record-data'],
  viewDocument: ['view-document'],
  viewDocumentRecord: ['view-document-record'],
  createEditDocumentRecord: ['create-edit-document-record'],
  viewCCPAProcessing: ['view-ccpa-processing'],
  createEditCCPAProcessing: ['create-edit-ccpa-processing'],
  viewAPPIProcessing: ['view-appi-processing'],
  createEditAPPIProcessing: ['create-edit-appi-processing'],
  viewPIPLProcessing: ['view-pipl-record'],
  createEditPIPLProcessing: ['create-edit-pipl-record'],
  viewPDPAProcessing: ['view-pdpa-record'],
  createEditPDPAProcessing: ['create-edit-pdpa-record'],
  viewLGPDProcessing: ['view-lgpd-record'],
  createEditLGPDProcessing: ['create-edit-lgpd-record'],
  viewPDPProcessing: ['view-pdp-record'],
  createEditPDPProcessing: ['create-edit-pdp-record'],
  viewFDPLProcessing: ['view-fdpl-record'],
  createEditFDPLProcessing: ['create-edit-fdpl-record'],
  viewSGPDPAProcessing: ['view-sgpdpa-record'],
  createEditSGPDPAProcessing: ['create-edit-sgpdpa-record'],
  viewUKGDPRProcessing: ['view-ukgdpr-record'],
  createEditUKGDPRProcessing: ['create-edit-ukgdpr-record'],
  viewIDPRProcessing: ['view-idpr-record'],
  createEditIDPRProcessing: ['create-edit-idpr-record'],
  viewCustomFieldData: ['view-custom-field-data'],
  createEditVendorForms: ['create-edit-vendor-forms'],
  viewGraph: ['view-graph'],
  createEditDataRecipientCategory: ['create-edit-data-recipient-category'],
  createEditProcessingCategories: ['create-edit-processing-categories'],
  createEditReferences: ['create-edit-reference'],
  createEditSecurityMeasures: ['create-edit-security-measures-description'],
  createEditPurposes: ['create-edit-purpose'],
  createEditDataSourceCategories: ['create-edit-data-source-category'],
  createEditAssessmentMasterData: ['create-edit-assessment-masterdata'],
  createEditDataSubjectCategories: ['create-edit-data-subject-categories'],
  createEditPersonalDataCategories: ['create-edit-personal-data-categories'],
  createEditPersonalDataItem: ['create-edit-personal-data-item'],
  createEditDataSources: ['create-edit-data-sources'],
  createEditRetentionOffsets: ['create-edit-retention-term-offset'],
  createEditLinks: ['create-edit-links'],
  createEditCustomFieldData: ['create-edit-custom-field-data'],
  masterDataExport: ['export-master-data-csv'],
  masterDataImport: ['import-master-data-csv'],
  viewReport: ['view-report-search'],
  createEditReport: ['create-edit-report-search'],
  viewFairenessOfDecisions: ['view-fairness-of-decisions'],
  viewDefaultPrivacyRecord: ['view-preDpia', 'view-processing', 'view-breach'],
  // navbar user menu
  accessSubtenant: ['access-subtenant'],
  viewCustomFields: ['view-custom-fields'],
  createEditCustomFields: ['create-edit-custom-fields'],
  viewKnowledgeHub: ['view-knowledge-hub'],
  // settings
  viewSettings: ['view-settings'],
  createEditSettings: ['create-edit-settings'],
  createEditAccessToken: ['create-edit-access-token'],
  createEditPublicRegistry: ['create-edit-public-registry'],
  createEditHelpNotes: ['create-edit-help-notes'],
  editWhitelabelling: ['create-edit-white-labelling'],
  viewFieldVisibility: ['view-field-visibility'],
  editFieldVisibility: ['create-edit-field-visibility'],
  viewSSO: ['view-sso-setting'],
  createEditSSO: ['create-edit-sso-setting'],
  viewMFA: ['view-mfa-setting'],
  createEditMFA: ['create-edit-mfa-setting'],
  viewMasterData: [
    'view-processing-categories',
    'view-data-subject-categories',
    'view-personal-data-item',
    'view-personal-data-categories',
    'view-data-sources',
    'view-security-measures-description',
    'view-reference',
    'view-dpia',
    'view-data-recipient-category'
  ],
  createEditMasterData: [
    'create-edit-processing-categories',
    'create-edit-data-subject-categories',
    'create-edit-personal-data-item',
    'create-edit-personal-data-categories',
    'create-edit-data-sources',
    'create-edit-security-measures-description',
    'create-edit-reference',
    'create-edit-dpia',
    'create-edit-data-recipient-category'
  ],
  createEditSubscription: ['create-edit-subscription'],
  viewEditExternalForms: ['view-external-forms', 'create-edit-external-forms'],
  // dashboard
  viewDashboardMenu: ['view-dashboard', 'view-audit-trail', 'view-job'],
  viewDashboard: ['view-dashboard'],
  viewAuditTrail: ['view-audit-trail'],
  viewJob: ['view-job'],
  viewRecord: [
    'view-preDpia',
    'view-processing',
    'view-breach',
    'view-custom-privacy-record-data',
    'view-document-record'
  ],
  // message centre
  viewMessageCentre: ['view-message', 'view-task', 'view-notification'],
  viewMessage: ['view-message'],
  createEditMessage: ['create-edit-message'],
  viewTask: ['view-task'],
  viewNotification: ['view-notification'],
  viewTemplate: ['view-template'],
  viewTiaTemplate: ['view-tia-template'],
  createEditTiaTemplate: ['create-edit-tia-template'],
  exportPdf: ['export-privacy-record-pdf'],
  approveAssessment: ['approve-assessment'],
  deleteAssessment: ['delete-assessment'],
  approveProcessing: ['approve-processing'],
  deleteProcessing: ['delete-processing'],
  approveBreach: ['approve-breach'],
  deleteBreach: ['delete-breach'],
  approveDocument: ['approve-document-record'],
  deleteDocument: ['delete-document-record'],
  approveCustom: ['approve-custom-privacy-record-data'],
  deleteCustom: ['delete-custom-privacy-record-data'],
  deleteTia: ['delete-tia'],
  createTemplate: ['create-edit-template'],
  tenantAdministrator: ['tenant-administrator'],
  tenantExpired: ['tenant-plan-expired'],
  createEditBillingPlan: ['create-edit-billing-plan'],
  createEditBanner: ['create-edit-banner'],
  hasIsmsAccess: ['isms-module-access'],
  hasPmsAccess: ['pms-module-access'],
  hasHoldingAccess: ['holding-tenant-access'],
  createEditSubTenant: ['create-edit-subtenant'],
  // data library
  dataLibraryAccess: ['data-library-access'],
  recordsLibraryAccess: ['records-library-access'],
  templatesLibraryAccess: ['templates-library-access'],
  layoutsLibraryAccess: ['layouts-library-access']
};

export const hierarchyValues = [NO_LIMIT, TOP_DOWN, FLAT];

const getUserHierarchy = (accessGroups) => {
  const isHolding = (name) => 
    name === 'Holding Administrator' || name === 'Coordinator';

  const hierarchyList = accessGroups.map((item) => 
    isHolding(item.name) ? NO_LIMIT : item.pmsDefinition?.organisationHierarchy
  );

  return hierarchyValues.find((value) => hierarchyList.includes(value));
};

export const modifyUserProfile = (data) => ({
  ...data,
  userHierarchy: getUserHierarchy(data.accessGroups),
  viewAccess: !data.accessGroups.find(
    (accessGroup) => accessGroup.pmsDefinition?.viewAccess === false
  )
});

export function* checkIsViewRestrictedUser() {
  const userState = yield select((state) => state.user);
  return userState.get('profile')?.get('viewAccess');
}

export const getStakeholderType = (uniqueId) =>
  ({
    ppControllers: 'controllers',
    ppProcessors: 'processors',
    ppExecutingEntities: 'executingEntities',
    ppDataRecipients: 'dataRecipients'
  }[uniqueId] || 'customStakeholders');

export const addKeyToDataSource = ({
  name,
  organisation,
  dataStorageCountry
}) => {
  if (organisation) {
    return `${name} (${organisation.name} ${organisation.country.id}) ${
      dataStorageCountry ? `(${dataStorageCountry})` : ''
    }`;
  } else {
    return `${name}${dataStorageCountry ? ` (${dataStorageCountry})` : ''}`;
  }
};

export const PRIVACY_STATEMENT_URL =
  'https://privacyperfect.com/privacy-statement/';

export const required = (value) =>
  (!value || !value.length) && errortranslations.required;
export const requiredEmail = (value) =>
  !validateEmail(value) && errortranslations.invalidEmail;
export const requiredItems = (value) =>
  !(value && value.length > 0) && errortranslations.required;
export const requiredNumOfSubjects = (value) =>
  !value && errortranslations.required;
