import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl-redux';

import { registerServices } from '@packages/services';

import App from './App';
import store from './store';

registerServices(store);
store.dispatch({ type: 'APP:INIT' });

// Custom intl selector
const intlSelector = (state) => {
  const { intl } = state;
  return {
    ...intl,
    key: intl.locale
  };
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <IntlProvider intlSelector={intlSelector}>
        <App />
      </IntlProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
