import React, { useEffect, useState } from 'react';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import { Column } from 'fixed-data-table';
import { injectIntl, FormattedMessage } from 'react-intl';

import Note from '@packages/components/note';
import {
  ResponsiveTableWrapper,
  SortHeaderCell,
  DataCell,
  DateCell,
  InformationCell,
  ActionCell
} from '@packages/components/responsive-table';
import {
  statusValueMapper,
  commonTranslations,
  recordTranslations,
  deleteRecord
} from '@packages/utils/commontranslations';
import styles from '@packages/ui/styles';
import {
  getContainerHeight,
  RECORDS_FETCH_LIMIT,
  getEmbedContainerHeight
} from '@packages/utils/common-utils';
import { getQueryStrings } from '@packages/utils/query-parameters';
import CustomDialog from '@packages/components/custom-dialog';
import {
  filterItem,
  getModifiedActions,
  statusColorCodes
} from '@packages/utils/record-utils';

import {
  shouldHideMenuItem,
  getRecordStatusFilterValues,
  recordRiskFilterValues
} from '../../record-utils';
import NotificationDialogBox from '../../../privacy-record-detail/components/notificationDialog';
import { DataRiskArrayCell } from '../processings/components/data-risk-array-cell/dataRiskArrayCell';

const actionItems = [
  {
    action: 'addNote',
    primaryText: commonTranslations.addNote
  },
  {
    action: 'editNote',
    primaryText: commonTranslations.editNote
  },
  {
    action: 'viewNote',
    primaryText: commonTranslations.viewNote
  },
  {
    action: 'removeNote',
    primaryText: commonTranslations.removeNote
  },
  // {
  //   action: 'changelog',
  //   primaryText: recordTranslations.viewChangeLog
  // },
  {
    action: 'graphicalView',
    primaryText: recordTranslations.graphicalView
  },
  {
    action: 'copyRecord',
    primaryText: recordTranslations.copyRecord
  }
];

const CustomRecordList = (props, context) => {
  const {
    items,
    isFetching,
    locale,
    pageSearchText,
    filteredOn,
    filterColumn,
    sortKey,
    sortOrder,
    registryDetails,
    pricingPlan,
    intl,
    isEditable,
    isViewable,
    canApprove,
    isVendor,
    position,
    onMount,
    updateNote,
    deletePrivacyRecord,
    getNextData,
    onChooseFilter,
    toggleLoader,
    isUpdating,
    selectedRecords,
    tenantLocale,
    embed,
    currentFilter,
    filterMenuData,
    recordParentType,
    copyRecord,
    isDeletable,
    isGlobal,
    showRisks
  } = props;

  const [recordId, setRecordId] = useState(-1);
  const [open, setOpen] = useState(false);
  const [deleteNoteOpen, setDeleteNoteOpen] = useState(false);
  const [editNoteOpen, setEditNoteOpen] = useState(false);
  const [viewNoteOpen, setViewNoteOpen] = useState(false);
  const [showFilterIcon, setShowFilterIcon] = useState(false);
  const [content, setContent] = useState(null);
  const [recordName, setRecordName] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [isInSync, setIsInSync] = useState(false);

  useEffect(() => {
    onMount(currentFilter, filterMenuData?.recordLayoutId);
  }, [currentFilter]);

  useEffect(() => {
    if (isDelete) {
      getNextData(position, filterMenuData.recordLayoutId, 1);
      setIsDelete(false);
    }
    toggleLoader(isUpdating || isFetching);
  }, [items.size, isUpdating, isFetching, selectedRecords]);

  const handleDeleteRecord = () => {
    setIsDelete(true);
    deletePrivacyRecord(recordId, filterMenuData.recordLayoutId);
    handleClose();
  };

  const handleRowClick = (e, index) => {
    const { id, recordLayoutData } = items.get(index);
    let url;
    if (currentFilter.split('_')[1] === 'assessments')
      url = `/assessment/${id}/view`;
    else if (currentFilter.split('_')[1] === 'processings')
      url = `/processing/${id}/view`;
    else if (currentFilter.split('_')[1] === 'breaches')
      url = `/breach/${id}/view`;
    else url = `/custom-record/${recordLayoutData.id}/${id}/view`;
    if (isViewable) {
      const hashURL = window.location.hash;
      const urlParams = getQueryStrings(hashURL);
      const location =
        urlParams.from === 'overview' ? 'overview' : 'privacyrecords';
      context.router.history.push(`${url}?from=${location}`);
    }
  };

  const handleEditRecordAction = (index) => {
    const hashURL = window.location.hash;
    const urlParams = getQueryStrings(hashURL);
    const { id, status, recordLayoutData, syncInfo } = items.get(index);

    if (
      status === 'record_status_requested' ||
      status === 'record_status_external_submitted' ||
      syncInfo?.isLibrarySyncEnabled
    ) {
      setOpenNotification(true);
      if(status === 'record_status_external_submitted') setIsSubmitted(true)
      setIsInSync(syncInfo?.isLibrarySyncEnabled);
    } else if (currentFilter.split('_')[1] === 'assessments') {
      context.router.history.push(`/assessment/${id}/edit`);
    } else if (currentFilter.split('_')[1] === 'processings') {
      context.router.history.push(`/processing/${id}/edit`);
    } else if (currentFilter.split('_')[1] === 'breaches') {
      context.router.history.push(`/breach/${id}/edit`);
    } else if (urlParams.from === 'overview') {
      context.router.history.push(
        `/custom-record/${recordLayoutData.id}/${id}/edit?from=overview`
      );
    } else {
      context.router.history.push(
        `/custom-record/${recordLayoutData.id}/${id}/edit`
      );
    }
  };

  const handleDeleteRecordAction = (index) => {
    const { id, name, status, syncInfo } = items.get(index);
    if (
      (!canApprove && (status === 'record_status_requested' || status === 'record_status_external_submitted')) ||
      syncInfo?.isLibrarySyncEnabled
    ) {
      setOpenNotification(true);
      if(status === 'record_status_external_submitted') setIsSubmitted(true)
      setIsInSync(syncInfo?.isLibrarySyncEnabled);
    } else {
      handleOpen(id, name);
    }
  };

  const handleScrollEnd = (scrollX, scrollY) => {
    if (
      items.size >= RECORDS_FETCH_LIMIT &&
      40 * (items.size - 1) -
        (embed ? getEmbedContainerHeight() : getContainerHeight()) <=
        scrollY
    ) {
      getNextData(position, filterMenuData.recordLayoutId);
    }
    setIsDelete(false);
  };

  const handleAddNoteOpen = (recordsId, recordsName) => {
    setAddNoteOpen(true);
    setRecordName(recordsName);
    setRecordId(recordsId);
  };

  const handleEditNoteOpen = (recordsId, recordsName, contents) => {
    setEditNoteOpen(true);
    setRecordId(recordsId);
    setRecordName(recordsName);
    setContent(contents);
  };

  const handleViewNoteOpen = (name, note) => {
    setViewNoteOpen(true);
    setContent(note);
    setRecordName(name);
  };

  const handleViewNote = (evt, rowIndex) => {
    evt.stopPropagation();

    const { name, note } = items.get(rowIndex);
    handleViewNoteOpen(name, note);
  };

  const handleDeleteNoteOpen = (id, recordsName) => {
    setDeleteNoteOpen(true);
    setRecordId(id);
    setContent('');
    setRecordName(recordsName);
  };

  const handleRecordActions = (actionButton, index, action) => {
    const { id: recordsId, name, recordType, note } = items.get(index);
    if (action === 'edit') {
      handleEditRecordAction(index);
    } else if (action === 'graphicalView') {
      context.router.history.push(`/${recordType}/${recordsId}/graph`);
    } else if (action === 'delete') {
      handleDeleteRecordAction(index);
    } else if (action === 'copyRecord') {
      handleCopyRecord(name, recordsId);
    } else if (action === 'addNote') {
      handleAddNoteOpen(recordsId, name);
    } else if (action === 'editNote') {
      handleEditNoteOpen(recordsId, name, note);
    } else if (action === 'viewNote') {
      handleViewNoteOpen(name, note);
    } else if (action === 'removeNote') {
      handleDeleteNoteOpen(recordsId, name);
    }
  };

  const handleCloseNotification = () => {
    setOpenNotification(false);
    setIsSubmitted(false)
  };
  const handleCopyRecord = (name, recordsId) => {
    copyRecord(
      recordsId,
      locale,
      tenantLocale,
      recordParentType,
      intl.formatMessage,
      isGlobal
    );
  };

  const handleChange = (event) => {
    setContent(event.target.value);
  };

  // Handle note value changes
  const handleEditNote = (event, note) => {
    const noteContent = note || content;
    if (
      recordId !== -1 &&
      (((addNoteOpen || editNoteOpen) && noteContent) || deleteNoteOpen)
    ) {
      updateNote(
        recordId,
        'note',
        noteContent.trim(),
        recordName,
        filterMenuData.recordLayoutId
      );
    }
    requestNoteClose();
  };

  const handleMouseHover = (event, index) => {
    setCurrentIndex(index);
  };

  const requestNoteClose = () => {
    setRecordId(-1);
    setEditNoteOpen(false);
    setViewNoteOpen(false);
    setAddNoteOpen(false);
    setDeleteNoteOpen(false);
    setContent(undefined);
  };

  const filterData = (filterKey, filterParams) => {
    onChooseFilter(
      Object.assign({}, filterParams, { filterKey }),
      filterMenuData.recordLayoutId
    );
  };

  const handleClose = () => {
    setRecordId(-1);
    setOpen(false);
  };

  const handleOpen = (recordsId, recordsName) => {
    setRecordId(recordsId);
    setOpen(true);
    setRecordName(recordsName);
  };

  const handleFilterIcon = (show) => {
    setShowFilterIcon(show);
  };

  const handleMenuHidden = (menu, rowIndex, action) => {
    const item = items.get(rowIndex);
    if (item.recordType === 'custom' && action === 'graphicalView') return true;
    return shouldHideMenuItem(
      action,
      rowIndex,
      items,
      isEditable,
      isViewable,
      isVendor
    );
  };

  const handleMenuDisabled = (menu, rowIndex, action) =>
    action === 'copyRecord' && filterMenuData?.status === 'Inactive';

  const modifiedAction = getModifiedActions(pricingPlan, actionItems);

  const recordStatusFilterValues = getRecordStatusFilterValues(registryDetails);

  return (
    <div>
      {!isFetching && items.size === 0 && (
        <div style={styles.tableNoContentStyle}>
          {commonTranslations.NoData}
        </div>
      )}
      <ResponsiveTableWrapper
        id="custom_responsive_table"
        actionChooserStyle={styles.actionChooserStyle}
        showActionSelector={true}
        rowHeight={40}
        headerHeight={45}
        rowsCount={items.size}
        onRowClick={handleRowClick}
        actionItems={modifiedAction}
        onActionHandler={handleRecordActions}
        isHidden={handleMenuHidden}
        onScrollEnd={handleScrollEnd}
        actionChooserClass="actionChooserClass"
        onRowMouseEnter={handleMouseHover}
        isDisabled={handleMenuDisabled}
        {...props}
      >
        <Column
          id="privacyRecordNumber"
          columnKey="privacyRecordNumber"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              searchText={pageSearchText.get('privacyRecordNumber')}
              onChooseFilter={filterData}
              pageSearchStyle={{ width: '100px' }}
            >
              {recordTranslations.recordId}
            </SortHeaderCell>
          }
          cell={<DataCell items={items} />}
          width={160}
        />
        <Column
          id="name"
          columnKey="name"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              searchText={pageSearchText.get('name')}
              onChooseFilter={filterData}
              tooltip={true}
            >
              {commonTranslations.name}
            </SortHeaderCell>
          }
          cell={<DataCell items={items} />}
          flexGrow={2}
          width={100}
        />
        <Column
          id="status"
          columnKey="status"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              enableFilter={true}
              filterValues={recordStatusFilterValues}
              filteredOn={filteredOn}
              sortKey={sortKey}
              sortOrder={sortOrder}
              filterColumn={filterColumn && filterColumn.status}
              onChooseFilter={filterData}
              filterItem={filterItem}
            >
              {recordTranslations.status}
            </SortHeaderCell>
          }
          cell={
            <DataCell
              items={items}
              mapper={statusValueMapper}
              styleMapper={statusColorCodes}
            />
          }
          width={190}
        />
        <Column
          columnKey="risks"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableFilter={true}
              filterValues={recordRiskFilterValues}
              filteredOn={filteredOn}
              filterColumn={filterColumn && filterColumn.risks}
              onChooseFilter={filterData}
              showSelectAll={false}
            >
              {isVendor ? (
                recordTranslations.outstandingRisks
              ) : (
                <FormattedMessage
                  id="PrivacyRecordList.risks"
                  description="Privacy Record List Column Risks"
                  defaultMessage="Risks"
                />
              )}
            </SortHeaderCell>
          }
          cell={
            <DataRiskArrayCell
              style={{ color: 'red' }}
              items={items}
              defaultContent={commonTranslations.noRisks}
              tooltipId={isVendor ? '' : 'processingRiskTitle'}
              showRiskCountOnly={isVendor}
              locale={locale}
              tenantLocale={tenantLocale}
            />
          }
          width={isVendor ? 100 : 450}
          flexGrow={isVendor ? 1 : 0}
          visible={showRisks}
        />
        <Column
          id="lastChanged"
          columnKey="lastChanged"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              onChooseFilter={filterData}
            >
              {' '}
              {recordTranslations.lastChanged}
            </SortHeaderCell>
          }
          cell={
            <DateCell dateFormat="DD MMM YYYY" items={items} locale={locale} />
          }
          width={180}
        />
        <Column
          id="action-cell"
          columnKey="action-cell"
          align="center"
          visible={isEditable || isDeletable}
          cell={
            <ActionCell
              currentIndex={currentIndex}
              cellStyle={{ float: 'right' }}
              handleEdit={handleEditRecordAction}
              handleDelete={handleDeleteRecordAction}
              isEditHidden={!isEditable}
              isDeleteHidden={!isDeletable || isVendor}
            />
          }
          width={80}
        />
        <Column
          id="note"
          columnKey="note"
          align="center"
          cell={
            <InformationCell
              items={items}
              handleOnClick={handleViewNote}
              className="informationClass"
            />
          }
          width={30}
        />
      </ResponsiveTableWrapper>
      <CustomDialog
        title={commonTranslations.deletePrivacyRecord}
        id="delete_record_dialog"
        show={open}
        proceed={handleDeleteRecord}
        cancel={handleClose}
        content={deleteRecord(recordName)}
      />
      {(editNoteOpen || addNoteOpen) && (
        <Note
          id="edit-note"
          content={addNoteOpen ? null : content}
          onChange={handleChange}
          isEdit={true}
          headerLabel={
            addNoteOpen
              ? commonTranslations.addNoteHeader
              : commonTranslations.editNoteHeader
          }
          entityName={recordName}
          open={editNoteOpen || addNoteOpen}
          handleEditNote={handleEditNote}
          close={requestNoteClose}
        />
      )}
      {viewNoteOpen && (
        <Note
          id="view-note"
          content={content}
          isEdit={false}
          headerLabel={commonTranslations.viewNoteHeader}
          entityName={recordName}
          open={viewNoteOpen}
          close={requestNoteClose}
        />
      )}
      <CustomDialog
        id="delete_note_dialog"
        show={deleteNoteOpen}
        proceed={(e) => handleEditNote(e, '')}
        cancel={requestNoteClose}
        content={recordTranslations.deleteNote}
      />
      <NotificationDialogBox
        open={openNotification}
        cancel={handleCloseNotification}
        message={isSubmitted ? recordTranslations.editSubmittedNotification : '' 
                          || (isInSync ? recordTranslations.itemIsInSync : '')}
      />
    </div>
  );
};

CustomRecordList.displayName = 'CustomRecordList';

CustomRecordList.propTypes = {
  locale: PropTypes.string.isRequired,
  tenantLocale: PropTypes.string,
  isEditable: PropTypes.bool,
  isViewable: PropTypes.bool,
  isVendor: PropTypes.bool,
  position: PropTypes.number,
  items: PropTypes.instanceOf(Immutable.List),
  isFetching: PropTypes.bool,
  onMount: PropTypes.func,
  updateNote: PropTypes.func,
  deletePrivacyRecord: PropTypes.func,
  getNextData: PropTypes.func,
  onChooseFilter: PropTypes.func,
  canApprove: PropTypes.bool,
  showNotification: PropTypes.func,
  pageSearchText: PropTypes.instanceOf(Immutable.Map),
  filteredOn: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  filterColumn: PropTypes.objectOf(PropTypes.string),
  sortKey: PropTypes.string,
  sortOrder: PropTypes.string,
  isUpdating: PropTypes.bool,
  toggleLoader: PropTypes.func,
  selectedRecords: PropTypes.arrayOf(PropTypes.string),
  pricingPlan: PropTypes.instanceOf(Immutable.Map),
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }).isRequired,
  isPublic: PropTypes.bool,
  embed: PropTypes.bool,
  registryDetails: PropTypes.instanceOf(Immutable.Map),
  currentFilter: PropTypes.string,
  filterMenuData: PropTypes.shape({
    recordLayoutId: PropTypes.string,
    status: PropTypes.string
  }),
  recordParentType: PropTypes.string,
  copyRecord: PropTypes.func,
  isDeletable: PropTypes.bool,
  isGlobal: PropTypes.bool,
  showRisks: PropTypes.bool
};

CustomRecordList.defaultProps = {
  tenantLocale: '',
  isFetching: false,
  isEditable: false,
  isViewable: false,
  canApprove: false,
  showNotification: (e) => e,
  position: 0,
  items: Immutable.List(),
  onMount: (e) => e,
  updateNote: (e) => e,
  deletePrivacyRecord: (e) => e,
  getNextData: (e) => e,
  onChooseFilter: (e) => e,
  pageSearchText: Immutable.Map(),
  filteredOn: {},
  filterColumn: {},
  sortKey: '',
  sortOrder: '',
  isUpdating: false,
  toggleLoader: (e) => e,
  selectedRecords: [],
  pricingPlan: Immutable.Map(),
  isVendor: false,
  isPublic: false,
  embed: false,
  registryDetails: Immutable.Map(),
  currentFilter: '',
  filterMenuData: {},
  recordParentType: '',
  copyRecord: (e) => e,
  isDeletable: false,
  isGlobal: false,
  showRisks: false
};

CustomRecordList.contextTypes = {
  router: PropTypes.shape({}).isRequired
};

export default injectIntl(CustomRecordList);
