import Immutable from 'immutable';

import { getTranslatedLabel } from '../tenant-configuration/tenantConfigUtils';
import homeLayoutTranslations from './homeLayoutTranslations';

const defaultState = Immutable.fromJS({
  isFetching: false,
  zoom: true,
  accessData: Immutable.List(),
  currentFilter: '',
  error: Immutable.Map({
    message: '',
    isError: false
  }),
  tenantsAndTags: [],
  subTenantHierarchy: undefined,
  subTenantDetails: {},
  tagDetails: {},
  isGlobal: false,
  hasHoldingRoles: false,
  globalUsage: {},
  subTenantName: '',
  reportItems: Immutable.List(),
  currentReportFilter: 'list',
  filters: []
});

const getModifiedSubMenu = (data, locale, tenantLocale) => ({
  ...data,
  key: `${data.id}_dashboards`,
  label: getTranslatedLabel(data?.names, locale, tenantLocale)
});

export default (state = defaultState, action) => {
  const { data, locale, tenantLocale, isEdit } = action;
  switch (action.type) {
    case 'GLOBAL:SOLUTION:TENANT:DETAILS:FETCH:INIT': {
      return state
        .set('isFetching', true)
        .set('error', defaultState.get('error'))
        .set('accessData', defaultState.get('accessData'));
    }
    case 'GLOBAL:SOLUTION:TENANT:DETAILS:FETCH:SUCCESS': {
      return state
        .set('isFetching', false)
        .set('accessData', Immutable.List(action.accessData));
    }
    case 'GLOBAL:SOLUTION:TENANT:DETAILS:FETCH:FAIL':
      return state
        .set('isFetching', false)
        .set('error', Immutable.Map({ message: action.error, isError: true }));

    case 'GLOBAL:SOLUTION:FILTERS:INIT': {
      return state.set('isGlobal', true).set('hasHoldingRoles', action.hasHoldingRoles);
    }
    case 'GLOBAL:SOLUTION:FILTERS:CHANGE':
    case 'HOLDING_DASHBOARD:FILTERS:CHANGE':
      return state.set('currentFilter', action.filterName);
    case 'GLOBAL:TENANTS_TAGS:FETCH:SUCCESS':
      return state.set('tenantsAndTags', action.items);
    case 'GLOBAL:TENANTS_TAGS:FETCH:INIT':
      return state.set('tenantsAndTags', defaultState.get('tenantsAndTags'));
    case 'FETCH:SUB_TENANTS:HIERARCHY:SUCCESS':
      return state.set('subTenantHierarchy', action.items);
    case 'SUB_TENANT:DETAIL:FETCH:SUCCESS':
      return state.set('subTenantDetails', action.data);
    case 'TAG:DETAIL:FETCH:SUCCESS':
      return state.set('tagDetails', action.data);
    case 'GLOBAL:SOLUTION:QUICK:REPORT:FETCH:INIT':
      return state.set('isFetching', true);
    case 'GLOBAL:SOLUTION:QUICK:REPORT:FETCH:SUCCESS':
      return state
        .set('reportItems', Immutable.List(action.data))
        .set('isFetching', false);
    case 'GLOBAL:SOLUTION:QUICK:REPORT:FETCH:RESET':
      return state.set('reportItems', defaultState.reportItems);
    case 'GLOBAL:SOLUTION:QUICK:REPORT:FETCH:FAIL':
      return state.set('isFetching', false);
    case 'RESET:ISGLOBAL':
      return state.set('isGlobal', defaultState.isGlobal).set('hasHoldingRoles', defaultState.hasHoldingRoles);
    case 'GLOBAL:LEGAL_ENTITIES:USAGE:RESET':
    case 'GLOBAL:LEGAL_ENTITIES:ITEM:USAGE:FETCH:INIT':
      return state
        .set('error', defaultState.get('error'))
        .set('globalUsage', defaultState.get('globalUsage'));
    case 'GLOBAL:LEGAL_ENTITIES:USAGE:SUCCESS': {
      return state.set('globalUsage', action.globalUsage);
    }
    case 'GLOBAL:LEGAL_ENTITIES:USAGE:FAIL':
      return state.set('isFetching', false).set(
        'error',
        Immutable.Map({
          message: action.error,
          isError: true
        })
      );
    case 'GLOBAL:TENANT_CHART:ZOOM:SET':
      return state.set('zoom', action.value);
    case 'GLOBAL:SUBTENANTS:FETCH:INIT':
      return state.set('isFetching', true);
    case 'GLOBAL:SUBTENANTS:FETCH:SUCCESS':
    case 'GLOBAL:SUBTENANTS:FETCH:FAIL':
      return state.set('isFetching', false);
    case 'GLOBAL:SUBTENANTS:CREATE:LATEST':
      return state.set('subTenantName', action.subTenantName);
    case 'GLOBAL:SOLUTION:ADVANCED:REPORTS:FILTER:CHANGE': {
      return state.set('currentReportFilter', action.filter);
    }
    // custom holding dashboard related Actions
    case 'HOLDING_DASHBOARD:FILTER:INIT': {
      const modifiedFilterData = state.get('filters').map((filter) => {
        if (filter.key === 'dashboards') {
          return {
            ...filter,
            subMenu: action.data.map((subMenuItem) =>
              getModifiedSubMenu(
                subMenuItem,
                action.locale,
                action.tenantLocale
              )
            ),
            defaultFilter: `${action.data[0].id}_dashboards`
          };
        }
        return filter;
      });
      return state
        .set('filters', modifiedFilterData)
        .set(
          'currentFilter',
          state.get('currentFilter') || `${action.data[0].id}_dashboards`
        )
        .set('isLoading', false);
    }
    case 'GLOBAL:SOLUTION:MULTI_TENANT_USER:FILTER:INIT': {
      const modifiedFilterData = action.data.filter((menuItem) => menuItem.key === 'access');
      return state
        .set('filters',  Immutable.List(modifiedFilterData))
        .set(
          'currentFilter','access_access'
        )
        .set('isLoading', false)
        .set('isGlobal', true)
        .set('hasHoldingRoles', false);
    }

    case 'HOLDING_DASHBOARD:DEFAULT_FILTERS:INIT': {
      return state.set('filters', Immutable.List(action.data));
    }
    case 'HOLDING_DASHBOARD:LAYOUTS:FETCH_ALL:INIT':
      return state.set('isLoading', true);
    case 'HOLDING_DASHBOARD:SAVE:SUCCESS': {
      const modifiedData = getModifiedSubMenu(data, locale, tenantLocale);
      if (isEdit) {
        const modifiedFilterData = state.get('filters').map((filter) => {
          if (filter.key === 'dashboards') {
            return {
              ...filter,
              subMenu: filter.subMenu.map((subMenuItem) => {
                if (subMenuItem.id === modifiedData.id) {
                  return modifiedData;
                }
                return subMenuItem;
              }),
              defaultFilter: `${data.id}_dashboards`
            };
          }
          return filter;
        });
        return state.set('filters', modifiedFilterData);
      } else {
        const modifiedFilterData = state.get('filters').map((filter) => {
          if (filter.key === 'dashboards') {
            return {
              ...filter,
              subMenu: [
                ...filter.subMenu,
                getModifiedSubMenu(data, locale, tenantLocale)
              ],
              defaultFilter: `${data.id}_dashboards`
            };
          }
          return filter;
        });
        return state
          .set('filters', modifiedFilterData)
          .set('currentFilter', `${data.id}_dashboards`);
      }
    }
    case 'HOLDING_DASHBOARD:LAYOUT:SAVE:SUCCESS': {
      const modifiedFilterData = state.get('filters').map((filter) => {
        if (filter.key === 'dashboards') {
          return {
            ...filter,
            subMenu: filter.subMenu.map((subMenuItem) => {
              if (subMenuItem.id === data.id) {
                return getModifiedSubMenu(data, locale, tenantLocale);
              }
              return subMenuItem;
            }),
            defaultFilter: `${data.id}_dashboards`
          };
        }
        return filter;
      });
      return state
        .set('filters', modifiedFilterData)
        .set(
          'currentFilter',
          state.get('currentFilter') || `${data.id}_dashboards`
        );
    }
    case 'HOLDING_DASHBOARD:LAYOUT:SHARE:SUCCESS': {
      const modifiedFilterData = state.get('filters').map((filter) => {
        if (filter.key === 'dashboards') {
          return {
            ...filter,
            subMenu: filter.subMenu.map((subMenuItem) =>
              subMenuItem.id === data.id
                ? getModifiedSubMenu(data, locale, tenantLocale)
                : subMenuItem
            )
          };
        }
        return filter;
      });
      return state.set('filters', modifiedFilterData);
    }

    case 'HOLDING:TEMPLATES:FILTER:INIT': {
      const modifiedFilterData = state.get('filters').map((filter) => {
        if (filter.key === 'dataManagement' && action.data.length) {
          return {
            ...filter,
            subMenu: [
              ...filter.subMenu,
              {
                key: 'templates_dataManagement',
                label: homeLayoutTranslations.templates,
                enable: true,
                defaultFilter: action.data[0].key,
                subMenu: [...action.data]
              }
            ]
          };
        }
        return filter;
      });
      return state.set('filters', modifiedFilterData).set('isLoading', false);
    }

    default:
      return state;
  }
};
