import registry from 'app-registry';
import { put, select, delay } from 'redux-saga/effects';
import { initialize } from 'redux-form';
import jwtDecode from 'jwt-decode';
import { replace as replaceRouter } from 'connected-react-router';

import { getQueryStrings } from '@packages/utils/query-parameters';
import { serviceDownError } from '@packages/utils/commontranslations';
import notificationtranslations from '@packages/utils/notificationtranslations';
import errortranslations from '@packages/utils/errortranslations';
import { modifyUserProfile } from '@packages/utils/common-utils';

import { stopPricingPlanAPI } from '../login/sagas';

export function* userDetailInit() {
  try {
    const { type } = getQueryStrings();
    const response = yield validateUserToken(type);
    yield response;

    switch (response.status) {
      case 200:
        yield put({ type: 'USER:TOKEN:VALIDATE:SUCCESS' });
        break;
      case 410: {
        yield put({ type: 'USER:TOKEN:VALIDATE:FAIL' });
        break;
      }
      default:
        yield put({ type: 'USER:TOKEN:VALIDATE:FAIL' });
        break;
    }
  } catch (err) {
    switch (err.status) {
      case 502:
      case 503:
      case 504:
        err.message = serviceDownError('user');
        break;
      default:
        err.message = err.message;
        break;
    }
    yield put({
      type: 'NOTIFIER:NOTIFY',
      notification: {
        content: err.message,
        type: 'error'
      }
    });
    yield put({ type: 'USER:TOKEN:VALIDATE:FAIL' });
  }
}

export function* changePasswordInit() {
  const { type = 'resetPassword' } = getQueryStrings();
  try {
    const response = yield validateUserToken(type);
    yield response;
    switch (response.status) {
      case 200:
        yield put({ type: 'FORGOT_PASSWORD:TOKEN:VALIDATE:SUCCESS' });
        break;
      case 410: {
        yield put({ type: 'FORGOT_PASSWORD:TOKEN:VALIDATE:FAIL' });
        yield put(
          replaceRouter({
            pathname: '/login/forgotPassword'
          })
        );
        break;
      }
      default:
        yield put({ type: 'FORGOT_PASSWORD:TOKEN:VALIDATE:FAIL' });
        break;
    }
  } catch (err) {
    switch (err.status) {
      case 502:
      case 503:
      case 504:
        err.message = serviceDownError('user');
        break;
      default:
        err.message = err.message;
        break;
    }
    yield put({
      type: 'NOTIFIER:NOTIFY',
      notification: {
        content: err.message,
        type: 'error'
      }
    });
    yield put({ type: 'FORGOT_PASSWORD:TOKEN:VALIDATE:FAIL' });
  }
}

export function* validateUserToken(type) {
  const { token } = getQueryStrings();
  return yield registry
    .get('request')
    .get(
      `/v1/identity/validate?tokenType=${type}&token=${token}`,
      null,
      {},
      false
    );
}

export function* changePassword(action) {
  const request = registry.get('request');
  const queryParams = getQueryStrings();
  const { token, type = 'resetPassword' } = queryParams;
  let userId = '';
  const data = token
    ? { newPassword: action.newPassword }
    : { oldPassword: action.oldPassword, newPassword: action.newPassword };
  try {
    if (token) {
      userId = getUserId(token);
    } else {
      const userState = yield select((state) => state.user);
      userId = userState ? userState.get('profile').get('id') : null;
    }
    const response = yield request.post(
      `/v1/users/${userId}/change-password?type=${type}`,
      data,
      {
        headers: { token }
      },
      typeof token === 'undefined'
    );
    yield response;

    switch (response.status) {
      case 204: {
        if (token) {
          yield put({
            type: 'LOGIN:POST_LOGIN:SUCCESS',
            response,
            username: jwtDecode(token).email_id
          });
        } else {
          // Clear change password form
          yield put(initialize('ChangePassword', {}));
          yield delay(2000);
          yield put({ type: 'USER:CHANGE_PASSWORD:RESET' });
          yield fetchProfile();
        }
        yield put({ type: 'USER:CHANGE_PASSWORD:SUCCESS', data });
        break;
      }
      case 410: {
        yield put({ type: 'USER:TOKEN:VALIDATE:FAIL' });
        break;
      }
      default: {
        const error = response.body.msg;
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: error,
            type: 'error'
          }
        });

        yield put({ type: 'USER:CHANGE_PASSWORD:FAIL', error });
        break;
      }
    }
  } catch (err) {
    switch (err.status) {
      case 502:
      case 503:
      case 504:
        err.message = serviceDownError('user');
        break;
      default:
        err.message = err.message;
        break;
    }
    yield put({
      type: 'NOTIFIER:NOTIFY',
      notification: {
        content: err.message,
        type: 'error'
      }
    });
    const errorMsg =
      err.response && err.response.body && err.response.body.msg
        ? err.response.body.msg
        : 'Password could not be changed.';
    yield put({ type: 'USER:CHANGE_PASSWORD:FAIL', error: errorMsg });
  }
}

export function* fetchProfile() {
  const config = registry.get('config');
  const userId = registry.get('storage').getItem(config.login.user.storage.key)
    ? JSON.parse(registry.get('storage').getItem(config.login.user.storage.key))
      .userId
    : '';
  if (userId !== '') {
    try {
      const response = yield registry.get('request').get(`/v1/users/${userId}`);

      switch (response.status) {
        case 200: {
          const data = modifyUserProfile(response.body);
          yield put({ type: 'USER:PROFILE:REQUEST:SUCCESS', data });
          break;
        }
        default:
          yield put({
            type: 'NOTIFIER:NOTIFY',
            notification: {
              content: response.body.msg,
              type: 'error'
            }
          });
          break;
      }
    } catch (err) {
      switch (err.status) {
        case 502:
        case 503:
        case 504:
          err.message = serviceDownError('user');
          break;
        default:
          err.message = err.message;
          break;
      }
      yield put({
        type: 'NOTIFIER:NOTIFY',
        notification: {
          content: err.message,
          type: 'error'
        }
      });
      registry.get('logger').error(err);
      yield put({ type: 'USER:PROFILE:REQUEST:FAIL', error: err.message });
    }
  }
}

export function* updateProfile(action) {
  try {
    const errorMsg = '';
    const userProfileData = {
      ...action.userProfile,
      organisationIds: action.userProfile.organisations
        ? getOrganisationIds(action.userProfile.organisations)
        : []
    };
    const userState = yield select((state) => state.user);
    const userId = userState ? userState.get('profile').get('id') : null;
    const response = yield registry
      .get('request')
      .put(`/v1/users/${userId}`, userProfileData);

    yield response;
    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.userDetailUpdated,
            type: 'success'
          }
        });

        // Add a small delay so that user details are updated.
        yield delay(3000);

        // Fire translation init on change of locale.
        yield put({
          type: 'TRANSLATION:INIT',
          locale: userProfileData.language
        });

        yield put({
          type: 'USER:PROFILE:UPDATE:SUCCESS',
          data: modifyUserProfile(response.body)
        });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: notificationtranslations.userDetailUpdateFailed,
            type: 'error'
          }
        });
        yield put({ type: 'USER:PROFILE:UPDATE:FAIL', error: errorMsg });
        break;
    }
  } catch (err) {
    switch (err.status) {
      case 502:
      case 503:
      case 504:
        err.message = serviceDownError('user');
        break;
      default:
        err.message = err.message;
        break;
    }
    yield put({
      type: 'NOTIFIER:NOTIFY',
      notification: {
        content: err.message,
        type: 'error'
      }
    });
    registry.get('logger').error(err);
    yield put({ type: 'USER:PROFILE:UPDATE:FAIL', error: err.message });
  }
}

const getOrganisationIds = (organisations) => {
  const organisationIds = [];
  organisations.forEach((item) => {
    const id = item.value ? item.value.id : item.id;
    organisationIds.push(id);
  });
  return organisationIds;
};

export function* updateUserLocale(action) {
  const userProfileState = yield select((state) => state.user.get('profile'));
  let userProfile = userProfileState.toJS();
  userProfile = {
    ...userProfile,
    language: action.locale
  };

  yield put({ type: 'USER:PROFILE:UPDATE', userProfile });
}

export function* upsertUser(action) {
  const request = registry.get('request');

  const { data } = action;
  const { type, token } = getQueryStrings();
  const requestData = { ...data, type };
  try {
    const requestUrl =
      type === 'user' || type === 'admin'
        ? `/v1/users/register?tcChecked=true`
        : `/v1/users`;
    const response = yield request.post(
      requestUrl,
      requestData,
      {
        headers: {
          token
        }
      },
      false
    );
    yield response;

    switch (response.status) {
      case 201:
      case 202:
      case 200: {
        const message = userMessages[response.status];
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: message,
            type: 'success'
          }
        });
        yield put({ type: 'USER:DETAIL:UPDATE:SUCCESS', data });
        yield put(initialize('UserDetail', {}));

        // Clear whitelabelling and Redirect to login
        yield put({ type: 'TENANT:SETTINGS:RESET' });
        const redirect = '/validateotp';
        yield put({ type: 'LOGIN:TOKEN_REFRESH:FAIL', redirect });
        break;
      }
      case 204: {
        yield put({
          type: 'LOGIN:POST_LOGIN:SUCCESS',
          response,
          username: jwtDecode(token).email_id
        });
        break;
      }
      case 409:
        yield put({
          type: 'USER:DETAIL:UPDATE:FAIL',
          error: 'User already exists.'
        });
        break;
      case 412:
        yield put({
          type: 'USER:DETAIL:UPDATE:FAIL',
          error: response.body.msg
        });
        break;
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    switch (err.status) {
      case 502:
      case 503:
      case 504:
        err.message = serviceDownError('user');
        break;
      default:
        err.message = err.message;
        break;
    }
    yield put({
      type: 'NOTIFIER:NOTIFY',
      notification: {
        content: err.message,
        type: 'error'
      }
    });
    yield put({ type: 'USER:DETAIL:UPDATE:FAIL', error: err.message });
  }
}

let refreshTokenTimeout;
let warningRefreshTokenTimeout;

function warningRefreshTokenCallback() {
  if (warningRefreshTokenTimeout) {
    clearTimeout(warningRefreshTokenTimeout);
  }
  // validate session inactivity and send warning message to the user to refresh token
  validateInactivityAndSendWarning();
}

function refreshTokenCallback() {
  if (refreshTokenTimeout) {
    clearTimeout(refreshTokenTimeout);
  }

  // validate session inactivity and refresh token
  validateInactivityAndRefreshToken();
}

function validateInactivityAndSendWarning() {
  const config = registry.get('config');
  const store = registry.get('store');
  const storage = registry.get('storage');
  const currentTime = new Date().getTime();
  const lastReqTime = storage.getItem('lastRequestTime');
  if (currentTime - lastReqTime > config.login.minInactiveTime) {
    store.dispatch({ type: 'LOGIN:TOKEN:VALIDATE:REFRESH' });
  }
}

// refresh token before expiring
function validateInactivityAndRefreshToken() {
  const config = registry.get('config');
  const store = registry.get('store');
  const storage = registry.get('storage');

  const currentTime = new Date().getTime();
  const lastReqTime = storage.getItem('lastRequestTime');
  if (currentTime - lastReqTime <= config.login.maxInactiveTime) {
    const authToken = storage.getItem(config.login.token.storage.key);
    store.dispatch({ type: 'USER:TOKEN_REFRESH', authToken });
    warningRefreshTokenTimeout = setTimeout(
      warningRefreshTokenCallback,
      config.login.token.warning
    );
    refreshTokenTimeout = setTimeout(
      refreshTokenCallback,
      config.login.token.expire
    );
  } else {
    store.dispatch({ type: 'RECORDS:UNSAVED_DATA:RESET' });
    if (refreshTokenTimeout) {
      clearTimeout(refreshTokenTimeout);
    }
    if (warningRefreshTokenTimeout) {
      clearTimeout(warningRefreshTokenTimeout);
    }
    storage.removeItem(config.login.token.storage.key);
    storage.removeItem(config.login.user.storage.key);
    store.dispatch({ type: 'LOGIN:TOKEN_REFRESH:FAIL' });
  }
}

export function* logoutFlow(action) {
  const { redirectToLogout = true } = action;
  const store = registry.get('store');
  const config = registry.get('config');
  deleteCookie('X-AUTH-PRIVACYPERFECT');
  registry.get('storage').removeItem(config.login.token.storage.key);
  registry.get('storage').removeItem(config.login.user.storage.key);
  registry.get('storage').removeItem('whiteLabelling');
  registry.get('storage').removeItem('locale');
  yield put({ type: 'LOGIN:SET_LOGGED_USER', user: null });
  yield put({ type: 'LOGIN:LOGIN_REDIRECT_USE' });
  stopPricingPlanAPI();
  if (refreshTokenTimeout) {
    clearTimeout(refreshTokenTimeout);
  }
  if(redirectToLogout){
    store.dispatch(replaceRouter('/logout'));
  }
}

const deleteCookie = (name) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export function setLoggedUserFlow(action) {
  const config = registry.get('config');

  if (refreshTokenTimeout) {
    clearTimeout(refreshTokenTimeout);
  }
  if (action.user) {
    warningRefreshTokenTimeout = setTimeout(
      warningRefreshTokenCallback,
      config.login.token.warning
    );
    refreshTokenTimeout = setTimeout(
      refreshTokenCallback,
      config.login.token.expire
    );
  }
}

export function* reinviteUser(action) {
  const { token, type } = getQueryStrings();
  const request = registry.get('request');
  const store = registry.get('store');
  const config = registry.get('config');
  yield put({ type: 'USER:REINVITE:INIT' });
  try {
    const requestUrl = `/v1/users/reinvite?type=${type}`;
    const response = yield request.post(
      requestUrl,
      { email: action.email },
      {
        headers: { [config.login.token.httpHeader]: token }
      },
      false
    );
    switch (response.status) {
      case 204: {
        yield put({ type: 'USER:REINVITE:SUCCESS' });
        setTimeout(store.dispatch(replaceRouter('/user/reinvite-success')), 0);
        break;
      }
      case 400: {
        yield put({
          type: 'USER:REINVITE:FAIL',
          error: errortranslations.emailAddressMismatch
        });
        break;
      }
      case 403: {
        yield put({
          type: 'USER:REINVITE:FAIL',
          error: errortranslations.reinvitationUsed
        });
        break;
      }
      case 423: {
        yield put({
          type: 'USER:REINVITE:ATTEMPT:EXCEEDED',
          error: errortranslations.attemptsExceeded
        });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
    }
  } catch (err) {
    yield put({ type: 'USER:REINVITE:RESET:FAIL', error: err.message });
  }
}

export function* refreshToken() {
  yield put({ type: 'LOGIN:TOKEN:VALIDATE:INIT' });
  if (refreshTokenTimeout) {
    clearTimeout(refreshTokenTimeout);
  }
  const config = registry.get('config');
  const storage = registry.get('storage');
  const authToken = storage.getItem(config.login.token.storage.key);
  yield put({ type: 'USER:TOKEN_REFRESH', authToken });
}

const userMessages = {
  200: notificationtranslations.userCreated,
  204: notificationtranslations.userDetailUpdated
};

function getUserId(token) {
  const decoded = jwtDecode(token);
  const userId = decoded.user_id;
  return userId;
}
