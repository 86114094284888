import { getParameterValuesFromHash } from '@packages/utils/query-parameters';

export const getURLWithParams = (
  filterParams,
  position = 0,
  searchParams,
  requestType,
  rowCount,
  isStakeHolderType,
  unused,
  fieldElement
) => {
  const URL = window.location;
  const isPublic = URL.pathname.includes('public');
  const { registryId } = isPublic && getParameterValuesFromHash('/:registryId');
  const { formId } = isPublic ? getParameterValuesFromHash('/external-form/:formId') : {};
  let url = `/v1/masterdata/${requestType}?offset=${position}`;
  if (isStakeHolderType) {
    url = registryId
      ? `/v1/public/${registryId}/records/${requestType}?offset=${position}`
      : `/v1/records/${requestType}${
        requestType.includes('?') ? '&' : '?'
      }offset=${position}`;
  }
  if (requestType === 'documents') {
    url = `/v1/${requestType}?offset=${position}`;
  }
  if(formId && fieldElement) {
    const {field: {fieldType}, layoutFieldUniqueId} = fieldElement 
    url = `/v1/public/external-form/${formId}/field-type/${
      fieldType}?layoutFieldId=${layoutFieldUniqueId}&itemListId=${layoutFieldUniqueId}&offset=${position}`
  }
  if (rowCount) {
    url = `${url}&numberOfResults=${rowCount}`;
  }
  if (filterParams) {
    const { filteredOn, sortOn, sortOrder } = filterParams;
    filteredOn.keySeq().forEach((filterKey) => {
      const filteredOnItem = filteredOn.get(filterKey);
      if (filteredOnItem && filteredOnItem.length > 0) {
        filteredOnItem.forEach((x) => {
          // FIXME: Record types should be made lower case in BE
          const filterText = typeof x === 'object' ? x.props.defaultMessage : x;
          url = `${url}&filter=${getModifiedKey(
            filterKey,
            requestType
          )}%3D${encodeURIComponent(filterText)}`;
        });
      }
    });
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  if (searchParams) {
    const { searchText, sortOn, sortOrder, searchKey } = searchParams;
    url = searchText
      ? `${url}&search=${searchKey}=${encodeURIComponent(searchText)}`
      : url;
    url = sortOn ? `${url}&sortOn=${sortOn}&sortOrder=${sortOrder}` : url;
  }
  if (unused !== undefined) {
    url = unused === true ? `${url}&unused=true` : `${url}&unused=false`;
  }
  return url;
};

export const getModifiedKey = (key, requestType) =>
  requestType === 'datasources' && key === 'organisation'
    ? 'organisationId'
    : key;

export const updateDataItems = (
  items,
  type,
  currentId,
  source,
  modifiedItem
) => {
  const index = items.findIndex((item) => item.id === currentId);
  if (index !== -1) {
    const updatedItems =
      type === 'edit' && source !== 'records'
        ? items.set(index, modifiedItem)
        : items.remove(index);
    return updatedItems;
  }
  return items;
};

export const transformUserName = (item) => {
  if (item.lastName && item.firstName) {
    return `${item.lastName}, ${item.firstName}`;
  }
  return item.lastName || item.firstName || '';
};

export const roleAccessGroupMap = {
  HoldingAdministrator: 'a4bfc1ca-a466-4b11-ad0a-9dda827a2cc4',
  Coordinator: 'e6d33f35-9295-4b48-9ae9-7dd4ecbfb103',
  PartnerAdministrator: '9f2d4b1b-0adf-4195-a8d7-e17b4af9f67b'
};
