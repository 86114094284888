import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import Paper from '@material-ui/core/Paper';

import styles from '@packages/ui/styles';
import Filter from '@packages/features/generic-filter';
import {
  recordTranslations,
  typeTranslations
} from '@packages/utils/commontranslations';
import DivWrapper from '@packages/components/divWrapper';
import { singularTerms as uppercaseSingular } from '@packages/utils/uppercaseTranslations';
import {
  getTransformedRecordType,
  getTransformedFilter
} from '@packages/utils/common-utils';
import { getQueryStrings } from '@packages/utils/query-parameters';

import PrivacyRecordList from './components/list';
import ProcessingRecordList from './components/processings';
import AssessmentRecordList from './components/assessments';
import BreachRecordList from './components/breaches';
import PrivacyRecordsHeader from './components/header';
import TiaRecordList from './components/tia';
import {
  checkRecordPermissions,
  checkRecordTypeVisibility
} from './record-utils';
import CustomRecordList from './components/custom';
import CustomTemplates from './components/custom-templates';

export const PrivacyRecordListLayout = (props) => {
  const {
    onChangeFilter,
    registryDetails,
    unMount,
    onInit,
    isFetching,
    toggleLoader,
    recordTypeList,
    setFiltersForCustomList,
    locale,
    tenantLocale,
    onBeforeChangeFilter,
    currentFilter,
    showRisks,
    isPublic,
    currentFilterData,
    selectedRecords,
    userPermissions,
    isTemplateMode,
    toggleMode,
    isGlobal
  } = props;

  const {
    viewProcessing,
    createProcessing,
    viewPrivacyRecord,
    deleteProcessing,
    exportPdf,
    createPreDpia,
    viewPreDpia,
    createBreach,
    viewBreach,
    createTia,
    viewTia,
    deleteTia,
    deleteBreach,
    deleteAssessment,
    approveAssessment,
    approveBreach,
    approveProcessing
  } = userPermissions.toJS();

  const [registryInfo, setRegistryInfo] = useState(registryDetails);

  useEffect(() => {
    onInit();
    return () => {
      toggleMode();
      return unMount;
    };
  }, []);

  useEffect(() => {
    setRegistryInfo(registryDetails);
    if (registryDetails?.get('recordTypes')?.length === 1) {
      onChangeFilter(
        getTransformedRecordType(registryDetails?.get('recordTypes')[0])
      );
    }
  }, [registryDetails]);

  useEffect(() => {
    toggleLoader(isFetching);
  }, [isFetching]);

  useEffect(() => {
    if (recordTypeList?.length > 0) {
      setFiltersForCustomList(
        recordTypeList
          .filter(
            (item) =>
              // !defaultRecordLayouts.includes(item.uniqueId) &&
              item?.subRecordTypes?.length > 0
          )
          .map((item) => getTransformedFilter(item, locale, tenantLocale))
      );
    }
  }, [recordTypeList]);

  const handleChangeFilter = (filterName, data) => {
    if (onBeforeChangeFilter) {
      onBeforeChangeFilter(filterName);
    }
    if (onChangeFilter) {
      onChangeFilter(filterName, data);
    }
  };

  const getFilterMenuItems = useCallback(
    (isPublic) => {
      const accessibleRecords = [];

      if (viewPrivacyRecord || checkRecordTypeVisibility('all', registryInfo)) {
        accessibleRecords.push({
          key: 'all',
          label: uppercaseSingular('all'),
          enable: true,
          defaultFilter: 'all'
        });
      }

      if (isPublic) {
        if (checkRecordTypeVisibility('assessment', registryInfo)) {
          accessibleRecords.push({
            key: 'assessments',
            label: typeTranslations.assessments,
            enable: true,
            defaultFilter: 'dpia_assessments',
            subMenu: [{ key: 'dpia_assessments', label: typeTranslations.dpia }]
          });
        }
        if (checkRecordTypeVisibility('processing', registryInfo)) {
          accessibleRecords.push({
            key: 'processings',
            label: typeTranslations.processings,
            enable: true,
            defaultFilter: 'processings'
          });
        }
      } else {
        recordTypeList.forEach((record) => {
          if (record.subRecordTypes?.length)
            accessibleRecords.push({
              ...getTransformedFilter(record, locale, tenantLocale)
            });
        });
      }
      return accessibleRecords;
    },
    [viewPrivacyRecord, registryInfo, recordTypeList, locale, tenantLocale]
  );

  const isJobActive = isPublic && registryInfo.get('jobId');
  const hashURL = window.location.hash;
  const embed = getQueryStrings(hashURL).embed === 'true';
  const isPreview = getQueryStrings(hashURL).isPreview === 'true';
  const defaultListIds = [
    'all',
    'processings',
    'assessments',
    'dpia_assessments',
    'breaches',
    'breach_breaches',
    'tia_assessments',
    'tia_assessment',
    'processing_processings'
  ];

  const isDefaultProcessing =
    currentFilter === 'processing_processings' ||
    currentFilter === 'processings';

  const isDefaultDpia =
    currentFilter === 'dpia_assessments' || currentFilter === 'assessments';

  const isDefaultBreach =
    currentFilter === 'breach_breaches' || currentFilter === 'breaches';

  const currentRecordType = currentFilter.split('_')[1] || currentFilter;

  // Hide toggle while navigating from the record distribution widget.
  const showToggleSwitch =
    currentFilter === 'all' || !!currentFilter.split('_')[1];

  const showToggleButton = !isPublic && currentFilter !== 'all' && currentFilter !== 'tia_assessments' 
  && !isDefaultBreach &&!isDefaultDpia;

  return (
    <div style={{ position: 'relative', height: '90%' }}>
      {isJobActive && (
        <Paper
          style={{ ...styles.paperStyle, width: '100%', marginBottom: 20 }}
        >
          {recordTranslations.jobRunningNotification}
        </Paper>
      )}
      <DivWrapper style={{ width: 270, height: '100%' }}>
        <Filter
          filterMenu={getFilterMenuItems(isPublic)}
          currentFilter={currentFilter}
          onClick={handleChangeFilter}
          style={{ filterMenuWidthStyle: { paddingTop: '20px' } }}
        />
      </DivWrapper>
      <div
        style={{
          position: 'absolute',
          left: '255px',
          right: 25,
          top: isJobActive ? 25 : 10,
          bottom: 0,
          margin: '0px 25px'
        }}
      >
        <PrivacyRecordsHeader
          showRisks={showRisks}
          showBulkDeleteIcon={isDefaultProcessing && !!currentFilter.split('_')[1]}
          allowPDFDownload={currentFilter !== 'all' &&  !!currentFilter.split('_')[1] && exportPdf}
          selectedRecords={selectedRecords}
          showToggleButton={showToggleButton}
          toggleColumnName={['risks', 'controllers', 'executingEntities']}
          filterMenuData={currentFilterData}
          recordTypeList={recordTypeList}
          showRecordModeToggle={showToggleSwitch}
          disableModeSwitch={isFetching}
        />
        {isTemplateMode ? (
          <div style={{ height: '90%' }}>
            <CustomTemplates
              currentFilter={currentFilter}
              filterMenuData={currentFilterData}
              embed={embed}
            />
          </div>
        ) : (
          <div style={{ height: '90%' }}>
            {currentFilter === 'all' &&
              (!isPublic || checkRecordTypeVisibility('all', registryInfo)) && (
                <PrivacyRecordList
                  embed={embed}
                  isPublic={isPublic}
                  isPreview={isPreview}
                  isGlobal={isGlobal}
                />
            )}
            {currentRecordType === 'processings' && (
              <ProcessingRecordList
                isEditable={createProcessing}
                isViewable={viewProcessing}
                isDeletable={deleteProcessing}
                canApprove={approveProcessing}
                allowPDFDownload={exportPdf}
                embed={embed}
                isPublic={isPublic}
                isPreview={isPreview}
                currentFilterData={currentFilterData}
                isDefaultProcessing={isDefaultProcessing}
                isGlobal={isGlobal}
                showRisks={showRisks}
              />
            )}
            {isDefaultDpia && (
              <AssessmentRecordList
                isEditable={createPreDpia}
                isViewable={viewPreDpia}
                isDeletable={deleteAssessment}
                canApprove={approveAssessment}
                allowPDFDownload={exportPdf}
                embed={embed}
                isPublic={isPublic}
                isPreview={isPreview}
                recordLayoutId={currentFilterData?.recordLayoutId}
                isGlobal={isGlobal}
              />
            )}
            {isDefaultBreach && (
              <BreachRecordList
                isEditable={createBreach}
                isViewable={viewBreach}
                isDeletable={deleteBreach}
                canApprove={approveBreach}
                allowPDFDownload={exportPdf}
                isPublic={isPublic}
                recordLayoutId={currentFilterData?.recordLayoutId}
                isGlobal={isGlobal}
              />
            )}
            {currentFilter === 'tia_assessments' && (
              <TiaRecordList
                isEditable={createTia}
                isViewable={viewTia}
                isDeletable={deleteTia}
                canApprove={approveAssessment}
                allowPDFDownload={exportPdf}
                isGlobal={isGlobal}
              />
            )}
            {!defaultListIds.includes(currentFilter) &&
              !['document-records', 'processings'].includes(
                currentRecordType
              ) && (
                <CustomRecordList
                  isEditable={checkRecordPermissions(
                    userPermissions,
                    currentRecordType,
                    'edit'
                  )}
                  isViewable={checkRecordPermissions(
                    userPermissions,
                    currentRecordType,
                    'view'
                  )}
                  isDeletable={checkRecordPermissions(
                    userPermissions,
                    currentRecordType,
                    'delete'
                  )}
                  canApprove={checkRecordPermissions(
                    userPermissions,
                    currentRecordType,
                    'approve'
                  )}
                  allowPDFDownload={false}
                  currentFilter={currentFilter}
                  filterMenuData={currentFilterData}
                  recordParentType={currentRecordType}
                  showRisks={showRisks}
                />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

PrivacyRecordListLayout.propTypes = {
  currentFilter: PropTypes.string,
  showRisks: PropTypes.bool,
  userPermissions: PropTypes.instanceOf(Immutable.Map),
  onChangeFilter: PropTypes.func.isRequired,
  onBeforeChangeFilter: PropTypes.func,
  setFiltersForCustomList: PropTypes.func,
  onInit: PropTypes.func.isRequired,
  selectedRecords: PropTypes.arrayOf(PropTypes.string),
  isPublic: PropTypes.bool,
  registryDetails: PropTypes.instanceOf(Immutable.Map),
  toggleLoader: PropTypes.func,
  isFetching: PropTypes.bool,
  unMount: PropTypes.func.isRequired,
  recordTypeList: PropTypes.arrayOf(PropTypes.shape({})),
  locale: PropTypes.string,
  tenantLocale: PropTypes.string,
  currentFilterData: PropTypes.objectOf(PropTypes.shape({})),
  isTemplateMode: PropTypes.bool,
  toggleMode: PropTypes.func,
  isGlobal: PropTypes.bool
};

PrivacyRecordListLayout.defaultProps = {
  currentFilter: 'all',
  showRisks: false,
  userPermissions: Immutable.Map(),
  onBeforeChangeFilter: (e) => e,
  setFiltersForCustomList: (e) => e,
  selectedRecords: [],
  isPublic: false,
  registryDetails: Immutable.Map(),
  toggleLoader: (e) => e,
  isFetching: false,
  recordTypeList: [],
  locale: '',
  tenantLocale: '',
  currentFilterData: {},
  isTemplateMode: false,
  toggleMode: (e) => e,
  isGlobal: false
};

export default PrivacyRecordListLayout;
