import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Column } from 'fixed-data-table';
import { FormattedMessage, injectIntl } from 'react-intl';

import Note from '@packages/components/note';
import {
  ResponsiveTableWrapper,
  SortHeaderCell,
  DataCell,
  DateCell,
  ActionCell,
  InformationCell
} from '@packages/components/responsive-table';
import {
  statusValueMapper,
  typeValueMapper,
  commonTranslations,
  recordTranslations,
  typeTranslations,
  deleteRecord,
  customTranslation
} from '@packages/utils/commontranslations';
import styles from '@packages/ui/styles';
import {
  getContainerHeight,
  RECORDS_FETCH_LIMIT,
  getEmbedContainerHeight
} from '@packages/utils/common-utils';
import CustomDialog from '@packages/components/custom-dialog';
import {
  filterItem,
  getModifiedActions,
  statusColorCodes
} from '@packages/utils/record-utils';
import { getTranslatedLabel } from '@packages/features/tenant-configuration/tenantConfigUtils';
import { singularTerms } from '@packages/utils/uppercaseTranslations';

import NotificationDialogBox from '../../../privacy-record-detail/components/notificationDialog';
import {
  shouldHideMenuItem,
  getRecordStatusFilterValues,
  checkRecordTypeVisibility,
  checkRecordPermissions
} from '../../record-utils';

const actionItems = [
  {
    action: 'graphicalView',
    primaryText: recordTranslations.graphicalView
  },
  {
    action: 'addNote',
    primaryText: commonTranslations.addNote
  },
  {
    action: 'editNote',
    primaryText: commonTranslations.editNote
  },
  {
    action: 'viewNote',
    primaryText: commonTranslations.viewNote
  },
  {
    action: 'removeNote',
    primaryText: commonTranslations.removeNote
  },
  {
    action: 'changelog',
    primaryText: recordTranslations.viewChangeLog
  },
  {
    action: 'copyRecord',
    primaryText: recordTranslations.copyRecord
  }
];

export const PrivacyRecordList = (props, context) => {
  const {
    copyRecord,
    deletePrivacyRecord,
    embed,
    filterColumn,
    filteredOn,
    getNextData,
    intl,
    isFetching,
    isPreview,
    isPublic,
    isUpdating,
    items,
    locale,
    onChooseFilter,
    onMount,
    pageSearchText,
    position,
    pricingPlan,
    registryDetails,
    sortKey,
    sortOrder,
    tenantLocale,
    toggleLoader,
    updateNote,
    userPermissions,
    isGlobal
  } = props;

  const {
    viewBreach,
    viewCustom,
    viewPreDpia,
    viewProcessing,
    viewTia,
    createPrivacyRecord,
    deletePrivacyRecord: deletePermission
  } = userPermissions.toJS();

  const [isDelete, setIsDelete] = useState(false);
  const [recordTypeFilterValues, setRecordTypeFilterValues] = useState([]);
  const [openNotification, setOpenNotification] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [recordId, setRecordId] = useState(-1);
  const [open, setOpen] = useState(false);
  const [deleteNoteOpen, setDeleteNoteOpen] = useState(false);
  const [editNoteOpen, setEditNoteOpen] = useState(false);
  const [viewNoteOpen, setViewNoteOpen] = useState(false);
  const [recordType, setRecordType] = useState('processing');
  const [recordName, setRecordName] = useState('');
  const [showFilterIcon, setShowFilterIcon] = useState(false);
  const [content, setContent] = useState(undefined);
  const [addNoteOpen, setAddNoteOpen] = useState(false);
  const [isInSync, setIsInSync] = useState(false);

  useEffect(() => {
    onMount();
  }, [onMount]);

  useEffect(() => {
    if (isDelete) {
      getNextData(position, 1);
      setIsDelete(false);
    }
    toggleLoader(isUpdating || isFetching);
    setRecordTypeFilterValues(getRecordTypeFilterValues(registryDetails));
  }, [items.size, isFetching, isUpdating, registryDetails, userPermissions]);

  const getModifiedRecordList = (data, locale, tenantLocale) =>
    data.map((item) =>
      item.recordType === 'custom'
        ? {
          ...item,
          recordType: getTranslatedLabel(
            item.recordTypeData?.names,
            locale,
            tenantLocale
          )
        }
        : item
    );

  const getRecordTypeFilterValues = (registryData) => {
    const recordTypes = [];
    if (
      viewProcessing ||
      checkRecordTypeVisibility('processing', registryData)
    ) {
      recordTypes.push({
        label: typeTranslations.processing,
        value: 'processing'
      });
    }
    if (viewPreDpia || checkRecordTypeVisibility('assessment', registryData)) {
      recordTypes.push({
        label: typeTranslations.assessment,
        value: 'assessment'
      });
    }
    if (viewBreach || checkRecordTypeVisibility('breach', registryData)) {
      recordTypes.push({ label: typeTranslations.breach, value: 'breach' });
    }
    if (viewTia || checkRecordTypeVisibility('tia', registryData)) {
      recordTypes.push({
        label: typeTranslations.transferImpactAssessment,
        value: 'tia'
      });
    }
    if (viewCustom)
      recordTypes.push({ label: singularTerms('custom'), value: 'custom' });
    return recordTypes;
  };

  const getTableHeight = () => {
    if (embed) return window.innerHeight - 90; // header= 90
    else if (isPublic) return window.innerHeight - 190; // navbar height = 100 + header = 65 + whitespace = 25
    return false;
  };

  const handleEditRecordAction = (index) => {
    const { id, recordType, status, recordLayoutData, syncInfo } =
      items.get(index);
    if (
      status === 'record_status_requested' ||
      status === 'record_status_external_submitted' ||
      syncInfo?.isLibrarySyncEnabled
    ) {
      setOpenNotification(true);
      if(status === 'record_status_external_submitted') setIsSubmitted(true)
      setIsInSync(syncInfo?.isLibrarySyncEnabled);
    } else if (recordType === 'custom') {
      context.router.history.push(
        `/custom-record/${recordLayoutData.id}/${id}/edit`
      );
    } else {
      context.router.history.push(`/${recordType}/${id}/edit`);
    }
  };

  const handleDeleteRecordAction = (index) => {
    const { id, recordType, name, status, syncInfo } = items.get(index);
    if (
      (isPermissionHidden('approve') && (status === 'record_status_requested' 
        || status === 'record_status_external_submitted')) ||
      syncInfo?.isLibrarySyncEnabled
    ) {
      setOpenNotification(true);
      if(status === 'record_status_external_submitted') setIsSubmitted(true)
      setIsInSync(syncInfo?.isLibrarySyncEnabled);
    } else {
      handleOpen(id, recordType, name);
    }
  };

  const handleDeleteRecord = () => {
    setIsDelete(true);
    deletePrivacyRecord(recordId, recordType);
    handleClose();
  };

  const handleRowClick = (e, index) => {
    const { recordType, id, recordLayoutData, recordTypeData } =
      items.get(index);
    const isViewable = checkRecordPermissions(
      userPermissions,
      recordTypeData?.uniqueId,
      'view'
    );
    let url = '';
    if (recordType === 'custom') {
      context.router.history.push(
        `/custom-record/${recordLayoutData.id}/${id}/view`
      );
    } else url = `/${recordType}/${id}/view`;
    if (isViewable) {
      context.router.history.push(`${url}?from=privacyrecords`);
    } else if (isPublic) {
      const registryId = registryDetails.toJS().id;
      context.router.history.push(
        `/${registryId}${url}?from=publicList&embed=${embed}&isPreview=${isPreview}`
      );
    }
  };

  const handleScrollEnd = (scrollX, scrollY) => {
    if (
      items.size >= RECORDS_FETCH_LIMIT &&
      40 * items.size -
        (embed ? getEmbedContainerHeight() : getContainerHeight()) <=
        scrollY
    ) {
      getNextData(position);
    }
    setIsDelete(false);
  };

  const handleRecordActions = (actionButton, index, action) => {
    const { id, name, recordType, note } = items.get(index);
    if (action === 'edit') {
      handleEditRecordAction(index);
    } else if (action === 'delete') {
      handleDeleteRecordAction(index);
    } else if (action === 'graphicalView') {
      context.router.history.push(`/${recordType}/${id}/graph`);
    } else if (action === 'changelog') {
      context.router.history.push(`/${recordType}/${id}/revisions`);
    } else if (action === 'addNote') {
      handleAddNoteOpen(id, name, recordType);
    } else if (action === 'editNote') {
      handleEditNoteOpen(id, name, recordType, note);
    } else if (action === 'viewNote') {
      handleViewNoteOpen(name, note);
    } else if (action === 'removeNote') {
      handleDeleteNoteOpen(id, recordType, name);
    } else if (action === 'copyRecord') {
      handleCopyRecord(name, id, recordType);
    }
  };

  const handleCloseNotification = () => {
    setOpenNotification(false);
    setIsSubmitted(false)
  };

  const handleCopyRecord = (name, recordId, recordType) => {
    const copyOfName = {
      name: intl.formatMessage(customTranslation('copyOfName').props, {
        value: name
      })
    };
    copyRecord(
      copyOfName,
      recordId,
      recordType.toUpperCase(),
      locale,
      tenantLocale,
      intl.formatMessage,
      isGlobal
    );
  };

  const handleAddNoteOpen = (id, recordName, recordType) => {
    setAddNoteOpen(true);
    setRecordId(id);
    setRecordName(recordName);
    setRecordType(recordType);
  };

  const handleEditNoteOpen = (id, recordName, recordType, content) => {
    setEditNoteOpen(true);
    setRecordId(id);
    setRecordName(recordName);
    setRecordType(recordType);
    setContent(content);
  };

  const handleViewNote = (evt, rowIndex) => {
    evt.stopPropagation();

    const { name, note } = items.get(rowIndex);
    handleViewNoteOpen(name, note);
  };

  const handleViewNoteOpen = (name, note) => {
    setViewNoteOpen(true);
    setRecordName(name);
    setContent(note);
  };

  const handleDeleteNoteOpen = (id, recordType, recordName) => {
    setDeleteNoteOpen(true);
    setRecordId(id);
    setRecordType(recordType);
    setRecordName(recordName);
    setContent('');
  };

  const handleChange = (event) => {
    setContent(event.target.value);
  };

  // Handle note value changes
  const handleEditNote = (event, note) => {
    const noteContent = note || content;
    if (
      recordId !== -1 &&
      (((addNoteOpen || editNoteOpen) && noteContent) || deleteNoteOpen)
    ) {
      updateNote(recordId, recordType, 'note', noteContent.trim(), recordName);
    }
    requestNoteClose();
  };

  const handleMouseHover = (event, index) => {
    setCurrentIndex(index);
  };

  const requestNoteClose = () => {
    setEditNoteOpen(false);
    setAddNoteOpen(false);
    setViewNoteOpen(false);
    setDeleteNoteOpen(false);
    setRecordId(-1);
    setContent(undefined);
  };

  const filterData = (filterKey, filterParams) => {
    onChooseFilter({ ...filterParams, filterKey });
  };

  const handleClose = () => {
    setOpen(false);
    setRecordId(-1);
  };

  const handleOpen = (recordId, recordType, recordName) => {
    setRecordId(recordId);
    setRecordType(recordType);
    setRecordName(recordName);
    setOpen(true);
  };

  const handleFilterIcon = (show) => {
    setShowFilterIcon(show);
  };

  const handleMenuHidden = (menu, rowIndex, action) => {
    const { recordTypeData } = items.get(rowIndex);
    const { uniqueId } = recordTypeData || {};

    const hasEditPermission = checkRecordPermissions(
      userPermissions,
      uniqueId,
      'edit'
    );

    const hasViewPermission = checkRecordPermissions(
      userPermissions,
      uniqueId,
      'view'
    );

    if (
      uniqueId === 'custom' &&
      ['changelog', 'graphicalView'].includes(action)
    ) {
      return true;
    }

    return shouldHideMenuItem(
      action,
      rowIndex,
      items,
      hasEditPermission,
      hasViewPermission
    );
  };

  const handleMenuDisabled = (menu, rowIndex, action) => {
    const item = items.get(rowIndex);
    return (
      action === 'copyRecord' && item.recordLayoutData?.status === 'Inactive'
    );
  };

  const modifiedAction = getModifiedActions(pricingPlan, actionItems);

  const recordStatusFilterValues = getRecordStatusFilterValues(registryDetails);
  const modifiedItems = getModifiedRecordList(items, locale, tenantLocale);

  const isPermissionHidden = (permissionType) =>
    !(
      currentIndex !== -1 &&
      checkRecordPermissions(
        userPermissions,
        items.get(currentIndex)?.recordTypeData?.uniqueId,
        permissionType
      )
    );

  const isEditHidden = isPermissionHidden('edit');
  const isDeleteHidden = isPermissionHidden('delete');

  return (
    <div>
      {!isFetching && items.size === 0 && (
        <div style={styles.tableNoContentStyle}>
          {commonTranslations.NoData}
        </div>
      )}
      <ResponsiveTableWrapper
        id="record_responsive_table"
        showActionSelector={!isPublic}
        actionChooserStyle={styles.actionChooserStyle}
        rowHeight={40}
        headerHeight={45}
        rowsCount={items.size}
        onRowClick={handleRowClick}
        actionItems={modifiedAction}
        onActionHandler={handleRecordActions}
        isHidden={handleMenuHidden}
        onScrollEnd={handleScrollEnd}
        actionChooserClass="actionChooserClass"
        onRowMouseEnter={handleMouseHover}
        containerHeight={getTableHeight()}
        containerMaxHeight={getTableHeight()}
        isDisabled={handleMenuDisabled}
        {...props}
      >
        <Column
          id="privacyRecordNumber"
          columnKey="privacyRecordNumber"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              onChooseFilter={filterData}
              pageSearchStyle={{ width: '100px' }}
              searchText={pageSearchText.get('privacyRecordNumber')}
            >
              {recordTranslations.recordId}
            </SortHeaderCell>
          }
          cell={<DataCell items={items} />}
          width={160}
        />
        <Column
          id="name"
          columnKey="name"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              searchText={pageSearchText.get('name')}
              onChooseFilter={filterData}
              tooltip={true}
            >
              {commonTranslations.name}
            </SortHeaderCell>
          }
          cell={<DataCell items={items} />}
          flexGrow={1}
          width={150}
        />
        <Column
          id="recordType"
          columnKey="recordType"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              enableFilter={true}
              filterColumn={filterColumn && filterColumn.recordType}
              filterValues={recordTypeFilterValues}
              filteredOn={filteredOn}
              onChooseFilter={filterData}
            >
              {recordTranslations.type}
            </SortHeaderCell>
          }
          cell={<DataCell items={modifiedItems} mapper={typeValueMapper} />}
          width={170}
        />
        <Column
          id="status"
          columnKey="status"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              enableFilter={true}
              filterColumn={filterColumn && filterColumn.status}
              filterValues={recordStatusFilterValues}
              filteredOn={filteredOn}
              onChooseFilter={filterData}
              filterItem={filterItem}
            >
              {recordTranslations.status}
            </SortHeaderCell>
          }
          cell={
            <DataCell
              items={items}
              mapper={statusValueMapper}
              styleMapper={statusColorCodes}
            />
          }
          width={170}
          visible={!isPublic}
        />
        <Column
          id="lastChanged"
          columnKey="lastChanged"
          header={
            <SortHeaderCell
              handleFilterIcon={handleFilterIcon}
              showFilterIcon={showFilterIcon}
              enableSorting={true}
              sortKey={sortKey}
              sortOrder={sortOrder}
              onChooseFilter={filterData}
            >
              <FormattedMessage
                id="PrivacyRecordList.lastChanged"
                description="Privacy Record List Column LastChanged"
                defaultMessage="Last Changed"
              />
            </SortHeaderCell>
          }
          cell={
            <DateCell dateFormat="DD MMM YYYY" items={items} locale={locale} />
          }
          width={150}
          visible={!isPublic}
        />
        <Column
          id="action-cell"
          columnKey="action-cell"
          visible={createPrivacyRecord || deletePermission}
          cell={
            <ActionCell
              currentIndex={currentIndex}
              cellStyle={{ float: 'right' }}
              handleEdit={handleEditRecordAction}
              handleDelete={handleDeleteRecordAction}
              isEditHidden={isEditHidden}
              isDeleteHidden={isDeleteHidden}
            />
          }
          width={100}
        />
        <Column
          id="note"
          columnKey="note"
          align="center"
          cell={
            <InformationCell
              items={items}
              handleOnClick={handleViewNote}
              className="informationClass"
            />
          }
          width={30}
        />
      </ResponsiveTableWrapper>
      <CustomDialog
        title={commonTranslations.deletePrivacyRecord}
        id="delete_record_dialog"
        show={open}
        proceed={handleDeleteRecord}
        cancel={handleClose}
        content={deleteRecord(recordName)}
      />
      {(editNoteOpen || addNoteOpen) && (
        <Note
          id="edit-note"
          content={addNoteOpen ? null : content}
          onChange={handleChange}
          isEdit={true}
          headerLabel={
            addNoteOpen
              ? commonTranslations.addNoteHeader
              : commonTranslations.editNoteHeader
          }
          entityName={recordName}
          open={editNoteOpen || addNoteOpen}
          handleEditNote={handleEditNote}
          close={requestNoteClose}
        />
      )}
      {viewNoteOpen && (
        <Note
          id="view-note"
          content={content}
          isEdit={false}
          headerLabel={commonTranslations.viewNoteHeader}
          entityName={recordName}
          open={viewNoteOpen}
          close={requestNoteClose}
        />
      )}
      <CustomDialog
        id="delete_note_dialog"
        show={deleteNoteOpen}
        proceed={(e) => handleEditNote(e, '')}
        cancel={requestNoteClose}
        content={recordTranslations.deleteNote}
      />
      <NotificationDialogBox
        open={openNotification}
        cancel={handleCloseNotification}
        message={isSubmitted ? recordTranslations.editSubmittedNotification : '' 
                         || (isInSync ? recordTranslations.itemIsInSync : '')}
      />
    </div>
  );
};

PrivacyRecordList.propTypes = {
  locale: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  isViewable: PropTypes.bool,
  position: PropTypes.number,
  items: PropTypes.instanceOf(Immutable.List),
  isFetching: PropTypes.bool,
  onMount: PropTypes.func,
  deletePrivacyRecord: PropTypes.func,
  updateNote: PropTypes.func,
  onChooseFilter: PropTypes.func,
  onSearch: PropTypes.func,
  getNextData: PropTypes.func,
  sortKey: PropTypes.string,
  sortOrder: PropTypes.string,
  pageSearchText: PropTypes.instanceOf(Immutable.Map),
  searchText: PropTypes.instanceOf(Immutable.Map),
  filteredOn: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  filterColumn: PropTypes.objectOf(PropTypes.string),
  searchItems: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  copyRecord: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  toggleLoader: PropTypes.func,
  pricingPlan: PropTypes.shape({}),
  createEditPermission: PropTypes.instanceOf(Immutable.Map),
  isPublic: PropTypes.bool,
  registryDetails: PropTypes.instanceOf(Immutable.Map),
  embed: PropTypes.bool,
  isPreview: PropTypes.bool,
  userPermissions: PropTypes.instanceOf(Immutable.Map),
  tenantLocale: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func
  }),
  isGlobal: PropTypes.bool
};

PrivacyRecordList.defaultProps = {
  isFetching: false,
  isEditable: false,
  isViewable: false,
  position: 0,
  items: Immutable.List(),
  onMount: (e) => e,
  deletePrivacyRecord: (e) => e,
  updateNote: (e) => e,
  onChooseFilter: (e) => e,
  onSearch: (e) => e,
  getNextData: (e) => e,
  filteredOn: {},
  filterColumn: {},
  pageSearchText: Immutable.Map(),
  sortKey: '',
  sortOrder: '',
  searchText: Immutable.Map(),
  searchItems: {},
  isUpdating: false,
  toggleLoader: (e) => e,
  pricingPlan: Immutable.Map(),
  createEditPermission: Immutable.Map(),
  isPublic: false,
  registryDetails: Immutable.Map(),
  embed: false,
  isPreview: false,
  userPermissions: Immutable.Map(),
  tenantLocale: '',
  intl: {
    formatMessage: (e) => e
  },
  isGlobal: false
};

PrivacyRecordList.contextTypes = {
  router: PropTypes.shape({}).isRequired
};
export default injectIntl(PrivacyRecordList);
