/* eslint-disable no-param-reassign */
import {
  sortItems,
  supportedJurisdictions
} from '@packages/utils/common-utils';
import { recordTranslations } from '@packages/utils/commontranslations';
import { singularTerms } from '@packages/utils/uppercaseTranslations';
import countryTranslations from '@packages/utils/countryTranslations';
import {
  getDiffContent,
  getDefaultRenderData,
  addKeyToRetentionTerm,
  numOfDataItemsMapping
} from '../../common-utils';
import {
  getDefaultRequestParams,
  transformDefaultRequestData,
  transformDefaultResponseData
} from '../../saga-utils';

export const getUpdatedProperty = (action) => {
  const { property } = action;

  const renderData = getDefaultRenderData(action);

  switch (property) {
    case 'numOfDataSubjects':
    case 'processingCategories': {
      return {
        renderData
      };
    }
    default:
      return getDefaultRequestParams(action, renderData);
  }
};

export const transformRequestData = (requestData, isGlobal) => {
  const reqData = Object.assign({}, requestData);
  if (reqData.retentionTerms) {
    reqData.retentionTerms = reqData.retentionTerms.map((term) => ({
      period: term.period || null,
      offset: term.offset || null,
      note: term.note || null
    }));
  }

  if (reqData.dataProtection && reqData.dataProtection.id) {
    const { id, contactDetails } = reqData.dataProtection;
    if (contactDetails.length) {
      const dpo = contactDetails.map((item) =>
        item.value
          ? { ...item.value, value: { id } }
          : { ...item, value: { id } }
      );
      reqData.dpo = dpo;
    } else reqData.dpo = [];
  }

  if (reqData.links) {
    reqData.links = reqData.links.map((link) => {
      const retentionTerms = link.value.retentionTerms.map(
        (term) => term.format || term
      );
      return {
        ...link,
        value: {
          ...link.value,
          retentionTerms
        }
      };
    });
  }

  if (reqData.appiInternationalTransferCountries?.length) {
    reqData.appiInternationalTransferCountries =
      reqData.appiInternationalTransferCountries.map((country) => ({
        value: {
          id:
            country.value.countryCode || country.countryCode || country.value.id
        }
      }));
  }

  if (reqData.piplInternationalTransferCountries?.length) {
    reqData.piplInternationalTransferCountries =
      reqData.piplInternationalTransferCountries.map((country) => ({
        value: {
          id:
            country.value.countryCode || country.countryCode || country.value.id
        }
      }));
  }

  if (reqData.lgpdInternationalTransferCountries?.length) {
    reqData.lgpdInternationalTransferCountries =
      reqData.lgpdInternationalTransferCountries.map((country) => ({
        value: {
          id:
            country.value.countryCode || country.countryCode || country.value.id
        }
      }));
  }

  if (
    requestData.specificNumOfDataSubjects &&
    reqData.numOfDataSubjects === -2
  ) {
    reqData.numOfDataSubjects = {
      min: reqData.specificNumOfDataSubjects,
      max: reqData.specificNumOfDataSubjects
    };
    delete reqData.specificNumOfDataSubjects;
  } else if (reqData.numOfDataSubjects === undefined) {
    reqData.numOfDataSubjects = numOfDataItemsMapping['-1'];
  } else {
    reqData.numOfDataSubjects =
      reqData.numOfDataSubjects.min !== undefined
        ? reqData.numOfDataSubjects
        : numOfDataItemsMapping[reqData.numOfDataSubjects];
  }

  if (reqData.jurisdictionCountries?.length) {
    reqData.jurisdictionCountries = reqData.jurisdictionCountries.map(
      (country) => ({
        value: {
          id:
            country.value.countryCode || country.countryCode || country.value.id
        }
      })
    );
  }

  delete reqData.dataProtection;
  delete reqData.mitigatingMeasures;
  delete reqData.processingRisks;
  delete reqData.jurisdiction;
  return transformDefaultRequestData(reqData, isGlobal); // Transform records request data
};

export function* transformResponseData(
  responseData,
  isFromTemplate,
  currentUser,
  formatMessage
) {
  const respData = Object.assign({}, responseData);

  if (respData.processingCategories) {
    const sortedItems = sortItems(respData.processingCategories, 'ASC', 'name');
    respData.processingCategories = sortedItems.map((processingCategory) => {
      const { item, change } = getDiffContent(processingCategory);
      return {
        change,
        ...item,
        value: {
          ...item.value,
          key: `${item.value.name}`
        }
      };
    });
  }

  if (respData.retentionTerms) {
    respData.retentionTerms = respData.retentionTerms.map((term) => {
      const { item, change } = getDiffContent(term);
      return {
        ...addKeyToRetentionTerm(item, formatMessage),
        change
      };
    });
  }

  if (respData.links) {
    respData.links = respData.links.map((link) => {
      const { item, change } = getDiffContent(link);
      const retentionTerms = item.value.retentionTerms.map((term) => {
        const retentionItem = getDiffContent(term).item;
        return {
          change,
          ...addKeyToRetentionTerm(retentionItem, formatMessage)
        };
      });
      return {
        change,
        ...item,
        value: {
          ...item.value,
          retentionTerms
        }
      };
    });
  }

  if (respData.appiInternationalTransferCountries?.length) {
    respData.appiInternationalTransferCountries =
      respData.appiInternationalTransferCountries.map((country) => ({
        value: {
          ...country.value,
          countryCode: country.value.id,
          key: formatMessage(countryTranslations[country.value.id].props)
        }
      }));
  }

  if (respData.piplInternationalTransferCountries?.length) {
    respData.piplInternationalTransferCountries =
      respData.piplInternationalTransferCountries.map((country) => ({
        value: {
          ...country.value,
          countryCode: country.value.id,
          key: formatMessage(countryTranslations[country.value.id].props)
        }
      }));
  }

  if (respData.appiThirdPartyDataReceivedFrom) {
    const sortedItems = sortItems(
      respData.appiThirdPartyDataReceivedFrom,
      'ASC',
      'name'
    );
    respData.appiThirdPartyDataReceivedFrom = sortedItems.map(
      (appiThirdPartyDataReceivedFrom) => {
        const { item, change } = getDiffContent(appiThirdPartyDataReceivedFrom);
        return {
          change,
          ...item,
          value: {
            ...item.value,
            key: `${item.value.name} (${item.value.country.id})`
          }
        };
      }
    );
  }

  const getDpodata = (item) => {
    const data = item.name || item.email || item.workPhone;
    const message = recordTranslations.noDpoName;
    const addlkey = item.name ? '' : formatMessage(message.props);
    return { data, addlkey };
  };

  if (respData.dpo) {
    if (respData.dpo.length) {
      const { id } = respData.dpo[0].value;
      const transformedDpo = [];
      respData.dpo.forEach((item) => {
        const { data, addlkey } =
          item.contactMemberType === 'DataProtectionOfficer'
            ? getDpodata(item.value.dataProtectionOfficer)
            : getDpodata(item.value.representative);
        const type =
          item.contactMemberType === 'DataProtectionOfficer'
            ? formatMessage(recordTranslations.dataProtectionOfficer.props)
            : formatMessage(recordTranslations.representative.props);
        if (data) {
          transformedDpo.push({
            value: {
              contactMemberType: item.contactMemberType,
              type,
              name: data,
              key: addlkey === '' ? data : `${data} (${addlkey})`
            }
          });
        }
      });
      respData.dataProtection = {
        id: transformedDpo.length ? id : null,
        contactDetails: transformedDpo
      };
    } else respData.dataProtection = {};
  }

  return yield transformDefaultResponseData(
    respData,
    currentUser,
    isFromTemplate
  ); // Transform records response data
}

const linkGroupItems = [
  'dataSubjectCategories',
  'personalDataCategories',
  'personalDataItems',
  'dataSources',
  'retentionTerms',
  'purposes',
  'purposesOfTransfer',
  'technicalSecurityMeasures',
  'organisationalSecurityMeasures'
];

export const handleRemoveGroupItems = (currentData) => {
  let groupsToRemove = [...currentData.links];
  groupsToRemove = groupsToRemove.filter(
    (group) =>
      group.value.purposesOfTransfer &&
      group.value.purposesOfTransfer.length > 0
  );
  const associatedItems = { ...currentData };
  groupsToRemove.forEach((removedGroup) => {
    linkGroupItems.forEach((key) => {
      if (key !== 'retentionTerms') {
        associatedItems[key] = associatedItems[key].filter(
          (item) =>
            removedGroup.value[key].findIndex(
              (data) => data.value.id === item.value.id
            ) === -1
        );
      } else {
        associatedItems[key] = associatedItems[key].filter(
          (item) =>
            removedGroup.value[key].findIndex((data) => {
              if (data.offset && data.period && item.offset && item.period) {
                return (
                  data.offset.id === item.offset.id &&
                  data.period === item.period
                );
              } else if (data.offset && item.offset)
                return data.offset.id === item.offset.id;
              return data.period === item.period;
            }) === -1
        );
      }
    });
  });
  return associatedItems;
};

export const prepareLinkUpdateForItemRemoval = (link) => {
  const linkToUpdate = { ...link };
  linkGroupItems.forEach((key) => {
    const items = linkToUpdate[key];
    const selectedItems =
      items &&
      items.map((item) => {
        const itemValue = item.value || item;
        return itemValue.id || itemValue;
      });
    if (selectedItems && selectedItems.length > 0) {
      linkToUpdate[key] = selectedItems;
    }
  });
  const {
    _rev,
    personalDataCategories,
    dataSubjectCategories,
    purposes,
    purposesOfTransfer,
    personalDataItems,
    dataSources,
    retentionTerms,
    technicalSecurityMeasures,
    organisationalSecurityMeasures
  } = linkToUpdate;
  return {
    personalDataCategories,
    dataSubjectCategories,
    personalDataItems,
    dataSources,
    retentionTerms,
    purposesOfTransfer,
    purposes,
    technicalSecurityMeasures,
    organisationalSecurityMeasures,
    _rev
  };
};

export const validateLinkItems = (linkObj) =>
  linkGroupItems.some((key) => linkObj[key].length > 0);

export const appiPersonalInfoTypeList = [
  {
    label: recordTranslations.retainedPersonalInfo,
    value: 'personal_information_retained',
    helpNote: 'aPPIRetainedPersonalData'
  },
  {
    label: recordTranslations.anonymouslyProcessedInfo,
    value: 'personal_information_anonymous_processed',
    helpNote: 'aPPIAnonymouslyProcessedInfo'
  },
  {
    label: recordTranslations.specialCarePersonalInfo,
    value: 'personal_information_special_care_required',
    helpNote: 'aPPISpecialCarePersonalInformation'
  },
  {
    label: recordTranslations.pseudonymouslyProcessedInfo,
    value: 'personal_information_pseudonymously_processed',
    helpNote: 'aPPIPseudoProcessedInfo'
  },
  {
    label: recordTranslations.personallyReferableInfo,
    value: 'personal_information_referrable',
    helpNote: 'aPPIPersonallyReferableInfo'
  },
  {
    label: recordTranslations.personalNumber,
    value: 'personal_information_my_number',
    helpNote: 'aPPIPersonalNumber'
  }
];
let processorList = [];
export const getAllProcessors = (itemList, resetList) => {
  if (resetList) processorList = [];
  itemList.forEach((item) => {
    if (item.value && item.value.subProcessors.length > 0) {
      processorList.push(item);
      getAllProcessors(item.value.subProcessors);
    } else if (item.children) {
      processorList.push(item);
      getAllProcessors(item.children);
    } else processorList.push(item);
  });
  return processorList;
};

export const jurisdictionProcessingRisks = (type, jurisdiction, values) => ({
  field: type,
  riskMessage: {
    key: `privacyRecord.${
      jurisdiction === 'gdpr' ? 'risks' : `${jurisdiction}Risks`
    }.${type}`,
    values: values ? [values] : []
  },
  isIgnored: false
});

export const getJurisdictionRisks = (risks, type, jurisdictions) => {
  // need to update gdpr risks when eu idpr is checked
  const riskJurisdictions = [...jurisdictions];
  const idprJurisdiction = jurisdictions.find((item) => item.data === 'IDPR');
  if (
    idprJurisdiction &&
    !riskJurisdictions.some((item) => item.data === 'GDPR')
  ) {
    riskJurisdictions.push({ ...idprJurisdiction, data: 'GDPR' });
  }

  riskJurisdictions.forEach((jurisdiction) => {
    const jurisdictionName = jurisdiction.data.toLowerCase();
    const riskList = risks[jurisdictionName];
    const { riskFields } = supportedJurisdictions.filter(
      (jur) => jur.data === jurisdiction.data
    )[0];
    const index = riskList?.findIndex((item) => item?.field === type);
    if (jurisdiction.validation && index !== -1) {
      risks[jurisdictionName]?.splice(index, 1);
    } else if (
      !jurisdiction.validation &&
      index === -1 &&
      riskFields.includes(type)
    ) {
      if (jurisdiction.values && jurisdiction.values.length) {
        jurisdiction.values.forEach((value) => {
          risks[jurisdictionName].push(
            jurisdictionProcessingRisks(type, jurisdictionName, value.name)
          );
        });
      } else {
        risks[jurisdictionName].push(
          jurisdictionProcessingRisks(type, jurisdictionName)
        );
      }
    }
  });
  return risks;
};

export const notificationMethods = [
  { label: singularTerms('emailLabel'), value: 'Email' },
  { label: recordTranslations.facsimile, value: 'Facsimile' },
  { label: recordTranslations.inPerson, value: 'InPerson' },
  { label: recordTranslations.onlineFormOrPost, value: 'OnlineFormOrPost' },
  { label: recordTranslations.post, value: 'Post' },
  { label: recordTranslations.telephone, value: 'Telephone' },
  { label: singularTerms('other'), value: 'Other' }
];

export const processingActivityRole = [
  { label: recordTranslations.Processor, value: 'Processor' },
  { label: singularTerms('subProcessor'), value: 'SubProcessor' },
  { label: recordTranslations.Controller, value: 'Controller' },
  { label: recordTranslations.jointController, value: 'JointController' },
  { label: singularTerms('other'), value: 'Other' }
];

export const regulatoryProcessStatus = [
  { label: recordTranslations.notRequired, value: 'NotRequired' },
  {
    label: recordTranslations.requiredButNotComplete,
    value: 'RequiredButNotComplete'
  },
  {
    label: recordTranslations.requiredAndCompleted,
    value: 'RequiredAndCompleted'
  }
];

export const notificationMapper = (value) =>
  notificationMethods.find((data) => data.value === value).label;

export const processingActivityRoleMapper = (value) =>
  processingActivityRole.find((data) => data.value === value)?.label;

export const regulatoryProcessStatusMapper = (value) =>
  regulatoryProcessStatus.find((data) => data.value === value)?.label;

export const complianceAndRiskSubChapters = [
  'Gdpr',
  'Lgpd',
  'Ccpa',
  'Appi',
  'Pipl',
  'Pdp',
  'Sgpdpa',
  'Fdpl',
  'Ukgdpr',
  'Pdpa'
];
