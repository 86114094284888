import login from '@packages/features/login/reducers';
import mfa from '@packages/features/login/components/mfa/reducers';
import ssosettings from '@packages/features/settings/components/security/ssosettings/reducer';
import banner from '@packages/features/admin/components/message-banner/reducers';

import privacyRecords from '@packages/features/privacy-records/reducers';

import notifications from '@packages/features/message-center/components/notifications/reducers/index';
import tasks from '@packages/features/message-center/components/task/reducers/index';
import messages from '@packages/features/message-center/components/messages/reducers/index';
import messageRecipients from '@packages/features/message-center/components/messages/components/recipient-selector/reducers';
import messageAttachments from '@packages/features/message-center/components/messages/components/records-selector/reducers';
import messageCenter from '@packages/features/message-center/reducer';

import tenantusers from '@packages/features/privacy-record-detail/components/access-rights-selector/reducers';
import assessment from '@packages/features/privacy-record-detail/assessments/reducers';
import processing from '@packages/features/privacy-record-detail/processings/reducers';
import breach from '@packages/features/privacy-record-detail/breaches/reducers';
import tia from '@packages/features/privacy-record-detail/tia/reducers';
import processorHierarchy from '@packages/features/privacy-record-detail/components/processor-hierarchy/reducers';
import documents from '@packages/features/privacy-record-detail/documents/reducers';
import simpleEntities from '@packages/features/privacy-record-detail/components/simple-entity-selector/reducers';
import legalEntitySelector from '@packages/features/privacy-record-detail/components/legal-entity-selector/reducers';
import processingCategories from '@packages/features/privacy-record-detail/components/processing-category-selector/reducers';
import dataRecipientCategories from '@packages/features/privacy-record-detail/components/data-recipient-category-selector/reducers';
import dataSources from '@packages/features/privacy-record-detail/components/datasource-selector/reducers';
import retentionTerms from '@packages/features/privacy-record-detail/components/retention-term-selector/reducers';
import personalDataItems from '@packages/features/privacy-record-detail/components/personal-data-item-selector/reducers';
import personalItemCategories from '@packages/features/privacy-record-detail/components/personal-data-category-selector/reducers';
import dataSubjectCategories from '@packages/features/privacy-record-detail/components/datasubject-category-selector/reducers';
import processingGrounds from '@packages/features/privacy-record-detail/components/processing-grounds/reducers';
import documentRecordSelector from '@packages/features/privacy-record-detail/components/document-record-selector/reducers';
import transferGrounds from '@packages/features/privacy-record-detail/components/transfer-grounds/reducers';
import breachGrounds from '@packages/features/privacy-record-detail/components/breach-grounds/reducers';
import supervisoryAuthorities from '@packages/features/privacy-record-detail/components/supervisory-authority-selector/reducers';
import recordRevisions from '@packages/features/change-log/reducers';
import processingDiff from '@packages/features/privacy-record-detail/processings/diff/reducers';
import assessmentDiff from '@packages/features/privacy-record-detail/assessments/diff/reducers';
import breachDiff from '@packages/features/privacy-record-detail/breaches/diff/reducers';
import tiaDiff from '@packages/features/privacy-record-detail/tia/diff/reducers';
import documentDiff from '@packages/features/privacy-record-detail/documents/diff/reducers';
import attachments from '@packages/features/privacy-record-detail/components/attachment-selector/reducers';
import masterDataTags from '@packages/features/privacy-record-detail/components/tag-selector/reducer';
import records from '@packages/features/privacy-record-detail/components/record-selector/reducers';

import legalEntities from '@packages/features/entity-selector/reducers';

import home from '@packages/features/home/reducer';
import globalDataUpdates from '@packages/features/home/components/data-updates/reducer';

import environment from '@packages/features/environment/reducers';
import organisationUser from '@packages/features/environment/components/organisation-users/reducers';
import environmentReducer from '@packages/features/environment/reducer';
import graphItems from '@packages/features/environment/master-data-graph-view/reducer';
import bulkImport from '@packages/features/environment/master-data-import/reducer';
import externalOrganisations from '@packages/features/environment/components/organisations/components/external-organisations/reducers';
import tenantOrganisations from '@packages/features/environment/components/organisations/components/tenant-organisations/reducers';

import organisationEntities from '@packages/features/organisation-entities/reducers';

import fieldVisibilitySettings from '@packages/features/settings/components/fieldVisibility/reducers';
import settings from '@packages/features/settings/reducer';
import dsrSettings from '@packages/features/settings/components/dsrSettings/reducers';
import passwordSettings from '@packages/features/settings/components/security/passwordSettings/reducer';
import apiTokens from '@packages/features/settings/components/integration/reducers';
import publicRegistry from '@packages/features/settings/components/integration/public-registry/reducers';
import recordPortal from '@packages/features/settings/components/integration/record-portal/reducers';
import customSettings from '@packages/features/settings/components/custom/reducers';

import pricing from '@packages/features/admin/components/pricing/reducers';
import admin from '@packages/features/admin/reducers';
import tenants from '@packages/features/admin/components/tenants/reducers';
import tenantPartners from '@packages/features/admin/components/tenants/components/detail/components/partner-selector/reducers';
import partners from '@packages/features/admin/components/partners/reducers';
import holdingTenants from '@packages/features/admin/components/holding-tenants/reducer';

import dashboard from '@packages/features/dashboard/reducers';

import dsr from '@packages/features/dsr/reducer';
import dsrList from '@packages/features/dsr/components/dsr-list/reducer';
import requesterList from '@packages/features/dsr/components/requester-list/reducer';
import dsrForms from '@packages/features/dsr/components/forms/reducer';
import dsrDetail from '@packages/features/dsr-detail/reducers';
import dsrRequesters from '@packages/features/dsr-detail/components/requester-id-selector/reducer';
import publicDsr from '@packages/features/dsr-public-form/reducer';

import controllers from '@packages/features/reports/components/selectors/controller/reducers';
import dataExporters from '@packages/features/reports/components/selectors/data-exporters/reducers';
import dataImporters from '@packages/features/reports/components/selectors/data-importers/reducers';
import onwardTransfers from '@packages/features/reports/components/selectors/onward-transfers/reducers';
import processors from '@packages/features/reports/components/selectors/processor/reducers';
import executingEntities from '@packages/features/reports/components/selectors/executing-entity/reducers';
import dataRecipients from '@packages/features/reports/components/selectors/data-recipients/reducers';
import subProcessors from '@packages/features/reports/components/selectors/sub-processors/reducers';
import recordsProcessinggrounds from '@packages/features/reports/components/selectors/processing-ground/reducers';
import recordsTransfergrounds from '@packages/features/reports/components/selectors/transfer-ground/reducers';
import recordsBreachgrounds from '@packages/features/reports/components/selectors/breach-ground/reducers';
import recordsRetentionTerms from '@packages/features/reports/components/selectors/retention-term/reducers';
import dpo from '@packages/features/reports/components/selectors/data-protection-officer/reducers';
import dpoOrganisations from '@packages/features/reports/components/selectors/dpo-organisation/reducers';
import linkGroups from '@packages/features/reports/components/selectors/links/reducers';
import thirdPartyDataOrganisations from '@packages/features/reports/components/selectors/third-party-data-organisations/reducers';
import linkedRecords from '@packages/features/reports/components/selectors/linkedRecords/reducers';
import createdByUsers from '@packages/features/reports/components/selectors/organisation-user/reducers';
import recordGraph from '@packages/features/record-graphical-view/reducers';
import tenantConfiguration from '@packages/features/tenant-configuration/reducer';
import customFields from '@packages/features/tenant-configuration/components/list/reducer';
import customMasterDataList from '@packages/features/environment/components/custom-master-data/reducer';
import customFieldSelector from '@packages/features/tenant-configuration/components/customFieldSelector/reducer';
import layoutEditor from '@packages/features/tenant-configuration/layout-editor/reducer';
import customRecord from '@packages/features/privacy-record-detail/custom/reducers';
import itemList from '@packages/features/tenant-configuration/components/item-list-selector/reducer';
import releaseNote from '@packages/features/admin/components/update-release/reducers';
import recordsSelector from '@packages/features/privacy-record-detail/components/linked-records/components/records-selector/reducers';
import reports from '@packages/features/reports/reducer';
import dataLibrary from '@packages/features/data-library/reducers';

export default {
  login,
  ssosettings,
  banner,
  notifications,
  tasks,
  messages,
  messageRecipients,
  messageAttachments,
  messageCenter,
  privacyRecords,
  tenantusers,
  environment,
  environmentReducer,
  fieldVisibilitySettings,
  breach,
  tia,
  customSettings,
  processorHierarchy,
  documents,
  simpleEntities,
  processingCategories,
  dataRecipientCategories,
  legalEntities,
  dataSources,
  retentionTerms,
  personalDataItems,
  personalItemCategories,
  dataSubjectCategories,
  processingGrounds,
  documentRecordSelector,
  dsrDetail,
  organisationUser,
  transferGrounds,
  breachGrounds,
  supervisoryAuthorities,
  recordRevisions,
  processingDiff,
  assessmentDiff,
  breachDiff,
  tiaDiff,
  documentDiff,
  masterDataTags,
  graphItems,
  home,
  bulkImport,
  organisationEntities,
  externalOrganisations,
  tenantOrganisations,
  settings,
  dsrSettings,
  passwordSettings,
  apiTokens,
  publicRegistry,
  recordPortal,
  pricing,
  admin,
  tenants,
  tenantPartners,
  partners,
  holdingTenants,
  dashboard,
  records,
  dsr,
  dsrList,
  requesterList,
  dsrForms,
  dsrRequesters,
  publicDsr,
  globalDataUpdates,
  legalEntitySelector,
  controllers,
  dataExporters,
  dataImporters,
  onwardTransfers,
  processors,
  executingEntities,
  dataRecipients,
  subProcessors,
  recordsProcessinggrounds,
  recordsTransfergrounds,
  recordsBreachgrounds,
  recordsRetentionTerms,
  dpo,
  dpoOrganisations,
  linkGroups,
  thirdPartyDataOrganisations,
  linkedRecords,
  attachments,
  recordGraph,
  mfa,
  createdByUsers,
  tenantConfiguration,
  customFields,
  customMasterDataList,
  customFieldSelector,
  layoutEditor,
  customRecord,
  assessment,
  processing,
  itemList,
  releaseNote,
  recordsSelector,
  reports,
  dataLibrary
};
