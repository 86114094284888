import registry from 'app-registry';
import { put } from 'redux-saga/effects';
import { replace as replaceRouter } from 'react-router-redux';

import { handleServiceDown } from '@packages/utils/common-utils';
import notificationtranslations from '@packages/utils/notificationtranslations';

import { getTranslatedLabel } from '../tenant-configuration/tenantConfigUtils';
import { getRecordTypeName } from './common-utils';
import { getModifiedRecordType } from '../privacy-records/record-utils';

// eslint-disable-next-line import/prefer-default-export
export function* pdfExport(action) {
  yield put({ type: 'RECORD_PDF:DOWNLOAD:INIT' });
  const request = registry.get('request');
  const { recordType, id, exportType } = action;

  try {
    const response = yield request.exportCSVbyGet(
      `v1/records/${recordType}/${id}/export?exportType=${exportType}`
    );

    yield response;
    switch (response.status) {
      case 200: {
        yield put({ type: 'RECORD_PDF:DOWNLOAD:SUCCESS' });
        break;
      }
      default:
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        yield put({ type: 'RECORD_PDF:DOWNLOAD:FAIL' });
        break;
    }
  } catch (err) {
    yield handleServiceDown(err, 'report');
    yield put({ type: 'RECORD_PDF:DOWNLOAD:FAIL' });
  }
}

export function* fetchHelpNoteValue() {
  yield put({ type: 'HELPTEXT:RECORDS:FETCH' });
  try {
    const request = registry.get('request');
    const response = yield request.get('/v1/settings/helpnotes/view');
    switch (response.status) {
      case 200: {
        yield put({
          type: 'HELPTEXT:RECORDS:FETCH:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'notification');
  }
}

const transformChapter = (chapters, locale, tenantLocale, parentFilter = {}) =>
  chapters.map((item) => {
    const defaultFilter = parentFilter.layoutUniqueId
      ? `${item.layoutUniqueId}_${parentFilter.layoutUniqueId}`
      : item.layoutUniqueId;

    const subMenu = item.isGroup
      ? transformChapter(item.subChapters, locale, tenantLocale, item)
      : null;

    return {
      label: getTranslatedLabel(item.names, locale, tenantLocale),
      key: defaultFilter,
      value: defaultFilter,
      defaultFilter,
      subMenu,
      enable: true,
      isPreDpia: item.isPreDpia,
      metadata: item.metadata,
      ...(item.fieldRules ? 
        {fieldRules: item.fieldRules, 
          isUsedInRules: item.isUsedInRules} :
        {})
    };
  });

export function* fetchLayout({
  recordType,
  locale,
  tenantLocale,
  layoutId,
  libraryTenantId,
  isPreviewMode = false,
  isRecordPortal = false
}) {
  try {
    yield put({ type: 'LOADER:TOGGLE', toggle: true });
    const request = registry.get('request');
    const response = yield request.get(
      `/v1/custom-record-layout${
        isPreviewMode ? `/${libraryTenantId}/data-library` : ''
      }/${layoutId}${isPreviewMode ? '/preview' : ''}${isRecordPortal ? '?source=RecordPortal' : ''}`
    );
    const data = response.body;
    switch (response.status) {
      case 200: {
        const fieldsByChapter = {};
        const modifiedChapters = transformChapter(
          data.chapters,
          locale,
          tenantLocale,
          {}
        );
        modifiedChapters.forEach((item) => {
          fieldsByChapter[item.defaultFilter] = data.fields.filter(
            (field) => field.chapter === item.defaultFilter.split('_')[0]
          );
          if (item.subMenu) {
            item.subMenu.forEach((menu) => {
              fieldsByChapter[menu.defaultFilter] = data.fields.filter(
                (field) => field.chapter === menu.defaultFilter.split('_')[0]
              );
            });
          }
        });
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        yield put({
          type: `${recordType}:FETCH_LAYOUT:SUCCESS`,
          data: {
            ...data,
            chapters: modifiedChapters,
            fieldsByChapter
          }
        });
        break;
      }
      default: {
        yield put({ type: 'LOADER:TOGGLE', toggle: false });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
      }
    }
  } catch (err) {
    yield put({ type: 'LOADER:TOGGLE', toggle: false });
    yield handleServiceDown(err, 'custom');
  }
}

export function* getPrivacyRecordsHierarchy() {
  yield put({ type: 'PRIVACY_RECORDS:HIERARCHY:FETCH:INIT' });
  try {
    const response = yield registry
      .get('request')
      .get(
        `v1/custom-record-type/hierarchy?filter=notRecordType=document-records`,
        null
      );
    switch (response.status) {
      case 200: {
        yield put({
          type: 'PRIVACY_RECORDS:HIERARCHY:FETCH:SUCCESS',
          data: response.body
        });
        break;
      }
      default: {
        yield put({
          type: 'PRIVACY_RECORDS:HIERARCHY:FETCH:FAIL',
          data: response.body
        });
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'custom');
  }
}

const getRecordActionUrlWithParams = (
  actionType,
  recordType,
  layoutId,
  keepStatus,
  removeCurrentRecord
) => {
  const baseUrl =
    recordType !== 'custom'
      ? `/v1/records/${actionType}/${layoutId}`
      : `/v1/custom/records/${actionType}/${layoutId}`;

  const queryParams =
    actionType === 'promote'
      ? `?recordType=${recordType}`
      : `?keepStatus=${keepStatus}&removeCurrentRecord=${removeCurrentRecord}`;

  return `${baseUrl}${queryParams}`;
};

const getRedirectUrl = (recordType, recordData) => {
  if (recordType !== 'custom') {
    if (recordData.layoutId === '9e803e46-4931-4026-b6c3-988b88f68ae3') {
      return `/tia/${recordData.id}/edit`;
    }
    return `/${recordType}/${recordData.id}/edit`;
  } else {
    return `/custom-record/${recordData.recordLayoutData.id}/${recordData.id}/edit`;
  }
};

export function* getRecordData({ recordType, id }) {
  let url;
  if (recordType === 'custom') {
    url = `/v1/custom/records/${id}`;
  } else url = `/v1/records/${getModifiedRecordType(recordType)}/${id}`;
  const response = yield registry.get('request').get(url, null);
  if (response.status === 200) {
    return response.body;
  }
  return {};
}

export function* recordActionWorker(action) {
  yield put({ type: 'LOADER:SHOW' });
  yield put({ type: 'PRIVACY_RECORDS:ACTION_DIALOG:STATE_CHANGE' });

  const { recordData, recordActionData } = action;
  const { actionType, layoutId, keepStatus, removeCurrentRecord } =
    recordActionData;

  const recordType = getRecordTypeName(recordActionData.recordType);

  const transformedRequestData = yield getRecordData(recordData);
  try {
    const recordActionUrl = getRecordActionUrlWithParams(
      actionType,
      recordType,
      layoutId,
      keepStatus,
      removeCurrentRecord
    );

    const response = yield registry.get('request').post(recordActionUrl, {
      ...transformedRequestData,
      name: recordData.name
    });

    yield put({ type: 'LOADER:HIDE' });

    switch (response.status) {
      case 200: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content:
              actionType === 'promote'
                ? notificationtranslations.recordPromotedSuccessfully
                : notificationtranslations.recordUpgradedSuccessfully,
            type: 'success'
          }
        });
        yield put(replaceRouter(getRedirectUrl(recordType, response.body)));
        break;
      }
      default: {
        yield put({
          type: 'NOTIFIER:NOTIFY',
          notification: {
            content: response.body.msg,
            type: 'error'
          }
        });
        break;
      }
    }
  } catch (err) {
    yield handleServiceDown(err, 'record');
  }
}
